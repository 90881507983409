const zh = {
  bannerTitle1: "Have an idea for your trading platform? Let’s do it together!",
  bannerTitle2: "AVE AN IDEA? LET'S DO IT TOGETHER! ",
  bannerDesc: "Contact us to get insight on your new stream of revenue",
  formBtn: "Submit",
  officesTitleUp: "Our Offices",
  officesTitle: "our offices",
  officesLabel: "Contact Information",
  officesDubai: "UAE",
  officesDubaiInfo1: "General inquiries ",
  officesDubaiInfoAccount1: "info@tinytrader.com",
  officesDubaiInfo2: "Sales",
  officesDubaiInfoAccount2: "info@tinytrader.com",
  officesDubaiInfo3: "Marketing",
  officesDubaiInfoAccount3: "info@tinytrader.com",
  officesDubaiPhone: "Phone",
  officesDubaiPhoneNum: "+971 (50) 134 9768",
  officesDubaiSocial: "Social",

  officesChina: "China",
  officesChinaInfo1: "General inquiries ",
  officesChinaInfoAccount1: "info@tinytrader.com",
  officesChinaInfo2: "Sales",
  officesChinaInfoAccount2: "info@tinytrader.com",
  officesChinaInfo3: "Marketing",
  officesChinaInfoAccount3: "info@tinytrader.com",
  officesChinaPhone: "Phone",
  officesChinaPhoneNum: "+971 (50) 134 9768",
  officesChinaSocial: "Social",

  officesSingapore: "Singapore",
  officesSingaporeInfo1: "General inquiries ",
  officesSingaporeInfoAccount1: "info@tinytrader.com",
  officesSingaporeInfo2: "Sales",
  officesSingaporeInfoAccount2: "info@tinytrader.com",
  officesSingaporeInfo3: "Marketing",
  officesSingaporeInfoAccount3: "info@tinytrader.com",
  officesSingaporePhone: "Phone",
  officesSingaporePhoneNum: "+971 (50) 134 9768",
  officesSingaporeSocial: "Social",

  officesUK: "UK",
  officesUKInfo1: "General inquiries ",
  officesUKInfoAccount1: "info@tinytrader.com",
  officesUKInfo2: "Sales",
  officesUKInfoAccount2: "info@tinytrader.com",
  officesUKInfo3: "Marketing",
  officesUKInfoAccount3: "info@tinytrader.com",
  officesUKPhone: "Phone",
  officesUKPhoneNum: "+971 (50) 134 9768",
  officesUKSocial: "Social",

  formTitle: "Book a demo with us",
  formDesc:
    "Our team will reach out, introduce our latest solutions and answer all the questions that you might have",
  placeholderFirstName: "First Name",
  placeholderLastName: "Last Name",
  placeholderPhone: "Phone Number (please include country code)",
  placeholderWT: "WhatsApp or Telegram",
  placeholderEmail: "Email",
  placeholderCountry: "Country",
  placeholderCountryJob: "Country of Residence",
  placeholderBusiness: "Business Type",
  placeholderProduct: "Product Interested",
  placeholderComment:
    "Tell us about your project or mention how we might be of help to you",
  placeholderCommentJob: "Cover Letter (optional)",
  placeholderFileJob: "Upload Resume/CV",
  formSubmit: "Submit",
  formJobTitle: "Join Our Team",
  formJobDesc:
    "Please fill out the application form and we will reach out to you if it is a match ",
};

export default zh;
