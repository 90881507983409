const en = {
  news: "News",
  newsSubtitle: "Read about us in the news",
  news20230111Title: "Red envelope goes online!",
  news20230111SubTitle:
    "TinyTrader will launch the new function of red envelope on January 11, 2023.",
  news20230111Content1: "Dear TinyTrader users",
  news20230111Content2:
    "TinyTrader will launch the new function of red envelope on January 11, 2023. That will be open to any new and existing user only on APP.",
  news20230111Content3: "With this new function, you can:",
  news20230111Content4:
    "1. Send the red envelope into which you deposit real USDT to your friends, including both regular red envelopes and red envelope lucky draw",
  news20230111Content5:
    "2. Attract new users to register accounts through red envelopes",
  news20230111Content6:
    "3. Make users newly registered via red envelope will automatically become the users you invite",
  news20230111Content7:
    "Note: The red envelope will expire 24 hours later. The red envelope which expires but is not activated will be returned to the sender's account.",
  news20230111Content8: "TinyTrader",
  news20230111Content9: "January 11, 2023",
  news20230401Title: "TinyTrader Futures Grid Robot is Launched!",
  news20230401SubTitle:
    "Contract grid trading is one of the latest features launched by TinyTrader, which can help users fully leverage the cryptocurrency market. It works autonomously under set parameters, helping traders profit from small price fluctuations, especially in detrended price oscillator markets.",
  news20230401Content1: "Dear TinyTrader users",
  news20230401Content2: "<b>Takeaways：</b>",
  news20230401Content3:
    "• Grid trading is a strategic trading tool that can automatically buy and sell futures contracts at preset time intervals within the current price range.",
  news20230401Content4:
    "• It helps traders profit from small price fluctuations, especially in detrended price oscillator markets.",
  news20230401Content5:
    "• Users can set their own parameters and create grid trading strategies with just one click to place an order.",
  news20230401Content6:
    "• Continuously increasing vendor’s trading volume and service fee.",
  news20230401Content7:
    "TinyTrader futures grid trading is based on perpetual contracts, creating an easy-to-use tool that allows users to automatically buy and sell orders. With just one click, users can profit from the price fluctuations of cryptocurrencies.",
  news20230401Content8:
    "Contract grid trading is one of the latest features launched by TinyTrader, which can help users fully leverage the cryptocurrency market. It works autonomously under set parameters, helping traders profit from small price fluctuations, especially in detrended price oscillator markets.",
  news20230401Content9:
    "What are the advantages of TinyTrader futures grid robots?",
  news20230401Content10:
    "<b>24/7 automatic trading: </b>With the TinyTrader futures grid robot, users do not need to constantly track price trends. Just input the position information, then leave everything to the robot and gain the profits.",
  news20230401Content11:
    "<b>Long/Short/Neutral options: </b>If the long strategy is selected, the robot will only perform long opening and closing positions. If you choose a short strategy, the robot will only open and close short positions. If a neutral strategy is chosen, the robot will open short positions and close positions when the price is above the market price, and open long positions and close positions when the price is below the market price.",
  news20230401Content12:
    "<b>Leveraging to amplify incomes: </b>Utilizing leverage in futures trading, amplifying the size of users' positions, and earning higher returns.",
  news20230401Content13:
    "<b>Novice can also easily get started: </b>Are you a futures trading novice? TinyTrader futures grid robots can help green hands easily trade.",
  news20230401Content14:
    "Whether users prefer short-term or long-term trading, whether they are novice futures traders or veteran experts, grid robots make it all happened. Come and use the TinyTrader futures grid robot to start a new trading experience.",
  news20230401Content15: "TinyTrader",
  news20230401Content16: "April 1, 2023",
  news20230505Title:
    "TinyTrader is revolutionizing the options trading landscape of digital currencies with pioneering SaaS products, empowering users to seize market opportunities quickly and easily!",
  news20230505SubTitle:
    "The innovative trading methods and advanced technology of TinyTrader options trading SaaS products will provide investors with a unique trading experience and profits. Come join us and embark on a new journey of investment!",
  news20230505Content1:
    "Are the investors on your platform aiming for higher returns and more stimulating trading experiences? Then let us introduce you to TinyTrader Options Trading SaaS - a brand-new trading product! ",
  news20230505Content2:
    "Options trading is an innovative financial instrument that allows investors to buy or sell an asset in a pre-defined price and date range. Compared to regular futures contract trading, options trading allows for increased flexibility and higher potential returns. At TinyTrader, our options trading product is carefully developed by our professional finance team. As the industry’s first SaaS option trading offering, it will lead the digital currency options trading innovation! ",
  news20230505Content3:
    "Our option trading SaaS products leverage the most advanced technology and innovative solutions to offer investors a new digital currency options trading method. This product offers the following features: ",
  news20230505Content4:
    "- <b>Quickly seize market opportunities</b>: Providing SaaS or integrated SDK services, bringing you access to the depth of the options market and lowering the barrier of entry for platform options business, to help you swiftly capture the digital currency options market.",
  news20230505Content5:
    "- <b>Flexible and diverse trading strategies</b>: Offering a selection of different options contracts that allow investors to choose the futures best suited to their investment objectives and risk tolerance, helping to expand trading opportunities and create greater returns.",
  news20230505Content6:
    "- <b>Unique risk control system</b>: Our risk control system adopts the most up-to-date technology and strategies to ensure the safety of users' funds. Our system will automatically monitor the transactions and positions of user accounts. When abnormal situations are found, corresponding measures will be taken immediately to avoid losses to users' funds.",
  news20230505Content7:
    "- <b>New user trading experience</b>: Based on European options, advanced intelligent algorithms are used to achieve an automated trading experience, freeing traders from the tedious trading process. Investors can enjoy lightning-fast trading execution speed and superior trading smoothness. Users can seamlessly trade anywhere and anytime confidently and easily.",
  news20230505Content8:
    "- <b>Accurate price discovery mechanism</b>: labeled prices are determined by the Black Scholes model and updated in real-time, accurately reflecting market expectations. In the pricing process, we fully consider factors such as market quotations and provide a fair price as the option marker price, providing a reference for users' investment decisions.",
  news20230505Content9:
    "- <b>Customer service 24/7</b>: TinyTrader's customer service team is composed of industry professionals offering you high-quality service and support. We will provide you with the best solution for transaction operation, risk control, or account management.",
  news20230505Content10:
    "Options trading can be both challenging and innovative investors that can help investors achieve higher investment returns and more accurate trading decisions. We believe that the innovative trading methods and advanced technology of TinyTrader options trading SaaS products will provide investors with a unique trading experience and profits. Come join us and embark on a new journey of investment!",
  news20230505Content11: "Dear TinyTrader users",
  news20230505Content12: "TinyTrader",
  news20230505Content13: "May 5, 2023",
  news20230619Title:
    "Deepening Innovation and Collaboration: TinyTrader OpenAPI Officially Launched!",
  news20230619SubTitle:
    "With the release of OpenAPI, we bring greater flexibility and integration to our users, empowering them to succeed in digital asset trading.",
  news20230619Content1:
    "We are thrilled to announce that TinyTrader's OpenAPI has officially launched! This powerful tool will bring you more opportunities and profits, helping you gain a leading position in the digital asset market.",
  news20230619Content2:
    "As a leading global SaaS cryptocurrency trading service provider, TinyTrader is committed to offering secure, efficient, and innovative digital asset trading solutions for institutional traders. We continuously strive to enhance user experience, introduce new products and features to meet evolving market demands. Now, with the release of OpenAPI, we bring greater flexibility and integration to our users, empowering them to succeed in digital asset trading.",
  news20230619Content3:
    "TinyTrader's OpenAPI product offers rich functionalities and data to meet your trading needs. Here are the key features of this product:",
  news20230619Content4:
    "• <b>Open Trading Capabilities</b>:  Through OpenAPI, you can perform various trading operations, including placing orders, canceling orders, and querying trade history. With API integration, you can achieve automated trading and personalize trading strategies to navigate the market according to your needs.",
  news20230619Content5:
    "• <b>Real-time Market Data</b>: Access the latest market trends, trading pair prices, depth data, and other real-time information. These data will assist you in making informed trading decisions and adjusting strategies promptly based on market changes.",
  news20230619Content6:
    "• <b>Comprehensive Account Management</b>: OpenAPI provides comprehensive account management features, including querying account balances and trade history. Manage your digital assets and trading activities more effortlessly, gaining a holistic view of your portfolio.",
  news20230619Content7:
    "• <b>Security Measures</b>: TinyTrader ensures secure and reliable API authentication and access control mechanisms to safeguard user data and funds. We employ advanced encryption techniques and identity verification measures to protect your trading journey.",
  news20230619Content8:
    "• <b>All-around service support</b>: We provide detailed API documentation, sample codes, and technical support to help you get started quickly and address any issues you encounter.",
  news20230619Content9:
    "• <b>Market Making Services</b>: Comprehensive market-making services are offered through OpenAPI, providing better rates and lower latency. This helps you maximize market influence and liquidity management capabilities, monitor the market in real-time, and adjust strategies flexibly to enhance trading efficiency, manage risks, and achieve significant returns.",
  news20230619Content10:
    "• <b>Brokerage Services</b>: With OpenAPI, we provide premier market depth, sharing the depth and liquidity of spot and derivative markets with top-tier exchanges. We offer high trading commission rebates and flexible rate markups.",
  news20230619Content11:
    "With TinyTrader's OpenAPI product, you and developers can deeply integrate our trading platform and develop customized trading tools, strategies, and applications. We encourage you and our partners to actively explore this innovative product and together drive the development of the digital asset trading industry.",
  news20230619Content12:
    "Click here to learn more about TinyTrader OpenAPI and embark on your journey in digital asset trading: <a>TinyTrader OpenAPI</a>",
  news20230619Content13: "Best regards,",
  news20230619Content14: "TinyTrader",
  news20230619Content15: "June 19, 2023",
  news20230815Title:
    "TinyTrader officially launches a brand-new spot trading platform and UI 2.0 version upgrade, committed to providing users with an enhanced trading experience！",
  news20230815SubTitle:
    "TinyTrader is proud to unveil its latest spot trading platform and UI 2.0 version upgrade, dedicated to delivering an exceptional trading experience to users. In our continuous pursuit of innovation and excellence, we are providing users with more powerful, convenient, and secure features to empower them for greater success in the cryptocurrency market.",
  news20230815Content1:
    "• <b>Revamped UI 2.0, Refreshing Experience</b>: The all-new UI 2.0 version brings a refreshing interface design, emphasizing optimized layout and visual effects, ensuring users feel a more intuitive and comfortable trading experience. The comprehensive interface upgrade aims to enhance users' trading perception, enabling them to swiftly access market information and make informed decisions.",
  news20230815Content2:
    "• <b>Real-time Market Data, Comprehensive Insights</b>: The spot trading platform offers users real-time market data and comprehensive insights, aiding them in making well-informed trading decisions. Our charting tools and technical indicators assist users in analyzing market trends more accurately, providing robust support for their trading choices.",
  news20230815Content3:
    "• <b>Limitless Choices, Diverse Trading</b>: The spot trading platform offers users a wider array of cryptocurrency trading pairs. TT provides tailored listing services to merchants, catering to diverse investment needs. Whether users are newcomers or experienced traders, they can easily find the most suitable trading pairs to seize market opportunities.",
  news20230815Content4:
    "• <b>Security and Reliability, Safeguarding Assets</b>: User security remains our top priority. In this version upgrade, we have further fortified our security protection system, implementing cutting-edge encryption technology and multi-factor authentication mechanisms to ensure maximum protection of user assets and transaction information.",
  news20230815Content5:
    "• <b>User Experience, Continuous Enhancement</b>: TinyTrader is committed to delivering an outstanding trading experience for users. We will continue to listen to user feedback and continuously optimize and enhance features in subsequent version upgrades to meet the evolving user demands.",
  news20230815Content6: "",
  news20230815Content7:
    "This significant version upgrade marks a new milestone for TinyTrader in terms of technological innovation and user experience. We firmly believe that through this upgrade, users will participate in the cryptocurrency market with greater ease and efficiency.",
  news20230815Content8:
    "Lastly, we sincerely thank you for your steadfast support. Your trust is the driving force behind our continuous progress!",
  news20230815Content9: "",
  news20230815Content10: "TinyTrader Team",
  news20230815Content11: "August 15, 2023",

  homeNewsReadMore: "Read More",
  homeNewsTitle: "In the news",
  homeNewsDesc:
    "We Proudly Unveils Its Latest Advancements In Derivatives  Technologies. As We Continue To Set Industry Standards, We Invite You To Explore The Innovations That Are Redefining Our Platform's Capabilities",

  // 新版英文官网
  ttNewsTitle: "Today News",
  ttNewsDesc:
    "We Proudly Unveils Its Latest Advancements In White-Label Digital Assets Trading Technologies. As We Continue To Set Industry Standards, We Invite You To Explore The Innovations That Are Redefining Our Platform's Capabilities",
  ttNewsView: "View Recent Tiny News",

  newsViewTitle: "View Recent Tiny News",
  newsReadTitle: "Read Also",
  eventViewsTitle: "View Recent Tiny events",
  eventWatchTitle: "Watch Also",
  publicationsTitle: "Publications",
  publicationsDesc:
    "A library of informative presentations and video about our products and industry-related issues. Browse through our extensive range of subject matter and get educated.",

  // events
  eventsTitle: "TinyTrader's VIP After Party at Asia Blockchain Summit 2024: Iconic Web3 Expo In Taiwan",
  eventsDesc: `As an after-party of the Asia Blockchain Summit, this event brings together Web3 founders, investors, crypto exchanges, industry builders and crypto enthusiasts to network and build meaningful connections.`,
  eventsBannerBtn: "Watch Now",
  eventsListTitle: "Here’s What We Announced.",
  eventsItemBtn: "Learn More",

  educationTitle: "Education",
  educationDesc:
    "We Proudly Unveils Its Latest Advancements In White-Label Digital Assets Trading Technologies. As We Continue To Set Industry Standards, We Invite You To Explore The Innovations That Are Redefining Our Platform's Capabilities",
  educationBtn: "View More",
  educationPresentations: "Presentations",
  educationPresentationsDesc:
    "A library of informative presentations and video about our products and industry-related issues. Browse through our extensive range of subject matter and get educated.",
  educationItemsTitle1: "How Crypto Payment Processing Works",
  educationItemsDesc1:
    "In this video, Arthur Azizov, CEO and Founder of the TinyTrader Group, explains the basics of crypto payment processing and how businesses can benefit from using a crypto payment solution like B2BinPay. He dives into the details of the processing infrastructure, current trends in crypto payments, differences between USDT and USDC stablecoins, and more.",
  educationPublications1:
    "We Proudly Unveils Its Latest Advancements In White-Label Digital Assets Trading Technologies. As We Continue To Set Industry Standards, We Invite You To Explore The Innovations That Are Redefining Our Platform's Capabilities",

  marketplaceTitle: "TT Marketplace",
  marketplaceDesc:
    "Ideal venue for businesses seeking marketing collaborations, co-marketing activities, integrations, and revenue-sharing opportunities. With a diverse community of companies, we offer an open and dynamic environment for strategic partnerships. By harnessing the power of synergistic alliances, businesses can expand their reach and access TT’s extensive customer base, which continues to grow exponentially.",
  marketplaceBtn: "Find More",
  marketplaceTitle1: "Welcome to TinyTrader​",
  marketplaceSubtitle: "Empowering Digital Trading Systems Beyond Borders​",
  marketplaceDesc1:
    "TinyTrader is a fintech Software-as-a-Service (SaaS) company specializing in developing and providing technological and liquidity solutions. Our primary focus is to develop and deliver innovative, secure, scalable and robust trading infrastructure, ecosystem applications, and services to businesses and organizations.​",
  marketplaceTitle2: "Mission​",
  marketplaceDesc2:
    "To foster collaboration and create a dynamic ecosystem where businesses can flourish together.​",
  marketplaceTitle3: "Vision",
  marketplaceDesc3:
    "Establish a collaborative and flourishing business community, where companies unite to enhance each other’s operations through cooperation and mutual support.​",
  marketplaceCoreTitle: "Why Join Our Marketplace?​",
  marketplaceCoreDesc:
    "What sets the TT Marketplace apart is its emphasis on cooperation and mutual benefits. By coming together, companies can better achieve more significant milestones and drive innovation to new heights.​",
  marketplaceCoreTitle1: "Affiliate Marketing​",
  marketplaceCoreDesc1:
    "Partner in our affiliate marketing model to promote your product offerings to a broader audience.​",
  marketplaceCoreTitle2: "Co-marketing",
  marketplaceCoreDesc2:
    "Jointly create and promote marketing campaigns,leveraging combined resources for increased brand exposure and mutual growth.",
  marketplaceCoreTitle3: "Revenue Sharing",
  marketplaceCoreDesc3:
    "Collaborate by sharing profits based on contributions, thus fostering mutual success through aligned interests and joint efforts.",
  marketplaceCoreTitle4: "Integrations",
  marketplaceCoreDesc4:
    "Spur innovation by joining the App Partner, to create collaboratively designed product and expand market reach.",
  marketplacePartnerTitle: "Featured Partners",
  marketplacePartnerDesc:
    "TinyTrader has partnered with a broad range of companies across different business segments.",
  marketplacePartnerBtn: "Join Us",

  // 专题列表-详情
  bookADemo: "Book A Demo",
  
  events20240729Title: "TinyTrader at ABS 2024 Taipei: after Party Side Event",
  events20240729SubTitle: "We are excited to announce that TinyTrader will be featured at the highly-anticipated ABS 2024 conference in Taipei, Taiwan, from August 6th to 8th.",
  events20240729DetailTitle: "TinyTrader's VIP After Party at Asia Blockchain Summit 2024: Iconic Web3 Expo In Taiwan",
  events20240729SubTitleDetail: "As an after-party of the Asia Blockchain Summit, this event brings together Web3 founders, investors, crypto exchanges, industry builders and crypto enthusiasts to network and build meaningful connections.",
  events20240729Content1_1: "As the Asia Blockchain Summit about to kick off, we are gathering the foremost visionaries, innovators, and pioneers in the crypto space. This side event is more than just a party; it's a celebration of the blockchain future and the incredible evolution of the crypto industry.",
  events20240729Content1_2: "Get ready for an evening of unparalleled networking opportunities, set against the stunning skyline of Taipei 101. We’re bringing together Web3 founders, investors, crypto exchanges, industry builders and crypto enthusiasts to exchange to network and build meaningful connections.",
  events20240729Content1_3: "But the night won't be all business! Guests will enjoy unlimited premium drinks and a variety of delicious foods. Prepare yourself for an event that promises to be truly extraordinary!",
  events20240729Content1_4: "Join us as we raise our glasses to celebrate innovation, collaboration, and the limitless opportunities that lie ahead. Together, let’s make this night unforgettable, setting the stage for a future where the only constant is change and the potential for greatness is limitless.",

  events20240729Content2_1: "​TinyTrader (TT) is an award-winning white-label trading technology and liquidity solutions provider. With over 50+ Institutional Clients, 100+ Employees, 10+ Countries, and $5B+ Volume, we connect digital-asset trading between fiat and digital currencies for global trading platforms, brokers, developers, and institutions.",
  events20240729Content2_2: "​At TinyTrader, our unwavering commitment to delivering cutting-edge white-label solutions is at the core of our mission. We empower a diverse spectrum of projects, spanning traditional finance, CFD brokerages, financial technology, and the crypto sector, to elevate their business operations. Our team embodies a unique blend of expertise and transparency, driven by an unwavering commitment to your success. We are well-prepared to share our technological prowess and knowledge to propel your achievements.",

  events20240729ContentLabel3: "About ABS 2024", 
  events20240729Content3: "Asia Blockchain Summit (ABS) is an annual event that brings together leading innovators, experts, and enthusiasts from the blockchain and cryptocurrency industries. Hosted in Asia, ABS serves as a platform to foster collaboration, knowledge sharing, and networking among key players in the rapidly evolving blockchain ecosystem.",

  events20240419Title: "TinyTrader at Token 2049 Dubai: after Party Side Event",
  events20240419SubTitle:
    "We are thrilled to share the exciting news that TinyTrader is set to make its mark at the highly-anticipated Token2049 conference in Dubai, UAE, taking place from April 18th to 19th.",
  events20240419DetailTitle:
    "TinyTrader's VIP After Party at Token2049: Embracing Crypto's Shining Future",
  events20240419SubTitleDetail:
    "As an after-party of the Dubai Crypto Week, this event brings together Web3 founders, investors, tier 1 exchanges, and industry builders to foster collaboration, share insights, and forge meaningful connections.",
  events20240419ContentLabel1: "Event Details",
  events20240419Content1_1:
    "As the sun sets on Dubai Crypto Week, we gathering top visionaries, innovators, and pioneers in the crypto space. It won't just be a party; it'll be a celebration of the future of finance and the incredible journey of the crypto industry.",
  events20240419Content1_2:
    "An evening of unparalleled networking opportunities awaits, set against the iconic skyline of Dubai and the majestic Burj Khalifa. We're bringing together Web3 founders, investors, tier 1 exchanges, and industry builders to share insights and forge connections that are bound to shape the future of the industry.",
  events20240419Content1_3:
    "But it won't be just business talk all night! Our guests will enjoy premium drinks, delectable canapes, and the chance to participate in exciting Lucky Draw Games, with exclusive gifts up for grabs. Get ready for an event that promises to be nothing short of extraordinary!",
  events20240419Content1_4:
    "Join us as we raise our glasses to toast to innovation, collaboration, and the limitless possibilities that lie ahead. Together, let's make this night one to remember, setting the stage for a future where the only constant is change, and the potential for greatness knows no bounds.",
  events20240419ContentLabel2: "About TinyTrader",
  events20240419Content2_1:
    "TinyTrader (TT) is an award-winning white-label trading technology and liquidity solutions provider. With over 50+ Institutional Clients, 100+ Employees, 10+ Countries, and $5B+ Volume, we connect digital-asset trading between fiat and digital currencies for global trading platforms, brokers, developers, and institutions.",
  events20240419Content2_2:
    "At TinyTrader, our unwavering commitment to delivering cutting-edge white-label solutions is at the core of our mission. We empower a diverse spectrum of projects, spanning traditional finance, CFD brokerages, financial technology, and the crypto sector, to elevate their business operations. Our team embodies a unique blend of expertise and transparency, driven by an unwavering commitment to your success. We are well-prepared to share our technological prowess and knowledge to propel your achievements.",
  events20240419ContentLabel3: "About Token2049",
  events20240419Content3:
    "TOKEN2049 is a global conference series, where the crypto ecosystem's decision-makers connect to exchange ideas, network, and shape the industry. TOKEN2049 is the pre-eminent meeting place for entrepreneurs, institutions, industry insiders, investors, builders, and those with a strong interest in the crypto and blockchain industry.",

  events20240219Title:
    "TinyTrader launches a revolutionary proprietary trading solution that signifies our ongoing commitment to innovation in the rapidly evolving world of digital assets.",
  events20240219SubTitle:
    "TinyTrader is excited to announce the launch of its newest product, Prop Trading, a revolutionary proprietary trading solution that signifies our ongoing commitment to innovation in the rapidly evolving world of digital assets.",
  events20240219SubTitleDetail:
    "A challenging program that allows users to earn higher profits by leveraging provided funds",
  events20240219ContentLabel1:
    "Future Generation White-Label Proprietary Trading Solution Release",
  events20240219Content1:
    "TinyTrader is excited to announce the launch of its newest product, Prop Trading, a revolutionary proprietary trading solution that signifies our ongoing commitment to innovation in the rapidly evolving world of digital assets.",
  events20240219ContentLabel2:
    "Tailored for both new and experienced traders, the platform provides an opportunity for users to learn and engage in challenges with minimised financial risks and enhanced potential profits.",
  events20240219Content2: "What is Prop Trading in detail?",
  events20240219ContentLabel3:
    "Prop Trading, short for proprietary trading, is a next-generation solution that empowers traders by providing access to firm capital. Backed by cutting-edge challenges provisioning and performance tracking tools, TinyTrader's Prop Trading is designed to revolutionize the way traders engage with the market.",
  events20240219Content3: "7 Key Features:",
  events20240219ContentLabel4:
    "1. Fully Customizable Platform Based on WordPress:",
  events20240219Content4:
    "Experience complete flexibility with the theme design, manage content through the CMS and enhance your system with a wide range of WordPress tools",
  events20240219ContentLabel5:
    "Guarantee swift speed and seamless responsiveness to provide users with an optimal experience",
  events20240219Content5: "2. Flexible Challenge Setup Back-Office:",
  events20240219ContentLabel6:
    "Customise the challenge setup with a user-friendly back-office interface",
  events20240219Content6:
    "Tailor challenges based on specific criteria, including the number of steps, performance parameters, marketing campaigns, trading periods, and more",
  events20240219ContentLabel7: "3. Multi-Step (Demo & Live) Environment:",
  events20240219Content7:
    "Experience a comprehensive multi-step environment that allows traders to transition seamlessly from demo to live trading",
  events20240219ContentLabel8:
    "Test and refine strategies in a risk-free demo environment before committing to live trading",
  events20240219Content8:
    "Launch the WordPress-based Prop Trading system in the shortest time and the most efficient way.",
  events20240219ContentLabel9: "4. Elevated Marketing Capabilities:",
  events20240219Content9:
    "Bring your platform to the market swiftly, streamline the go-to-market process and gain a competitive edge",
  events20240219ContentLabel10:
    "Utilise the diverse tools within WordPress to extend your online presence, reaching a broader audience and maximising your impact in the digital space",
  events20240219Content10: "5. Risk-Management Tools:",
  events20240219ContentLabel11:
    "Benefit from robust risk-management tools to ensure responsible and secure trading practices",
  events20240219Content11:
    "Set parameters such as minimum trading days, daily and total loss limits, profit targets, and registration fees to align with individual risk tolerance",
  events20240219ContentLabel12: "6. Advanced Challenge Analytics:",
  events20240219Content12:
    "Leverage advanced analytics tools to gain insights into challenge performance",
  events20240219ContentLabel13:
    "Monitor and track trading activity with precision to refine strategies and optimise results",
  events20240219Content13: "7. Full Ecosystem Support:",
  events20240219ContentLabel14:
    "Enjoy a fully supported ecosystem that provides the infrastructure for smooth monitoring and notification needs",
  events20240219Content14:
    "Customise different challenge tiers to cater to a diverse range of users, from those managing small allocated funds to high-net-worth portfolios",
  events20240219ContentLabel15:
    "Want to check out the system? Book a demo with us! ",
  events20240219Content15:
    "Discover more about TinyTrader's SaaS solutions by scheduling a call with our team. Learn how we can help you create new revenue streams and elevate your trading experience. Contact us today to embark on a journey of innovation and success with TinyTrader.",

  events20230589Title:
    "TinyTrader's Participation in Crypto 306: Unlocking Crypto's Potential",
  events20230589SubTitle:
    "We are thrilled to share the exciting news that TinyTrader is set to make its mark at the highly-anticipated Crypto 306 event in Dubai, UAE, taking place from March 8th to 9th.",
  events20230589SubTitleDetail:
    "We are thrilled to share the exciting news that TinyTrader is set to make its mark at the highly-anticipated Crypto 306 event in Dubai, UAE, taking place from March 8th to 9th. Our dedicated team will be on-site, showcasing our market-ready solutions and offering valuable insights to address any questions you may have.",
  events20230589ContentLabel1: "About Crypto 306",
  events20230589Content1:
    "Crypto 306 stands as the pinnacle of annual B2B/B2C networking events, serving as a vital connection point for the global crypto community. This eagerly awaited gathering unites newcomers, investors, and crypto industry stalwarts from across the globe. It serves as a platform for sharing ideas, discussing emerging trends, and presenting groundbreaking projects that promise to shape the future of crypto.",
  events20230589ContentLabel2: "About TinyTrader",
  events20230589Content2:
    "At TinyTrader, our unwavering commitment to delivering cutting-edge white label solutions is at the core of our mission. We empower a diverse spectrum of projects, spanning traditional finance, CFD brokerages, financial technology, and the crypto sector, to elevate their business operations. Our team embodies a unique blend of expertise and transparency, driven by an unwavering commitment to your success. We are well-prepared to share our technological prowess and knowledge to propel your achievements.",
  events20230589ContentLabel3: "Final Thoughts",
  events20230589Content3:
    "We extend a warm invitation to join us at Crypto 306 and connect with our experienced TinyTrader teams. Discover how we can help your company realize its full potential and explore our innovative product offerings. This is an opportunity not to be missed, so seize the moment and register today to be a part of Crypto 306. We eagerly await your presence at this transformative event.",

  events2023071922Title:
    "TinyTrader's Showcased Solutions at Sigma Asia Summit 2023 Sponsored Dinner",
  events2023071922SubTitle:
    "TinyTrader and HBC were proud to be part of AIBC Eurasia 2023, a groundbreaking global blockchain event taking place in Dubai!...",
  events2023071922SubTitleDetail:
    "TinyTrader and HBC were proud to be part of AIBC Eurasia 2023, a groundbreaking global blockchain event taking place in Dubai! The event was taking place on July 20, and it provided the perfect platform for our teams to connect with industry leaders and experts in the blockchain and crypto fields, as well as display our products and latest updates to new and old clients.",
  events2023071922ContentLabel1: "About AIBC Sigma Manila 2023",
  events2023071922Content1:
    "AIBC (Artificial Intelligence Blockchain and Cryptocurrency) is a globally recognized leader in technology events and media production. Their mission is to unite experts, investors, developers, and enthusiasts in AI, blockchain, and related fields. AIBC's international events offer unmatched networking and learning opportunities, connecting attendees with influential industry figures. AIBC SIGMA Manila 2023, held from July 19th to 22nd, underscored their commitment to fostering innovation and collaboration.",
  events2023071922ContentLabel2:
    "Award Granted - Blockchain Data Solution of the Year 2023",
  events2023071922Content2: `At AIBC SIGMA Manila, TinyTrader clinched the "Blockchain Data Solution of the Year 2023" award, affirming their innovation and excellence in the blockchain and cryptocurrency arena. This recognition solidifies their position as a pioneering force, revolutionizing data solutions in the industry.`,
  events2023071922ContentLabel3: "Final Words",
  events2023071922Content3: `AIBC SIGMA Manila 2023 witnessed TinyTrader's remarkable journey, from sponsoring a VIP dinner to being recognized as the "Blockchain Data Solution of the Year 2023." This award not only celebrates their contributions but also underscores the power of innovation, collaboration, and expertise. Such recognition propels both AIBC and TinyTrader forward, opening doors to new possibilities in the ever-evolving blockchain and fintech landscape.`,

  events2023072526Title: "TinyTrader's Impact at WebX 2023",
  events2023072526SubTitle: `On July 25-26, 2023, TinyTrader made a significant impression at the WebX Conference. They played a dual role as sponsors and hosts of the "Pioneer the Future of Web 3" side event, underscoring their commitment to tech advancement.`,
  events2023072526SubTitleDetail: `On July 25-26, 2023, TinyTrader made a significant impression at the WebX Conference. They played a dual role as sponsors and hosts of the "Pioneer the Future of Web 3" side event, underscoring their commitment to tech advancement. WebX, organized by CoinPost, is Asia's largest web3 conference, attracting global industry leaders, startups, investors, and experts. This two-day event at Tokyo International Forum aimed to accelerate global collaboration and position Japan as a Web3 hub in Asia, representing a crucial moment where technology and society intersect.`,
  events2023072526ContentLabel1: "About WebX",
  events2023072526Content1:
    "WebX, hosted by CoinPost, is Asia's premier web3 conference, uniting key industry players, startups, and experts from Japan and beyond. Taking place at the Tokyo International Forum, it serves as a vital platform for discussions on decentralized technologies like blockchain. By bridging the gap between Web3, Web2, and other sectors, WebX accelerates global collaboration and solidifies Japan's position in the Web3 domain.",
  events2023072526ContentLabel2: "Final Words",
  events2023072526Content2: `TinyTrader's sponsorship and the hosting of "Pioneer the Future of Web 3" at WebX Conference 2023 showcased their commitment to innovation. This presence underlines their dedication to empowering projects in traditional finance, CFD brokerages, fintech,and the crypto sector. As the world embraces the web3 era, TinyTrader's role at WebX affirms their influential position in shaping the technology landscape and its integration into society.`,

  events20230725Title: `TinyTrader at WebX and "Pioneer the Future of Web 3"`,
  events20230725SubTitle: `TinyTrader's commitment to innovation takes center stage in two pivotal events. They are proud sponsors of the WebX event and, concurrently, sponsors of a unique side event in Tokyo, "Pioneer the Future of Web 3," organized by TWIC on July 25.`,
  events20230725SubTitleDetail: "",
  events20230725ContentLabel1: "Introduction:",
  events20230725Content1: `TinyTrader's commitment to innovation takes center stage in two pivotal events. They are proud sponsors of the WebX event and, concurrently, sponsors of a unique side event in Tokyo, "Pioneer the Future of Web 3," organized by TWIC on July 25. This gathering brings together multiple Web 3 projects for project sharing and networking, providing a fertile ground for knowledge exchange and collaboration. With a mission centered on delivering cutting-edge white label solutions, TinyTrader's impact extends across traditional finance, CFD brokerages, fintech, and the crypto sector.`,
  events20230725ContentLabel2: `About "Pioneer the Future of Web 3`,
  events20230725Content2: `"Pioneer the Future of Web 3," organized by TWIC, gathers multiple Web 3 projects in Tokyo, offering a platform for sharing and networking, essential in shaping the future of technology.`,
  events20230725ContentLabel3: "Final Thoughts:",
  events20230725Content3: `TinyTrader's dual sponsorship, at both WebX and "Pioneer the Future of Web 3," is a testament to their commitment to innovation and collaboration. These events showcase their cutting-edge white label solutions that empower a diverse range of projects. As they continue to shape the technology and finance landscape, TinyTrader's participation amplifies opportunities and fosters the success of their clients.`,

  events20230903Title: `TinyTrader at "Exploration Unleashed": Navigating Real-World Assets in Web3`,
  events20230903SubTitle: `On September 3, 2023, TinyTrader participated in the "Exploration Unleashed" event in Seoul, organized by Tg Trade.`,
  events20230903SubTitleDetail: "",
  events20230903ContentLabel1: "Introduction:",
  events20230903Content1: `On September 3, 2023, TinyTrader participated in the "Exploration Unleashed" event in Seoul, organized by Tg Trade. This conference focused on the convergence of traditional assets and cutting-edge technologies, with a specific emphasis on tokenization and trading of Real-World Assets (RWAs) in the Web3 space. The event unveiled how blockchain integration redefines ownership and investment concepts, bridging the gap between digital and physical assets. TinyTrader's involvement in the panel discussion, "Unlocking the Potential of Web3 and RWAs — Adoption and Regulatory Considerations," brought together industry experts to explore challenges, opportunities, and regulatory aspects.`,
  events20230903ContentLabel2: `About "Exploration Unleashed`,
  events20230903Content2: `"Exploration Unleashed," organized by Tg Trade, is a conference exploring the intersection of traditional assets and innovative technologies, particularly the tokenization of RWAs in Web3.`,
  events20230903ContentLabel3: "Final Thoughts:",
  events20230903Content3: `TinyTrader's participation at "Exploration Unleashed'' reflects their commitment to innovation and collaboration. By showcasing their cutting-edge white label solutions, they empower a diverse range of projects. This event amplifies opportunities and fosters success, marking an exciting journey at the intersection of real-world assets and Web3. TinyTrader continues to shape the future of technology and finance, leading the way to a more open and transparent future.`,

  events20230907Title:
    "TinyTrader at Korean Blockchain Week After Party - Pioneer the Future of Web 3",
  events20230907SubTitle:
    "On September 7, 2023, Gangnam, Seoul took the spotlight, hosting an anticipated event that promised a unique networking experience.",
  events20230907SubTitleDetail: "",
  events20230907ContentLabel1: "Introduction:",
  events20230907Content1:
    "On September 7, 2023, Gangnam, Seoul took the spotlight, hosting an anticipated event that promised a unique networking experience. It unites visionary leaders, innovators, talent, and investors from the Korean blockchain landscape and beyond. This After Party is the pinnacle of Korean Blockchain Week and marks a pivotal moment in the Web 3 industry. In collaboration with partners like Ticker Capital, Alchemy Pay, Bittrex, Filecoin, and more, including TinyTrader, it's an exclusive opportunity to engage with the minds shaping the future of technology.",
  events20230907ContentLabel2: "About TinyTrader",
  events20230907Content2:
    "TinyTrader's mission revolves around delivering cutting-edge white label solutions, empowering projects in traditional finance, CFD brokerages, fintech, and the crypto sector. Their team combines expertise and transparency, driven by a commitment to their clients' success.",
  events20230907ContentLabel3: "Final Thoughts:",
  events20230907Content3:
    "TinyTrader's presence at the Korean Blockchain Week After Party underscores their commitment to innovation and collaboration. This event provides a platform for showcasing their cutting-edge white label solutions, amplifying opportunities, and fostering the success of their clients. It's an exciting gathering of industry pioneers, marking a significant moment in the journey toward the future of Web 3.",

  events20230912Title:
    "TinyTrader at Token 2049: Japan Meets Singapore (Side event)",
  events20230912SubTitle:
    "Token 2049, a highly anticipated event in the blockchain and cryptocurrency world, is about to get a new wave of energy.",
  events20230912SubTitleDetail: "",
  events20230912ContentLabel1: "Introduction:",
  events20230912Content1: `Token 2049, a highly anticipated event in the blockchain and cryptocurrency world, is about to get a new wave of energy. In partnership with industry leaders Teamz and TWIC, is hosting an exclusive side event, "Japan Meets Singapore," at Token 2049. With thought-provoking discussions, inspiring speeches, and unmatched networking opportunities featuring luminaries from Teamz, TWIC, DigiFinex and more, it's a treasure trove of insights into the ever-evolving world of Web3.`,
  events20230912ContentLabel2: "About Japan meet Singapore",
  events20230912Content2:
    "Teamz, and TWIC are committed to fostering collaboration and knowledge-sharing in the blockchain and cryptocurrency community. ",
  events20230912ContentLabel3: "Final Thoughts:",
  events20230912Content3: `DigiFinex's presence at Token 2049 and their exclusive side event, "Japan Meets Singapore," exemplify their dedication to innovation and collaboration in the dynamic blockchain and cryptocurrency landscape. This event underscores the transformative potential of Web3 and the commitment of these industry leaders to drive the field forward.`,

  events2023091314Title: "TinyTrader and Token 2049: Shaping the Future",
  events2023091314SubTitle:
    "TOKEN2049 is a renowned annual gathering that stands at the forefront of the blockchain and cryptocurrency industry.",
  events2023091314SubTitleDetail: "",
  events2023091314ContentLabel1: "About Token 2049",
  events2023091314Content1:
    "TOKEN2049 is a renowned annual gathering that stands at the forefront of the blockchain and cryptocurrency industry. As a premier event, it draws together a diverse and dynamic community of blockchain experts, investors, entrepreneurs, and enthusiasts from across the globe. With a focus on fostering collaboration and knowledge sharing, TOKEN2049 offers a platform to delve into the latest trends, innovations, and opportunities within the blockchain space. This influential conference features a curated selection of keynote presentations, panel discussions, workshops, and networking sessions, making it a vital nexus for staying informed about the evolving landscape of digital assets, decentralized finance, non-fungible tokens, and other cutting-edge blockchain technologies. For anyone seeking to navigate the ever-changing world of blockchain and cryptocurrencies, TOKEN2049 is a must-attend event, providing valuable insights and connections in this rapidly evolving ecosystem.",
  events2023091314ContentLabel2: "About TinyTrader ",
  events2023091314Content2:
    "At TinyTrader, our unwavering commitment to delivering cutting-edge white label solutions is at the core of our mission. We empower a diverse spectrum of projects,spanning traditional finance, CFD brokerages, financial technology, and the crypto sector, to elevate their business operations. Our team embodies a unique blend of expertise and transparency, driven by an unwavering commitment to your success. We are well-prepared to share our technological prowess and knowledge to propel your achievements.",
  events2023091314ContentLabel3: "Final Thoughts",
  events2023091314Content3:
    "In summary, TinyTrader's participation in TOKEN2049 heralds a transformative moment in blockchain and digital finance. This partnership promises to ignite innovation, collaboration, and insights within the industry. As we anticipate this premier event, it's evident that TinyTrader's expertise will drive the evolution of global financial services, offering attendees a unique opportunity to stay at the forefront of the blockchain landscape.",

  events20230915Title:
    "TT and OKX at Token 2049: Pioneering Liquidity Solutions",
  events20230915SubTitle:
    "Token 2049 Week in Singapore is set to host an exclusive private event where industry leaders and experts will convene to explore the future of liquidity solutions in the cryptocurrency market.",
  events20230915SubTitleDetail: "",
  events20230915ContentLabel1: "Introduction:",
  events20230915Content1:
    "Token 2049 Week in Singapore is set to host an exclusive private event where industry leaders and experts will convene to explore the future of liquidity solutions in the cryptocurrency market. TT and OKX jointly present this event, showcasing their collaborative efforts in delivering flexible spot liquidity solutions, a vital aspect of cryptocurrency trading. The event's agenda includes a keynote address by Viktor Filimonov, Product Manager at TinyTrader, who will shed light on the innovative approaches adopted by TT and OKX to empower liquidity within the trading infrastructure.",
  events20230915ContentLabel2: "About the Speaker:",
  events20230915Content2:
    "Viktor Filimonov, Product Manager at TinyTrader, brings extensive knowledge and experience to the realm of liquidity solutions. He will share insights into how TT and OKX are revolutionizing liquidity, providing seamless and flexible options for clients.",
  events20230915ContentLabel3: "Final Thoughts:",
  events20230915Content3:
    "TT and OKX's collaboration at Token 2049 reflects their commitment to advancing liquidity solutions in the cryptocurrency space. This exclusive event promises insightful discussions, expanded networks, and a glimpse into the future of liquidity solutions. It's an opportunity for attendees to connect with like-minded individuals and gain a deeper understanding of the dynamic cryptocurrency market. TT and OKX's dedication to innovation and collaboration sets the stage for a promising and flexible future in cryptocurrency trading.",

  events20230916Title: "TinyTrader's Presence at Wiki Finance Expo Sydney 2023",
  events20230916SubTitle:
    "Save the date for November 16, 2023, as TinyTrader steps into the spotlight at Wiki Finance Expo Sydney, a part of Wiki Expos illustrious global financial exhibition and fintech conference series.",
  events20230916SubTitleDetail: `CEO and co-founder Zo Liang will take the stage to deliver a keynote speech titled "Proprietary Trading Insights: How White Label Solutions Give You an Edge.`,
  events20230916ContentLabel1: "Keynote Speech by CEO Zo Liang",
  events20230916Content1: `CEO and co-founder Zo Liang will take the stage to deliver a keynote speech titled "Proprietary Trading Insights: How White Label Solutions Give You an Edge." This speech promises to offer valuable insights into the power of white label solutions in gaining a competitive edge in proprietary trading.`,
  events20230916ContentLabel2: "Final Words",
  events20230916Content2:
    "TinyTrader's presence at Wiki Finance Expo Sydney, complete with their booth at C1 and CEO Zo Liang's keynote speech, reaffirms their commitment to innovation and collaboration. This collaboration is set to accelerate opportunities and shape the future of financial technology and blockchain, all under the umbrella of TinyTrader's cutting-edge solutions and the wisdom shared by its co-founder.",

  eventsPhotosTitle: "We Can’t Wait To See You",
};

export default en;
