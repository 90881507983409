import i18n from "i18next";
import Cookies from "js-cookie";
import { initReactI18next } from "react-i18next";
import resources from "@/locales/index";
import { Langeuages } from "@/utils/variable";

export function getBorwserLan() {
  var jsSrc = (navigator.language || navigator.browserLanguage).toLowerCase();
  if (jsSrc.indexOf("zh") !== -1) {
    return Langeuages.zh_CN;
  } else if (jsSrc.indexOf("en") !== -1) {
    return Langeuages.en_US;
  } else if (jsSrc.indexOf("ko") !== -1) {
    return Langeuages.ko_KR;
  } else {
    return Langeuages.en_US;
  }
}

export function getInitLan() {
  return localStorage.getItem("lan") || getBorwserLan() || Langeuages.en_US;
}

const lan = getInitLan();
Cookies.set("locale", lan);
i18n.use(initReactI18next).init({
  resources,
  lng: lan,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
