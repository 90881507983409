const zh = {
  slogan_title: "白标加密货币交易所",
  slogan_desc1: "坚固、安全、可扩展.",
  slogan_desc2: "TinyTrader自2017年以来一直在颠覆市场",
  seven_day_setup: "7天快速上线",
  multi_language: "多语言支持",
  support_24hours: "全天候支持",
  request_callback: "申请使用",
  learn_more: "查看更多",
  product_solutions_title_main: "全面的加密生态系统，量身定制的区块链解决方案。",
  product_solutions_title_main_detail: "TinyTrader提供一站式的SaaS解决方案，提供您启动和发展加密货币交易所所需的所有工具。",
  matching_core:"撮合引擎",
  matching_core_desc: "我们的交易匹配引擎以70K TPS的容量撮合买卖双方订单，具有区块链的所有安全性优势",
  trading_ui:"交易界面",
  trading_ui_desc:"TinyTrader的默认“极简界面”可以根据您的品牌在多个平台上进行无限定制。",
  liquidity: "流动性",
  liquidity_desc:"我们的白标解决方案提供流动性算法，以实现市场上最佳的买卖价差。",
  operational_terminal: "后台管理",
  operational_terminal_desc:"后台管理系统旨在管理您的用户、合作伙伴、订单和资金。",
  key_features: "核心特点",
  asset_custody: "资产托管",
  asset_custody_desc: "通过我们的合作伙伴HyperBC，受益于数字资产托管的安全性和可扩展性。",
  wallet: "钱包",
  wallet_desc: "提供安全且无限制的多链钱包，以便大规模管理数字资产。",
  affiliate_partnetships: "联盟合作",
  affiliate_partnetships_desc: "领先的合作伙伴计划和周边工具在各个阶段促进您的增长。",
  copy_trading: "跟单交易",
  copy_trading_desc: "KOL分享交易策略，普通用户一键跟单，轻松赚钱。",
  user_authentication: "用户认证",
  user_authentication_desc: "集成了手动和自动的KYC系统，通过管理终端帮助您了解客户的所有信息。",
  security: "安全性",
  security_desc: "通过广泛的系统控制保护用户数据，以减轻泄漏、技术和欺诈威胁。",
  //Solutions
  solutions:"解决方案",
  solutions_desc:"<b>TinyTrader</b>为您的加密货币交易所提供全面的<b>数字资产生态系统</b>支持，助力您的<b>业务增长</b>。",
  back_office:"后台管理",
  back_office1:"代理合伙人管理",
  back_office2:"自动/人工KYC审核",
  back_office3:"跟单交易管理",
  back_office4:"多语言支持",
  back_office5:"交易佣金设置",
  back_office6:"交易历史分析",
  back_office7:"文档存储",

  trading_ui: "交易界面",
  trading_ui_1: "永续合约、期权和差价合约（CFD）交易模式",
  trading_ui_2: "直观、信息丰富的交易视图",
  trading_ui_3: "委托历史记录、余额和账户摘要",
  trading_ui_4: "期货损益计算器",
  trading_ui_5: "多平台跨系统开发",

  matching_core: "撮合引擎",
  matching_core_1:"每秒处理能力高达70,000笔交易",
  matching_core_2:"最长交易执行时间：30毫秒",
  matching_core_3:"支持的订单类型（市价、限价、触发价、全部成交或全部撤单、立即成交或取消）",
  matching_core_4:"逐仓保证金模式和全仓保证金模式",

  liquidity:"流动性",
  liquidity_1: "获得主流交易所的深度流动资金池",
  liquidity_2: "算法增强流动性",
  liquidity_3: "流动性和价差调整",
  liquidity_4: "资产管理",

  marketing: "运营工具",
  marketing_1: "多级返佣",
  marketing_2: "先进的跟单交易集成",
  marketing_3: "内容管理系统",
  marketing_4: "首页轮播和公告",
  marketing_5: "任务中心",
  marketing_6: "空投",

  security: "安全",
  security_1:"反钓鱼和 DDoS 保护",
  security_2:"MPC 安全多方计算",
  security_3:"数字签名算法（ECDSA）",
  security_4:"同态加密（HE）",
  security_5:"加密数据库",
  security_6:"TEE 可信环境",
  security_7:"多云 TEE 安全架构",
  security_8:"多因素身份验证（MFA）",

  //Why US
  why_us: "为什么选择我们？",
  why_us_1: "TinyTrader定制的白标加密货币交易平台以其坚固、安全和可扩展的架构而闻名——这家屡获殊荣的公司以其专业的咨询和响应迅速的支持而备受认可。",
  why_us_2: "TinyTrader交易所已成为其所属领域的全球领导者，并在我们的支持下每天产生超过80亿美元的交易量。",
  why_us_3: "不要只是参与市场，要掌控市场。",

  //Our Clients
  our_clients: "我们的客户",
  our_clients_title: "我们为全球客户提供一站式白标解决方案。",
  our_clients_desc_coinw: "CoinW是全球顶级交易所之一，以其广泛的交易策略和功能而闻名。该平台包括期货交易、高级订单类型、庞大的合作伙伴网络以及全球最高的返利佣金。",
  our_clients_desc_biking: "Biking Exchange致力于加密数字资产的投资和管理，在加拿大拥有经过许可和注册的MSB证书。",
  our_clients_desc_ulink: "Ulink Exchange是一家全球领先的加密货币交易所，致力于以安全、透明、公正和高效性的方式提供衍生品交易。它是亚洲最值得信赖的加密货币交易平台之一。",
  our_clients_desc_sunbit: "Sunbit Exchange是世界级的加密资产交易所，其日均交易量超过5000万美元。该交易所专注于平稳、安全和高效的交易体验。",

  //Client Testimonials
  clientTestimonials: "客户见证",
  client_testimonials_coinw: "TinyTrader是我们业务的改变者。他们的白标加密货币交易平台易于使用、可靠，并拥有我们的用户喜爱的功能。技术支持团队对我们非常有帮助，自从切换到TinyTrader以来，我们看到了用户满意度和留存率的显著提高。强烈推荐！",
  client_testimonials_coinw_positon: "业务发展总监",
  client_testimonials_biking: "一开始我们对切换到一个新的白标加密货币交易平台持有犹豫态度，但TinyTrader证明了这是我们业务做出的最佳决策。该平台对用户友好且可定制，帮助我们吸引了许多新用户。TinyTrader团队始终帮助我们解决问题或疑虑，并且努力确保我们的满意度。我们非常满意选择与TinyTrader合作。",
  client_testimonials_biking_positon: "首席技术官",
  client_testimonials_ulink: "我们正在寻找一种成本效益高且能提供高质量服务的白标加密货币交易平台。TinyTrader在各个方面都超出了我们的预期。该平台直观易用，帮助我们简化交易流程。技术支持团队始终回答我们的问题，并帮助我们将平台的功能发挥最大化。我们强烈推荐TinyTrader给任何想进入加密货币领域的企业。",
  client_testimonials_ulink_positon: "首席营销官",
  client_testimonials_sunbit: "我们已经使用TinyTrader作为我们的白标加密货币交易平台一年多了，对结果非常满意。该平台坚固可靠，帮助我们显著增加交易量。TinyTrader团队始终提供支持和帮助，并愿意多做努力以确保我们的满意度。我们强烈推荐TinyTrader给任何寻找高质量加密货币交易平台的企业。",
  client_testimonials_sunbit_positon: "业务发展经理",
  prev: "上一条",
  next: "下一条",

  //Featured in
  featured_in: "精选展示",

  // FAQ
  faq: "常见问题",
  faq_title: "您需要了解的所有内容#OwnTheMarket",
  faq_1_q:"撮合引擎的容量是多少？",
  faq_1_a:"TinyTrader的撮合引擎可以处理每秒70,000笔交易（TPS）。",
  faq_2_q:"交易所上线需要多长时间？",
  faq_2_a:"在商户提供所需材料（logo、UI颜色代码、域名等）后，平均搭建时间为7个工作日。",
  faq_3_q:"您的撮合引擎支持哪些订单类型？",
  faq_3_a:"我们的SaaS解决方案支持的订单类型：市价单、限价单、触发价、全部成交或全部撤单、立即成交或取消。",
  faq_4_q:"您提供在排名网站上列出交易所的机会吗？",
  faq_4_a:"我们提供所需的数据和技术支持，以便在排名门户网站上列出您的加密货币交易所。",
  faq_5_q:"您的解决方案支持哪些平台？",
  faq_5_a:"我们的交易所解决方案支持主流移动和桌面终端，以及通过Web应用程序访问。",
  faq_6_q:"是否可以在本地服务器上托管交易所？",
  faq_6_a:"不可以，TinyTrader使用Amazon Web Services以最佳方式保护您和用户的数据和体验。",

  // Footer
  mailing_address: "地址",
  email_address: "电子邮件",
  phone_num: "电话号码",

  //Thank You For Commit
  thank_you: "感谢",
  thank_you_1: "感谢您填写表格。我们已经收到您的申请，CSR将稍后与您联系！",
  thank_you_2: "直接通过Whatsup和Telegram联系我们",
  thank_you_3: "立即预约会议",

  product:"产品",
  case:"案例",
  solution: "解决方案",

};

export default zh;