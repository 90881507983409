const ko = {
  account1: "Telegram",
  account2: "Wechat",
  account3: "Facebook",
  account4: "Linked In",
  account5: "Medium",
  privacy_policy: "개인보호 약관",
  services: "거래 플랫폼 서비스",
  servicesSubTitle: "제품과 서비스",
  servicesT1: "계약거래 시스템",
  servicesT1ST1Title: "고성능 중매 엔진",
  servicesT1ST1Desc: "강력한 성능, 고신뢰성, 고확장 가능한 중매 시스템",
  servicesT1ST2Title: "다중 거래 패턴",
  servicesT1ST2Desc:
    "영속계약, 차익계약, 무한계약, USDT/장 자유전환 및 계산단위 지원하며 멀티플레이트 양방향 포지셔닝 및 고배율 레버 지원한다",
  servicesT1ST3Title: "전문거래",
  servicesT1ST3Desc:
    "K라인 기술 분석 지표와 선 그리기 도구 및 다양한 위탁 전략 및 화환 거래 제공한다",
  servicesT1ST4Title: "지원 기능",
  servicesT1ST4Desc: "재무 시스템, 리스크 관리 시스템 및 운영 관리 백그라운드",
  servicesT2: "옵션거래시스템",
  servicesT2ST1Title: "자금 운용이 더욱 원활하다",
  servicesT2ST1Desc:
    "옵션은 사용자가 소량의 자금으로 대규모 투자를 할 수 있으며 제한된 자금을 최대한 활용할 수 있도록 한다",
  servicesT2ST2Title: "일류의 유동성",
  servicesT2ST2Desc:
    "Tinytrader는 일류의 유동성과 시장 깊이를 제공하며 최소한 슬리피지로 사용자에게 공정하고 투명한 거래 경험을 제공한다",
  servicesT2ST3Title: "한계 있는 리스크, 한계 없는 수익",
  servicesT2ST3Desc:
    "사용자는 옵션 보유자로서 성공할 때는 무한한 이익을 얻을 수 있고, 실패할 때는 최소한 손실만 입을 수 있다",
  servicesT2ST4Title: "다양한 거래 옵션",
  servicesT2ST4Desc:
    "유연하게 조합하여 수익을 지속적으로 창출할 수 있으며 시장 변동에 대한 걱정이 없다",
  servicesT3: "현물 거래 시스템",
  servicesT3ST1Title: "양질의 심층 지원",
  servicesT3ST1Desc:
    "더욱 완벽한 중매 거래로 인기 화폐에 대한 양호한 유동성을 보장하다.",
  servicesT3ST2Title: "안전한 지갑 기술",
  servicesT3ST2Desc:
    "안정적이고 안전한 지갑은 저변 기술 지원으로 편리한 캐시백과 풍부한 운영 활동가치를 제공한다",
  servicesT3ST3Title: "다중 직쇄 토큰 지원",
  servicesT3ST3Desc:
    "다중 직쇄 토큰의 제출 충전을 지원하여 사용상의 편리함을 제공하는 동시에 디지털 자산의 사용 문턱을 낮추다",
  servicesT4: "장외 거래시스템",
  servicesT4ST1Title: "효율적이고 신속하다",
  servicesT4ST1Desc:
    "법정화폐와 디지털화폐의 거래 중매를 효율적이고 신속하게 완성한다",
  servicesT4ST2Title: "다채널 방식",
  servicesT4ST2Desc:
    "플랫폼 출입금 루트로 조작이 편리하고 풀랫폼의 수용자를 효과적으로 확대한다",
  servicesT4ST3Title: "안전하고 철저한 리스크 통제",
  servicesT4ST3Desc:
    "안전하고 철저한 리스크 통제로 사용자의 출입금 안전을 보장한다",
  servicesT5: "NFT 거래 시스템",
  servicesT5ST1Title: "유동성",
  servicesT5ST1Desc: "NFT의 거래로 통해 플랫폼 내의 유동성을 증가한다",
  servicesT5ST2Title: "다각화거래",
  servicesT5ST2Desc:
    "정가 거래나 경매 등 다양한 환경에서의 NFT 거래를 지원하고 다양한 통화로 거래하는 것을 진원한다",
  servicesT5ST3Title: "백그라운드 관리 시스템",
  servicesT5ST3Desc:
    "고객은 백그라운드를 통해 체계적으로 플랫폼을 관리할 수 있으며 플랫폼 운영 및 유지 관리에서 관련 데이터를 신속하게 파악할 수 있다",
  servicesT6: "증정금 거래 시스템 ",
  servicesT6ST1Title: "증정금 해본다 ",
  servicesT6ST1Desc:
    "사용자가 가상 증정금을 받으면 증정금 계약, 소곰대전등 다양한 거래 시스템을 해볼 수 있다",
  servicesT6ST2Title: "무한 공중 투하",
  servicesT6ST2Desc:
    "사용자는 플랫폼 증정금을 받을 수 있으며 증정금이 실효되기 전에 사용할 수 있다",
  servicesT6ST3Title: "수익 진U",
  servicesT6ST3Desc:
    "사용자가 증정금 계약 또는 소곰을 통해 얻은 수익은 일정한 액도에 도달하면 진U로 이체될 수 있다",
  servicesT7: "선물거래 그리드 로봇",
  servicesT7ST1Title: "전천후 자동 거래",
  servicesT7ST1Desc:
    "선물 거래 그리드 로봇을 사용하면 계속 가격 동향을 추적할 필요가 없습니다. 포지션 정보를 입력하고 로봇에게 모든 것을 맡기만 하면수익을 받을 수 있습니다.",
  servicesT7ST2Title: "과매/공매/중립 전략 옵션",
  servicesT7ST2Desc:
    "과매전략을 선택하면 로봇은 과매 거래 개시와 청산만 진행합니다. 공매 전략을 선택하면 로봇은 공매 거래 개시와 청산만 진행합니다. 중립 전략을 선택하면 로봇이 가격은 시가보다 높을 때 공매 거래 개시와 청산을 진행하며, 가격은 시가보다 낮을 때 과매 거래 개시와 청산을 진행합니다.",
  servicesT7ST3Title: "레버리지로 수익을 확대합니다",
  servicesT7ST3Desc:
    "레버리지로 거래하고 사용자의 포지션 규모를 확대하여 더 높은 수익을 얻습니다.",
  solutions: "새로운 파생상품 해결방안",
  solutionsSubTitle:
    "지난 10여 년 동안tinytrader 서비스는 수십 개의 거래소를 방문하여 최첨단 새로운 파생 금융 해결방안을 제공하고 수백 가지의 성숙한 직쇄 토큰을 지원할 수 있는 서비스 프레임워크를 구축하였다.",
  solutionsT1: "거래소 해결방안",
  solutionsT1ST1Title: "유연성",
  solutionsT1ST1Desc:
    "매칭 기능 모듈, app 조판 양식 및 배치 방식을 자유롭게 선택하여 보다 특색 있고 맞춤형 거래소 시스템을 구축할 수 있다",
  solutionsT1ST2Title: "활동성",
  solutionsT1ST2Desc:
    "다양한 마케팅 도구를 조합하여 사용하는 것은 사용자가 획득하는 효율을 높일 수 있을 뿐만 아니라 기존 사용자의 보유율과 활동도를 크게 높일 수 있다",
  solutionsT1ST3Title: "풍부성",
  solutionsT1ST3Desc:
    "여러 거래 모듈을 병행하여 풍부한 거래 패턴으로 사용자에게 풍부한 거래 장면을 제공한다",
  solutionsT2: "파생 금융상품 해결방안",
  solutionsT2ST1Title: "다종 금융상품",
  solutionsT2ST1Desc:
    "영속계약, 차익계약, 각종 옵션 및 주문상품 등을 포함하여 고객의 파생영역을 배치하여 시장 유동량을 선점한다",
  solutionsT2ST2Title: "탄탄한 기술 기반",
  solutionsT2ST2Desc:
    "클라우드 노드 서비스, 오프라인 서명 시스템, 냉온 지갑 등 다양한 기술적 수단을 활용하여 금융 기능의 프로세스와 효율성을 확보한다",
  solutionsT2ST3Title: "최고의 리스크 관리 대책",
  solutionsT2ST3Desc:
    "최고의 리스크 관리 대책을 사용하여 포괄적인 리스크 관리 시스템으로 고객 금융자산에 대한 안전성 확보한다",
  solutionsT3: "SaaS 제품 해결방안",
  solutionsT3ST1Title: "일체형 서비스",
  solutionsT3ST1Desc:
    "APP, WEB, 관리 백그라운드, 에이전트 백그라운드, 이벤트 백그라운드 등 전반적인 관리, 운영을 아우르는 SAAS 시스템",
  solutionsT3ST2Title: "완벽한 거래 체계",
  solutionsT3ST2Desc:
    "파생상품 거래시스템, 지급시스템, NFT거래시스템, 증정금 시스템, 화환시스템이 통합된 플랫폼 체계",
  solutionsT3ST3Title: "초고 유동성",
  solutionsT3ST3Desc: "선도 업종 유동성 지원, 1000건 이상 거래쌍 지원",
  solutionsT4: "사용자 증가 해결방안",
  solutionsT4ST1Title: "증정금 모델",
  solutionsT4ST1Desc:
    "증정금 모델은 Tinytrader 혁신적으로 개발한 체험금 플레이 방식이다. 사용자는 각종 방식을 통해 증정금을 받고, 전속거래구역에서 이윤을 낸 후 이체 조작을 진행할 수 있다.",
  solutionsT4ST2Title: "화환커뮤니티",
  solutionsT4ST2Desc:
    "화환 기능은 커뮤니티의 상호 작용을 효과적으로 향상시킬 수 있으며 사용자의 정책 선택에 더 많은 참고 자료를 제공할 수 있다. 주문 기능을 활성화한 후, 주문 거래원의 모든 오픈 포지셔닝 행위가 동시에 수행될 것이다.",
  solutionsT4ST3Title: "작업 센터",
  solutionsT4ST3Desc:
    "Tinytrader의 맞춤형 사용자 인센티브 센터는 운영 목표에 따라 다양한 작업 내용을 설정할 수 있으며, 다양한 선별 조건의 추가를 지원하여 운영 및 시장 목표에 더 잘 맞는 제품 지원을 제공할 수 있다.",
  solutionsT4ST4Title: "브로커 시스템",
  solutionsT4ST4Desc:
    "브로커 시스템을 혁신적으로 사용하고, 커뮤니티식 사용자 관리 시스템을 제공하며 강력한 플랫폼 사용자 분열 효능을 실현하고 규모의 효과를 발생시킨다.",
  solutionsT5: "자산관리 해결방안",
  solutionsT5ST1Title: "안정적이다",
  solutionsT5ST1Desc:
    "여러 부서가 협력하여 플랫폼 자산을 관리하고, MPC의 최고 수준의 암호화 기술을 활용하여 분산 관리를 실현하고 잠재적 위험을 제거한다",
  solutionsT5ST2Title: "효율적이고 편리하다",
  solutionsT5ST2Desc:
    "수백 개가 넘는 주류 체인 노드에 접속하여 동시에 자산 위탁 관리, 교차 거래소 자산 배치를 포함하는 일련의 편리한 거래를 만족시킨다",
  solutionsT5ST3Title: "라스크 관리 보증",
  solutionsT5ST3Desc:
    "튼튼한 리스크 관리 서비스를 제공하고 신뢰할 수 있는 준법 제공업체를 선택하고 종합적이고 전면적인 리스크 관리  준법 체계를 수립하여 전 세계 여러 지역의 준법 준칙을 만족시킨다",
  solutionsT6: "Web 3.0 해결방안",
  solutionsT6ST1Title: "web 3.0 기술",
  solutionsT6ST1Desc:
    "다양한 블록체인 최고의 기술을 통합적으로 사용하여 업계에서 가장 안정적인 web3.0 서비스 업체 중 하나이다",
  solutionsT6ST2Title: "web 3.0 제품",
  solutionsT6ST2Desc:
    "완벽한 NFT 플랫폼, 성숙한 클라우드 서비스로 업계 벤치마킹 web 3.0 제품을  창출한다",
  solutionsT6ST3Title: "Web 3.0 구성원",
  solutionsT6ST3Desc:
    "업계 내 전문가로 구성된 수십 명의 자문팀과 기술팀이 핵심 업종 방향에 대한 지적 지원을 제공한다",
  solutionsT7: "안전사항의 해결방안",
  solutionsT7ST1Title: "웹사이트 안전 심사",
  solutionsT7ST1Desc:
    "Tinytrader의 전문적 팀이 당신의 웹사이트의 코드와 환경을 검사하여 위험을 식별할 것입니다",
  solutionsT7ST2Title: "디지털 지갑 안전 심사",
  solutionsT7ST2Desc:
    "심사보고서의 권고 사항에 따라 디지털 지갑 프로그램의 버그를 복구하는 방법을 알게 될 것입니다",
  solutionsT7ST3Title: "스마트 계약 안전 심사",
  solutionsT7ST3Desc:
    "블록체인에 배포하기 전에 스마트 계약을 확인하여 안전 문제가 없는 것을 확인해 드립니다",
  solutionsT8: "운영 컨설팅 서비스",
  solutionsT8ST1Title: "온라인 운영 지원",
  solutionsT8ST1Desc:
    "고객들의 온라인 런칭을 지원하며 신속한 운영 활동 개시를 위한 전략을 개발합니다",
  solutionsT8ST2Title: "장기 운영 지원",
  solutionsT8ST2Desc:
    "종합적이고 효과적인 운영을 달성하고 미리 정의된 목표를 달성하기 위한 장기 운영 계획을 제공합니다",
  solutionsT8ST3Title: "맞춤형 솔루션 지원",
  solutionsT8ST3Desc:
    "중요한 사건들에 대해 맞춤형 커뮤니케이션 계획을 제공하여 특정 프로젝트 목표의 달성을 돕습니다",
  support: "서비스 지원",
  supportSubTitle:
    "tinytrader는 모든 사용자에게 집사적인 서비스 지원을 제공하며 모든 플랫폼 수요는 즉시 피드백과 추적을 받을 수 있으며 고객의 만족을 달성하기 위해 노력한다",
  supportT: "우리의 서비스",
  supportT1: "유동성 지원",
  supportT1Desc:
    "초기 사용자 부족 시 거래 유동성 부족의 문제점을 효과적으로 해결한다",
  supportT2: "1대1 기술 고문",
  supportT2Desc: "전문 1대1 멘토링으로 기술개발에 대한 궁금증을 해결한다",
  supportT3: "업무 운영 서비스",
  supportT3Desc: "지정업무에 대하여 전문화, 맞춤형 부가서비스 제공한다",
  supportT4: "제품운영교육",
  supportT4Desc:
    "업계 생태 및 제품 운영 지식에 대한 체계적인 교육을 실시하여 팀의 인식을 제고하다",
  supportT5: "주변 서비스 지원",
  supportT5Desc:
    "브랜드 성격과 시장 수요에 따라 주변 제품의 아이디어와 아이디어 방안을 제공한다",
  supportT6: "정기적으로 시스템 유지보수 업그레이드",
  supportT6Desc:
    "제품의 업그레이드과 연구개발 기능에 맞추어 온라인으로 부대 유지 보수 및 관련 서비스를 진행하다",
  supportT7: "API 인터페이스",
  supportT7Desc:
    "대외유형별 수록, 거래심도 도킹 등 과정에서 인터페이스 도킹이나 코드 최적화가 필요한 부분을 담당한다",
  supportPowerful: "서비스 우위",
  supportPowerfulT1Title: "다년간 기술이 축적되어 경험이 풍부하다",
  supportPowerfulT1Desc:
    "고주파 거래되는 파생상품의 경우 시스템의 안정화가 매우 중요하다. Tinytrader 팀은 풍부한 경험에 의존하여 거래 제품이 극단적인 시황과 순간적인 트래픽의 급속한 증가에도 안정적으로 작동하고 '다운' 상황이 발생하지 않도록 보장한다",
  supportPowerfulT2Title: "신속하게 건설 완성하고 시장 선점한다",
  supportPowerfulT2Desc:
    "시장은 빠르게 변화하고 있으며 기회를 놓치면 개발 비용보다 손실이 훨씬 크다. Tinytrader는 사용자를 위한 신속한 제품 구축을 보장하고 안정적인 성능을 전제로 모든 기회를 놓치지 않고 즉시 출시한다.",
  supportPowerfulT3Title: "매우 높은 완전성으로 사용자의 자산을 보장한다",
  supportPowerfulT3Desc:
    "냉온 지갑 분리, 다중 오프라인 서명 등을 통해 자산을 깊이 저장하여 사용자 및 플랫폼 자산의 안전을 보장한다. 강력한 디도스 세척력을 통해 다양한 유형의 디도스 트래픽을 완벽하게 방어하고 SYN Flood, UDP Flood, ICMP Flood 등의 공격을 100% 세척하며 사이트가 다운되지 않도록 충분한 보호 자원을 제공한다.",
  supportPowerfulT4Title: "사용자 확장하고 다차원 운영 지원한다",
  supportPowerfulT4Desc:
    "Tinytrader는 사용자 보조금, 작업 센터, 커뮤니티 인터랙션 등 다양한 운영 지원 기능을 제공하여 후속 사용자 성장과 시장 확장을 위한 풍부한 제품 기능 지원을 제공한다.",
  supportOpening: "오프 사이트 프로세스",
  supportOpeningT1: "당신의 요구를 이해한다",
  supportOpeningT2: "1v1 맞춤 프로그램",
  supportOpeningT3: "계약체결",
  supportOpeningT4: "자료 수집",
  supportOpeningT5: "온라인 배치",
  supportOpeningT6: "체계적 트레이닝",
  supportOpeningT7: "고객검수",
  supportOpeningT8: "온라인 운영 관리",
  supportCompliance: "컴플라이언스 서비스",
  supportComplianceT: "Tinytrader - 컴플라이언스 금융 라이센스 지원",
  supportComplianceD1:
    "Tinytrader는 싱가포르에 본사를 둔 블록체인 신금융 파생상품 SAAS 기술 연구개발 및 블록체인 금융상품에 종사하는 풀스택 서비스 플랫폼이다.",
  supportComplianceD2:
    "Tinytrader는 300명 이상의 최고 기술팀을 보유하고 있으며, 3년 동안 기술을 축적하여 수만 명의 고객에게 서비스를 제공하였다. 팀원들은 모두 국제적으로 유명한 인터넷 회사 출신으로 다년간의 금융 상품 및 블록체인 응용 개발 경험을 가지고 있다. ",
  supportComplianceD3:
    "Tinytrader 블록체인 종사자들을 위한 전방위적인 블록체인 제품 및 기술 서비스 지원에 힘쓰고 있으며 구체적인 서비스로는 거래 플랫폼 구축, 클라우드 서비스, 새로운 금융 파생 상품 개발, 블록체인 응용 개발 등이 있다.",
  case: "성공 사례",
  caseSubTitle:
    "우리는 많은 거래소에게 기술 지원을 제공하여 많은 브랜드가 신뢰하는 파트너로 되었습니다",
  caseT1: "우리 고객",
  caseT2: "성공 프로젝트",
  caseT2ST1Title: "Hyper",
  caseT2ST1Desc:
    'Hyper W는 세이셸 공화국에 정식 등록된 암호화폐 금융 파생상품 거래소다. 이 플랫폼은 증권 거래소 수준의 보안을 갖춘 기술 아키텍처를 제공하고 수백만 명의 사용자에게 다양한 금융 파생 상품 거래를 제공하도록 노력해 왔다. Hyper W는 항상 "혁신, 효율성 및 보안"을 고수하며 전 세계 사용자에게 양질의 혁신적인 제품과 서비스를 제공하여 세계 일류의 암호화폐 파생상품 서비스 제공자가 되었다. HyperW 는 고급 금융 엔지니어링 전문가 그룹에 의해 시작되었다. 이 팀은 금융 파생상품 업계에서 다년간의 경험을 가지고 있다. 핵심 구성원은 칭화대학교, 베이징대학교, 푸단대학교 등 중국 중점 대학교 출신으로 골드만삭스, 바이두, 오라클, 텐센트, 알리바바 등 엘리트들을 모아 신세대 탈중심화 파생상품 거래 플랫폼을 만들려고 하였다',
  caseT2ST2Title: "Ulink",
  caseT2ST2Desc:
    'Ulink 은 싱가포르에 본사를 둔 세계 최고 수준의 규정 준수 전문 블록체인 파생상품 거래 플랫폼입니다.유니온은 시종"안전, 자유, 사용자"의 회사 이념을 계승하여 유럽, 아메리카, 동남아시아, 홍콩, 싱가포르 등 지역의 120여만 사용자에게 거래 서비스를 제공한다.이 회사는 미국과 캐나다에 이중 MSB 라이센스를 보유하고 있으며 세계화가 가속화되고 있습니다.현재 전 세계에 독립적인 비즈니스를 제공하고 있습니다.미래에는 "안전, 자유, 사용자" 를 기반으로 구현하기 위해 많은 노력을 기울일 것입니다. Ulink 의 목표는 글로벌 블록 체인 생태계 구축을 달성하는 완전한 글로벌 디지털 자산 금융 생태계를 만드는 것입니다.',
  caseT2ST3Title: "BWFX",
  caseT2ST3Desc:
    "BWFX거래소(BWFX.site)는 글로벌 컴플라이언스 전문적 선도 블록체인 파생상품 거래 플랫폼으로 본사는 싱가포르에 있으며, '안전, 자유, 진진심'이라는 거래 개념을가지고 유럽, 미주, 동남아시아, 홍콩, 싱가포르 등 지역에 120만 이상의 사용자를 대상으로 거래 서비스를 전개하고 있습니다. BWFX는 세계 100대 기업의 블록체인 기술 전문가 팀을 보유하고 있으며, 세계 최고의 보안 기술을 융합하여 사용자에게 안전하고 편리한 거래 서비스를 제공하도록 노력하고 있습니다.",
  caseT2ST4Title: "Biking",
  caseT2ST4Desc:
    "세계 최고의 블록 체인 디지털 자산 계약 거래 플랫폼 인 Biking은 디지털 화폐 거래, 블록 체인 교육, 블록 체인 프로젝트 인큐베이팅, 블록 체인 자산 발행 플랫폼 및 블록 체인 연구 기관 및 블록 체인 공익 및 자선 서비스를 전 세계에 계속 제공합니다.현재 자전거 사용자는 전 세계 30여 개 국가와 지역을 커버하고 있다.비킹은 초당 140만건의 주문이 이뤄지는 핵심 메모리 매칭 기술로 싱가포르 수도 인타빙으로부터 2천만달러(1억3천만원)의 전략적 투자를 받았다.동시에 미국과 캐나다에서 MSB 라이센스를 취득한 규정 준수 해외 계약 거래소입니다.",
  caseT2ST5Title: "Sunbit",
  caseT2ST5Desc:
    "Sunbit은 세계 최고의 보안 디지털 자산 파생 상품 거래 플랫폼입니다.디지털 화폐 거래 플랫폼과 금융 파생상품을 합법적으로 운영할 수 있는 허가증을 가지고 있다.선빗은 블록체인 분야 전문 국제팀이 개발·운영하며 거래 시스템, 위험 통제, 결제 및 보안 관리에 풍부한 경험을 보유하고 있다.Sunbit은 규정 준수, 전문성, 친절성, 사용자 최우선, 서비스 최우선의 방식으로 전 세계 사용자에게 안전하고 안정적이며 편리한 디지털 자산 거래 서비스를 제공하기 위해 노력합니다.",
  caseT2ST6Title: "HPX",
  caseT2ST6Desc:
    "HPX는 블록체인 사용자 경험에 초점을 맞춘 블록체인 디지털 자산 거래 플랫폼이다.HPX 거래소 소프트웨어는 사용자에게 편리한 암호화/법정 통화 거래 및 안전하고 편안한 거래 환경을 제공합니다.사용자는 HPX 거래소 앱에 가서 다중 통화 거래, 디지털 자산 추세 조회, 온라인 채굴을 통해 돈을 벌 수 있다.문제 및 우려 사항을 해결하는 데 도움이 되는 온라인 전문 컨설턴트와 자세한 정보를 제공하는 유용한 전문 강좌가 있습니다.",
  caseT3: "우리에 대해서",
  more: "추가",
  why: "왜 우릴 선택했지?",
  why1Title: "사용자 자산을 보호하는 최고의 안정성",
  why1Desc:
    "사용자 및 플랫폼 자산의 안전을 보장하기 위해 냉온 지갑 격리, 오프라인 다중 서명 등을 통해 자산을 저장한다. 강력한 DDoS 정리 기능으로 SYN Flood, UDP Flood, ICMP Flood 등 각종 공격에 완벽하게 방어할 수 있어 사이트 폐쇄를 피할 수 있다.",
  why2Title: "혁신적이고 포괄적인 컴플라이언스 해결방안",
  why2Desc:
    "TinyTrader의 혁신적이고 토털 컴플라이언스 해결방안은 디지털 자산 교환 해결방안, NFT 거래 플랫폼 해결방안, 지갑 해결방안, 유동성 해결방안, 자산 관리 해결방안 등이다.",
  why3Title: "국가 전략적 인터넷 기술센터",
  why3Desc:
    "파생상품의 고주파 거래의 경우 시스템의 안정성이 매우 중요하다. TinyTrader 팀은 극한의 시장 조건과 트래픽 급증에도 '셧다운'이 발생하지 않도록 전문 기술과 풍부한 경험을 바탕으로 한다. ",
  why4Title: "다년간의 업계 경험",
  why4Desc:
    "우리는 강력한 기술 전문성과 풍부한 경험을 바탕으로 기업에 집적하기 쉽고 안전한 블록체인 해결방안을 제공한다.",
  why5Title: "전문적인 전략 지원",
  why5Desc:
    "아시아-태평양 지역의 고객들을 대상으로 전문적인 제3자 컨설팅 팀을 선택합니다. 신뢰성 있고 효과적인 운영 전략을 제공하여 고객들의 운영적 결함을 해소하고 미리 정의된 목표를 신속히 달성할 수 있도록 지원합니다.",
  why6Title: "풍부한 자원 지원",
  why6Desc:
    "채널 자원, 비즈니스 파트너십, 미디어 자원, 시장 메이킹 자원, 커뮤니티 자원 및 자본 자원을 포함하여 제품 운영 중 필요한 자원을 제공하기 위해 산업 자원을 통합하고 개방적인 서비스 접근 방식을 고수합니다.",
  about: "우리에 대해서",
  about1:
    "Tinytrader는 싱가포르에 본사를 둔 블록체인 신금융 파생상품 SAAS 기술 연구개발 및 블록체인 금융상품에 종사하는 풀스택 서비스 플랫폼이다.",
  about2: "팀 맴버",
  about3: "거래처",
  about4: "국가",
  about5: "일 거래량",
  about6: "50억 달러",
  ourPartners: "파트너",
  banner: "<div>자신의 거래소</div> <b>창립</b>",
  bannerContent1: "7일 설치",
  bannerContent2: "멀티 자산 유동성",
  bannerContent3: "전천후의 지원",
  home: "첫 페이지",
  products: "제품",
  news: "News",
  apply: "사용신청",
  applyDesc: "원하는 거래 및 추가 정보 얻기",
  name: "이름",
  nameError: "성명을 입력해주세요",
  email: "이메일",
  emailError: "이메일을 입력하세요",
  socialMedia: "소셜 계정",
  socialMediaError: "계정을 입력하세요",
  message: "정보",
  submit: "제출",
  seeMore: "자세히 보기",
  slogan: "	미래를 향한 걸음",
  coreProducts: "	핵심 제품",
  coreProductsDesc:
    "저희의 핵심 제품은 현물, 파생상품, 장외거래, nft, 전략거래, 자산증식, 결제 등 시장에 나와 있는 모든 암호화폐의 핵심 기능을 거버하고 있습니다. 높은 매칭 엔진 용량, 낮은 지연 시간, 고급 리스크 관리 및 높은 유동성은 TinyTrader를 독특한 산업 참여자로 만들었습니다. 저희는 데스크톱, PC 및 모바일 애플리케이션 이 세 가지 다른 인터페이스를 통해 애플리케이션을 제공합니다.",
  headerMenuT1: "제품",
  headerMenuT1ST1: "디지털 자산 교환",
  headerMenuT1ST1D1:
    "현물, 파생상품, 장외거래, OTC,NFTs, 전략거래, 자산증식, 결제 등 시장의 모든 암호화폐 핵심 기능을 커버합니다",
  headerMenuT1ST1D2:
    "선도적인 리스크 관리 전략을 통합하여 거래소의 안전을 확보합니다",
  headerMenuT1ST1D3:
    "SaaS(공유 클라우드), 사유화(위탁 관리), SDK, 사유화 이 네 가지 구현 방안을 제공합니다",
  headerMenuT1ST1D4:
    "데스크톱, PC, 모바일 앱 이 세 가지 인터페이스를 통해 이 앱을 제공합니다",
  headerMenuT1ST1D5: "상위 3대 거래소의 메인체인과 대응 토큰을 지원합니다",
  headerMenuT1ST1D6: "업계 선두의 유동성 서비스를 제공합니다",
  headerMenuT1ST1D7: "매칭 엔진 : 강력한 성능, 높은 신뢰성",
  headerMenuT1ST2: "조작 도구",
  headerMenuT1ST2D1:
    "다양한 운영 도구를 개발하여 고객들이 데이터와 작극적인 사용자를 획득하기를 지원합니다",
  headerMenuT1ST2D2: "포춘 전략 거래: 카피 거래와 그리드 거래를 지원합니다",
  headerMenuT1ST2D3:
    "토큰을 입금하면 이자가 발생하므로 사용자의 점성과 거래소가 관리하는 자금규모를 향상됩니다",
  headerMenuT1ST2D4:
    "럭키머니, 공투, 초청 장려, 거래 시연 등을 통해 신규 사용자를 확보하고 유지합니다",
  headerMenuT1ST3: "NFT 거래 플랫폼",
  headerMenuT1ST3D1: "NFT 거래로 플랫폼 유동성 늘립니다",
  headerMenuT1ST3D2: "지능화된 관리 도구로 데이터 통찰력을 향상시킵니다",
  headerMenuT1ST3D3:
    "NFT 거래, 블라인드 박스 거래, 고정 가격 거래, 경매 등 다양한 환경에서의 다통화 거래를 지원합니다",
  headerMenuT1ST3D4:
    "IPFS에 분산 저장하여 데이터의 안정성과 신뢰성을 확보합니다",
  headerMenuT1ST4: "API",
  headerMenuT1ST4D1: "귀하의 비즈니스 요구를 위해 API를 집성합니다",
  headerMenuT1ST4D2:
    "개발자가 자신의 제품이나 서비스에 디지털 교환 기능을 집성할 수 있도록 다양한 API를 제공합니다",
  headerMenuT1ST4D3:
    "SaaS 서비스(옵션, 선물, 마케팅 이벤트 등)는 모두 동일한 API를 제공하기 때문에 다양한 API를 배울 필요가 없습니다",
  headerMenuT1ST4D4:
    "서비스별 API(예: 주문, 스퀘어, 마켓 데이터 검색 등)는 Web、Android 및 iOS 애플리케이션을 위한 SDK 뒤에 있습니다",
  headerMenuT1ST5: "신용 카드",
  headerMenuT1ST5D1: "디지털 화폐를 예입하고 글로벌 결제를 즐깁니다",
  headerMenuT1ST5D2:
    "신용카드는 TinyTrader에서 발행됩니다. 가상 카드와 실물 카드를 제공합니다",
  headerMenuT1ST5D3: "플랫폼이 전 세계 고객을 확대하도록 돕습니다",
  headerMenuT1ST5D4: "Visa, MasterCard, 유니온페이를 지원합니다",
  headerMenuT1ST5D5:
    "자금은 준법한 위탁 관리 서비스 기관에서 위탁하여 자금의 안전을 확보해야 합니다",
  headerMenuT1ST5D6:
    "커버 디자인을 맞춤 제작하여 다양한 스타일를 개인화하여 전시합니다",
  headerMenuT1ST5D7: "전 세계 176개 국가, 5000만 상가가 지원합니다",
  headerMenuT2: "	해결방안",
  headerMenuT2ST1: "지갑",
  headerMenuT2ST1D1:
    "매우 안전하며, 환경부터 거래 데이터까지 모든 면에서 자산을 안전하게 보호합니다",
  headerMenuT2ST1D2: "지갑 하나로 50+공용체인 자산을 손쉽게 관리합니다",
  headerMenuT2ST1D3: "전문금융기관이 제공하는 투자 선택이다",
  headerMenuT2ST1D4:
    "스마트 라우팅, 최적의 가격, 슬라이딩 및 Gas를 자동으로 계산하여 거래를 쉽게 합니다",
  headerMenuT2ST2: "유동성",
  headerMenuT2ST2D1: "대형 거래소의 심층 유동성 풀을 획득합니다",
  headerMenuT2ST2D2: "더 낮은 매매 가격 차이와 더 나은 매매 가격을 지원합니다",
  headerMenuT2ST2D3: "현물 유동성과 파생상품 유동성을 포함합니다",
  headerMenuT2ST2D4: "모든 비즈니스 모델을 위한 맞춤형 유동성",
  headerMenuT2ST3: "자산위탁",
  headerMenuT2ST3D1:
    "기업 디지털 자산의 대역폭, 규모 및 금융 업무에 맞는 맞춤형 해결방안을 제공합니다",
  headerMenuT2ST3D2:
    "고객님의 기호에 따라 실시간, 매일 또는 고정 결제를 위한 업무용 지갑 주소를 지정합니다",
  headerMenuT2ST3D3: "개인적이고 사용자 친화적인 계기판을 갖추고 있습니다",
  headerMenuT2ST3D4: "운영 연구 개발 비용을 최소화합니다",
  headerMenuT2ST3D5: "50+ 블록체인과 160+ 주류 화폐를 지원합니다",
  headerMenuT2ST4: "Web3 인프라",
  headerMenuT2ST5: "분산 저장",
  headerMenuT2ST6: "운영 컨설팅 서비스",
  headerMenuT2ST6D1:
    "아시아-태평양 지역의 고객들을 대상으로 전문적인 제3자 컨설팅 팀을 선택합니다. 해당 팀은 화폐 거래소 중 유명한 플랫폼인 후오비(Huobi), 바이낸스(Binance), OKX 등의 멤버들로 구성되어 있으며 프로젝트 운영에 폭넓은 경험을 보유하고 있습니다",
  headerMenuT2ST6D2:
    "고객들이 신속한 온라인 운영을 달성하기 위해 전문적인 운영 프레임워크를 구축하는 데 도움을 드립니다",
  headerMenuT2ST6D3:
    "각 고객의 특성에 기반하여 운영, 마케팅, 홍보, 커뮤니티 및 이벤트 전략을 맞춤화합니다",
  headerMenuT2ST6D4:
    "고객들의 특정 요구에 기반하여 채널, 미디어, 커뮤니티, 비즈니스 파트너십 등의 자원 통합과 조율을 원활하게 지원합니다",
  headerMenuT2ST6D5:
    "프로젝트 운영 및 시장 홍보를 위해 전문화된 팀들을 조직하여 고객들에게 종합적이고 일대일로 컨설팅 서비스를 제공합니다",
  headerMenuT2ST6D6:
    "활동 기획, 이벤트 마케팅, 사용자 성장, 미디어 노출 및 커뮤니티 개발을 포함한 맞춤형 운영 컨설팅 서비스를 제공합니다",
  headerMenuT3: "어바웃",
  headerMenuT3ST1: "서비스 지원",
  headerMenuT3ST2: "성공 사례",
  PhoneNumber: "전화번호",
  EMailAddress: "이메일",
  LearnMore: "더 보기",
  navigation: "네비게이션",
  contact_your: "지원서가 접수되었습니다. CSR에서 나중에 연락드리겠습니다.",
  newSolutions: "디지털 자산 교환 해결방안",
  newSolutionsSubTitle:
    "TinyTrader는 원스톱 SaaS해결방안과 암호화 거래소를 시작하고 발전시키는 데 필요한 모든 도구를 제공합니다.",
  newSolutionsT1: "핵심 거래 플랫폼",
  newSolutionsT1ST1Title: "선물거래 시스템",
  newSolutionsT1ST2Title: "옵션거래 시스템",
  newSolutionsT1ST3Title: "현물거래 시스템",
  newSolutionsT1ST4Title: "장외거래 시스템",
  newSolutionsT1ST5Title: "NFT 거래 시스템",
  newSolutionsT1ST6Title: "보너스 거래 시스템",
  newSolutionsT1ST7Title: "선물 그리드 로봇",
  newSolutionsT2: "매치되는 핵심",
  newSolutionsT2ST1Title: "실행 능력은 70000 TPS이다",
  newSolutionsT2ST2Title: "최대 거래 실행 시간: 30밀리초",
  newSolutionsT2ST3Title:
    "모든 종류의 주문을 실행할 수 있다 (시장, 제한, 촉발, 채우기 또는 종료, 즉시 또는 취소)",
  newSolutionsT2ST4Title: "격리된 교차 보증금 거래 모델",
  newSolutionsT3: "거래 인터페이스",
  newSolutionsT3ST1Title: "영구 선물, 옵션 및 CFD 거래 모델",
  newSolutionsT3ST2Title: "직관적이고 정보가 풍부한 TradingView 그래프",
  newSolutionsT3ST3Title: "주문 역사 기록, 잔액 계정 요약",
  newSolutionsT3ST4Title: "선물 PL 계산기",
  newSolutionsT3ST5Title: "다중 플랫폼 운영 체제의 알 수 없는 개발",
  newSolutionsT4: "백그오피스",
  newSolutionsT4ST1Title: "파트너 관계 관리",
  newSolutionsT4ST2Title: "자동 수동 KYC 프로그램",
  newSolutionsT4ST3Title: "Copy-Trading 관리",
  newSolutionsT4ST4Title: "다국어 지원",
  newSolutionsT4ST5Title: "거래 수수료 설정",
  newSolutionsT4ST6Title: "무역 역사 분석",
  newSolutionsT4ST7Title: "파일 저장",
  newSolutionsT5: "조작 도구",
  newSolutionsT5ST1: "선물 그리드 로봇",
  newSolutionsT5ST2: "수익",
  newSolutionsT5ST2Title:
    "Tinytrader 재테크는 자산 관리 플랫폼으로 사용자의 암호화 자산이 안정적으로 증가하도록 도와줍니다. 경쟁력 있는 연간 수익률을 제공하고 높은 수익을 보장하며 자금에 대한 최고의 안전 보장을 제공합니다.",
  newSolutionsT5ST2T1: "거래 문턱 낮다",
  newSolutionsT5ST2D1:
    "수수료 없이 수시로 예금/인출할 수 있다. 예금되면 계단식 안정적 수익을 받을 수 있다",
  newSolutionsT5ST2T2: "일류의 안전 보장",
  newSolutionsT5ST2D2: "냉열 지갑 격리, 다중 안전 심사",
  newSolutionsT5ST2T3: "고수익 제품조합",
  newSolutionsT5ST2D3:
    "풍부한 투자 주기 및 유연한 투자 한도로 사용자의 다양한 수요를 만족시키고, 30+디지털 자산 유휴 자금으로 수익을 창출한다",
  newSolutionsT5ST3: "화환거래",
  newSolutionsT5ST3Title1: "거래달인",
  newSolutionsT5ST3Desc11:
    "KOL 공유거래전략, 더 많은 거래자를 이끌어 강약세를 뛰어넘는다",
  newSolutionsT5ST3Desc12: "화환 커뮤니티를 창립하며, 수익을 공유한다",
  newSolutionsT5ST3Title2: "화환 사용자",
  newSolutionsT5ST3Desc2: "원터치 화환, 즐겁게 돈 벌어",
  newSolutionsT5ST4: "데모 거래",
  newSolutionsT5ST4T1: "빠르게 시작하기",
  newSolutionsT5ST4D1:
    "대량의 거래 지식과 튜토리얼을 제공하여 신규 사용자가 거래 시장을 더 잘 알아보고 거래 기술을 배울 수 있도록 도와 준다",
  newSolutionsT5ST4T2: "리스크  피하기",
  newSolutionsT5ST4D2:
    "기존 사용자가 자신의 거래 전략을 실천하도록 하고, 여러번의 시행착오를 거쳐 자신에게 적합한 거래 방식을 찾도록 한다",
  newSolutionsT5ST4T3: "무료 보너스",
  newSolutionsT5ST4D3: "사용자가 무료로 증정금을 받고, 수익이 무한하다",
  newSolutionsT5ST4T4: "수익 인출",
  newSolutionsT5ST4D4:
    "손실에 대한 걱정 없이 거래 기술을 배울 수 있으며, 이익 한도액을 초과한 후에 현금을 인출할 수 있다",
  newSolutionsT5ST5: "마케팅 구성요소",
  newSolutionsT5ST5Text1:
    "미션 장려는 사용자의 사용 습관을 기르고, 미션을 완성함으로써 사용자의 활동성과 보유율을 높일 수 있습니다",
  newSolutionsT5ST5Text2:
    "사용자가 신규 사용자를 초청하여 거래를 등록하면, 거래소의  리베이트와 플랫폼 정려를 받을 수 있습니다",
  newSolutionsT5ST5Text3:
    "럭키머니 발송 기능은 거래소의 보급하는 데 도움을 줄 수 있습니다",
  newSolutionsT5ST5Text4: "거래 대회 이벤트 현장을 폭발시킨다",
  newSolutionsT7: "시스템 구조",
  newSolutionsT8: "배포 패키지 비교",
  newSolutionsT8ST1Title: "SaaS( 공용 클라우드)",
  newSolutionsT8ST1Desc1: "시장이 있다",
  newSolutionsT8ST1Desc2: "조작 능력을 갖춘다",
  newSolutionsT8ST1Desc3: "기술자를 절약한다",
  newSolutionsT8ST2Title: "사유화 (위탁관리) ",
  newSolutionsT8ST2Desc1: "시장이 있다",
  newSolutionsT8ST2Desc2: "조작 능력을 갖춘다",
  newSolutionsT8ST2Desc3: "기술자가 있다",
  newSolutionsT8ST2Desc4:
    "독립적으로 배치하고 싶지만 비용을 절감하고 소스코드가 필요하지 않다",
  newSolutionsT8ST3Title: "SDK",
  newSolutionsT8ST3Desc1: "시장이 있다",
  newSolutionsT8ST3Desc2: "조작 능력을 갖춘다",
  newSolutionsT8ST3Desc3: "기술자가 있다",
  newSolutionsT8ST3Desc4:
    "디지털 교환기의 기능을 자신의 제품과 서비스에 통합하고 싶다",
  newSolutionsT8ST4Title: "사유화",
  newSolutionsT8ST4Desc1: "거래 시스템을 완전히 장악하고 싶다",
  newSolutionsT8ST4Desc2:
    "자신의 조작 아이디어에 따라 최대한 시스템을 맞춤화했으면 한다",
  newSolutionsT8ST4Desc3: "지정/특수 국가를 위해 서비스 제공하고 싶다",
  newSolutionsT8ST4Desc4: "리스크 안전 기술 지원이 있다",

  // 新版首页
  headerApply: "Book A Demo",
  tabTech: "Tech",
  tabLiquidity: "Liquidity",
  liquidityMenuTitle1: "TT Hub",
  tabContact: "Contact Us",
  tinyTrader: "Tinytrader",
  techMenuTitle1: "Digital Asset Exchange",
  techMenuDesc1: "Full range trading platform solutions",
  techMenuTitle2: "TT Crypto Card",
  techMenuDesc2: "Digital banking card solution",
  techMenuTitle3: "Open API",
  techMenuDesc3: "Integration to existing platform solution",
  techMenuTitle4: "Proprietary Trading",
  techMenuDesc4: "Trading with firm capital solution",
  techMenuChildrenTitle1: "TT0: Spot",
  techMenuChildrenDesc1: "Spot Trading System",
  techMenuChildrenTitle2: "TT1: Futures",
  techMenuChildrenDesc2: "Futures Trading System",
  techMenuChildrenTitle3: "TT2: Options",
  techMenuChildrenDesc3: "Options Trading System",
  tabAbout: "About",
  aboutMenuTitle: "About Us",
  aboutMenuDesc:
    "We're revolutionizing 21st-century trading platforms by eradicating barriers, enhancing user experience, and pioneering seamless trading and liquidity solutions.",
  aboutMenuChildrenTitle1: "Company Overview",
  aboutMenuChildrenTitle2: "Team",
  aboutMenuChildrenTitle3: "Careers",
  tabResources: "Resources",
  resourcesMenuDesc:
    "Designed a comprehensive resources center for today's institutional businesses, offering timely news updates, insightful education, and handpicked marketplace partners. Elevate your trading infrastructure with us.",
  resourcesChildrenTitle1: "TT Marketplace",
  resourcesChildrenTitle2: "News",
  resourcesChildrenTitle3: "Education",
  resourcesChildrenTitle4: "Events",

  bannerTitle1: "Advanced White-Label",
  bannerTitle2: "Trading Technology & Liquidity",
  bannerTitle3: "Solutions Provider",
  bannerTitle4: "Tiny Steps to Trade in the Future",
  ttLearnMore: "Learn More",
  bannerNumber1: "50+",
  bannerNumberDesc1: "Institutional Clients",
  bannerNumber2: "100+",
  bannerNumberDesc2: "Employees",
  bannerNumber3: "10+",
  bannerNumberDesc3: "Countries",
  bannerNumber4: "$5B+",
  bannerNumberDesc4: "Volume",

  footerCopyright1: "TinyTrader. All Rights Reserved.",
  footerCopyright2:
    "Trademarks In Singapore (Official Registration Numbers To Be Confirmed). TinyTrader Represents A Formalized Joint Venture",
  footerCopyright3:
    "Established Through Contractual Alliances Among Distinct Legal Entities And Esteemed Individuals Within Singapore",
  footerSlogan:
    "TinyTrader (TT) Is an advanced white-label trading technology and liquidity solutions provider that connects digital-asset trading between fiat and digital currencies for global trading platforms, brokers, developers, and institutions.",
  footerProducts: "Products",
  footerProducts1: "Digital Asset",
  footerProducts2: "TT Spot",
  footerProducts3: "TT Futures ",
  footerProducts4: "TT Options",
  footerProducts8: "Prop Trading",
  footerProducts5: "Crypto Card",
  footerProducts6: "Open API ",
  footerProducts7: "TT Marketplace",
  footerLearn: "Learn",
  footerLearn1: "News",
  footerLearn2: "Event",
  footerLearn3: "About",
  footerLearn4: "Carrers",
  footerLegal: "Legal",
  footerLegal1: "Terms of Services",
  footerLegal2: "Privacy Policy",
  footerSupport: "Support",
  footerSupport1: "Contact Us",
  footerSupport2: "FAQ",
  footerSupport3: "Prop Trading App Download",
  emailTitle: "Stay Connected!",
  emailDesc: "Get Our Newsletter",
  emailBtn: "Enter your Email",

  whoTitle: "Who We Are",
  whoDesc:
    "We are committed to empowering every client, from traditional finance to emerging fintech, with our cutting-edge trading technologies and liquidity solutions",

  clientTitle: "Open to Every Type of a Client",
  clientTitle1:
    "We are committed to empowering every client, from traditional finance to emerging fintech, with our cutting-edge trading technologies and liquidity solutions",
  clientTitle2: "From Traditional Finance To Emerging Fintech,",
  clientTitle3: "With Our Cutting-Edge Cerivatives And Liquidity Solutions",
  clientListTitle1: "TradFi Companies",
  clientListDesc1:
    "We provides entities such as FOREX, CFD firms and brokerages with seamless integration to the digital asset market, establishing an additional revenue stream, whilst circumventing the necessity of building a proprietary platform from the ground up.",
  clientListTitle2: "Financial Technologies ",
  clientListDesc2:
    "Our offering for wallets, custodians, and digital trading venues provide opportunity to deliver a secure, scalable, and customizable platform that amplifies your service offerings and equips you with a competitive edge trading technologies landscape.",
  clientListTitle3: "Crypto Projects",
  clientListDesc3:
    "We provides a robust, intuitive trading infrastructure, ideal for tokens and crypto project owners. This invariably enhances the visibility of your project and ensures optimal market market.",

  solutionsTitle: "Our Solutions",
  solutionsTitle1:
    "We provide a complete solutions suite covering infrastructure development and ecosystem support for forward-looking businesses exploring new revenue streams",
  solutionsTitle2: "Ecosystem Support For Forward-Looking Businesses Exploring",

  solutionsMenuTitle1: "White Label Crypto Exchange",
  solutionsMenuList1Item1:
    "Execution in matching engine with high speed, efficiency and utmost reliability",
  solutionsMenuList1Item2:
    "Provided with the full-range digital asset trading platform functionality, including spot trading, derivatives (futures and options), prop trading, P2P, including ecosystem services",
  solutionsMenuList1Item3:
    "Integrated risk management solutions to guarantee exchange security",
  solutionsMenuList1Item4:
    "Delivered deployment solutions: SaaS (Public Cloud), SDK and API integrations",
  solutionsMenuList1Item5:
    "Availability across platforms: Desktop, PC, and mobile applications",
  solutionsMenuList1Item6:
    "Provided aggregated liquidity services on the white label crypto exchange platform",

  solutionsMenuTitle2: "Operational Tools",
  solutionsMenuList2Item1:
    "Cover all of the cryptocurrency core functions in the market, such as spot, derivatives, OTC, NFTs, strategy trading, asset value-added, payments, etc",
  solutionsMenuList2Item2:
    "Integrate leading risk control strategies to ensure the security of exchanges",
  solutionsMenuList2Item3:
    "Provide four deployment solutions to meet your needs: SaaS (Public Cloud), Privatization (Hosting), SDK, Privatization",
  solutionsMenuList2Item4:
    "Offer these across three different interfaces: desktop, PC, and mobile apps",
  solutionsMenuList2Item5:
    "Support main chains and corresponding tokens of top three exchanges",
  solutionsMenuList2Item6: "Offer industry leading liquidity services",
  solutionsMenuList2Item7:
    "Matching Engine: powerful performance, high reliability",

  solutionsMenuTitle3: "API",
  solutionsMenuList3Item1:
    "Comprehensive API support enhancing exchange infrastructure integration",
  solutionsMenuList3Item2:
    "Seamless WebSocket and RestAPI integration for real-time data exchange and operations",
  solutionsMenuList3Item3:
    "Program API provision, enabling software development grounded in our systems",
  solutionsMenuList3Item4:
    "Open architecture, promoting adaptability and customization for diverse applications",
  solutionsMenuList3Item5:
    "Empower developers with detailed documentation and support, ensuring efficient system utilization",
  solutionsMenuList3Item6:
    "Prioritize security and efficiency, ensuring rapid data transfers with utmost integrity",
  solutionsMenuList3Item7:
    "Foster innovation, supporting third-party extensions and collaborative integrations",

  solutionsMenuTitle4: "Crypto Card",
  solutionsMenuList4Item1:
    "Secured digital banking integration service powered by white-label integration",
  solutionsMenuList4Item2:
    "Secure issuance of digital assets payment cards, ensuring user trust and reliability",
  solutionsMenuList4Item3:
    "Seamless integration with the exchange, facilitating instantaneous digital assets transactions",
  solutionsMenuList4Item4:
    "Equip users with the ability to transact both online and offline worldwide",
  solutionsMenuList4Item5:
    "Maintain the highest standards of security, ensuring asset protection and transactional integrity",
  solutionsMenuList4Item6:
    "Simplify user experiences through offering of instant virtual card provisioning, eliminating lengthy forms",
  solutionsMenuList4Item7:
    "Flexibility in fund management: Provide seamless options for users to convert back to USDT",

  solutionsMenuTitle5: "Liquidity",
  solutionsMenuList5Item1:
    "Internal organic liquidity pool for consistent market depth",
  solutionsMenuList5Item2:
    "Seamless liquidity bridging with top-tier crypto exchanges",
  solutionsMenuList5Item3: "Advanced Market Making (MM) algorithms",
  solutionsMenuList5Item4:
    "RestAPI and WebSocket API across markets external MM connectivity",

  liquidityTitle: "Liquidity Solutions",
  liquidityDesc1:
    "We offer a variety of liquidity solutions, including Remarketer automated market",
  liquidityDesc2:
    "making software and solutions that provide spot liquidity, derivatives liquidity ",
  liquidityDesc3: "and leveraged ETF liquidity.",
  liquidityBtn1: "Internal Liquidity Pool",
  liquidityBtn2: "Liquidity Bridges",
  liquidityBtn3: "External API Connectivity",

  marketplaceTitle: "TT Marketplace",
  marketplaceDesc1:
    "Ideal venue for businesses seeking marketing collaborations, co-marketing activities, integrations, and revenue-sharing opportunities. With a diverse community of companies, we offer an open and dynamic environment for strategic partnerships. By harnessing the power of synergistic alliances, businesses can expand their reach and access TT’s extensive customer base, which continues to grow exponentially.",
  marketplaceDesc2:
    "By Harnessing The Power Of Synergistic Alliances, Businesses Can Expand Their Reach And Access TT’s Extensive Customer Base, Which Continues To Grow Exponentially.",

  awardsTitle: "Awards & Licensing",
  awardsItem1Title: "ISO 27001:2022",
  awardsItem1Desc: "Information security, cybersecurity and privacy protection",
  awardsItem2Title: "DMCC DLT License",
  awardsItem2Desc:
    "Authority for blockchain and cryptocurrency businesses in Dubai, UAE",
  awardsItem3Title: "RAK DAO Digital-Assets Oasis License",
  awardsItem3Desc: "Digital asset businesses in Ras Al Khaimah, UAE",
  awardsItem4Title: "Best Blockchain Data Solution 2023",
  awardsItem4Desc:
    "Awarded as best Blockchain Solution Provider of the year by Sigma",
  awardsItem5Title: "Best Liquidity Provider 2023",
  awardsItem5Desc:
    "Awarded as the best Liquidity Provider of the year by CryptoExpo",
  awardsItem6Title: "Best White-Label Solution Provider 2023",
  awardsItem6Desc: "Awarded as White-Label Solution Provider by WikiExpo",

  partnersTitle: "Our Partners",
  partnersDesc: "Don’t take our word for it. Trust our customers",
  partnersItem1Title:
    "CoinW is globally recognized as a leading exchange platform, characterized by its advanced trading capabilities. It offers trading in different markets,",
  partnersItem1Desc:
    "a comprehensive affiliate  system, and some of the industry's highest rebate bonus structures.",
  partnersItem2Title:
    "OKX is a leading cryptocurrency exchange renowned for its",
  partnersItem2Desc:
    "advanced technology, offering users a platform to trade, invest, and manage their digital assets securely and efficiently.",
  partnersItem3Title:
    "Alchemy Pay is globally acknowledged as a forefront payment gateway, characterized by its cutting-edge transaction solutions. It provides seamless crypto-to-fiat conversions, intricate payment protocol integrations,",
  partnersItem3Desc: "",
  partnersItem4Title:
    "Jumio is universally esteemed as a pinnacle in digital identity verification, characterized by its state-of-the-art authentication mechanisms. It offers advanced biometric scanning, intricate document verification processes,",
  partnersItem4Desc: "",
  partnersItem5Title:
    "AWS (Amazon Web Services) is universally recognized as a paramount entity in cloud computing, epitomized by its pioneering infrastructure services.",
  partnersItem5Desc: "",
  partnersItem6Title:
    "CoinMarketCap is the world's most-referenced price-tracking website for cryptoassets in the rapidly growing cryptocurrency space.",
  partnersItem6Desc: "",

  noFoundTitle: "Oops it seems you follow backlink",
  noFoundBtn: "Go To Home",

  comingTitle: "We’re Coming Soon..",
  comingDesc:
    "Lorem ipsum dolor sit amet consectetur. Vel sit facilisi mauris vitae aliquam velit molestie imperdiet eget. In sociis gravida posuere arcu egestas. ",
  comingItem1: "360",
  comingItem1Unit: "DAYS",
  comingItem2: "24",
  comingItem2Unit: "HOURS",
  comingItem3: "60",
  comingItem3Unit: "MUNUTES",
  comingItem4: "60",
  comingItem4Unit: "SECONDS",
  cookieTitle:
    "We use cookies to personalize and improve your experience on our website. By clicking “Accept” you consent to our",
  cookiePolicy: "Cookies Policy.",
  cookieDecline: "Decline",
  cookieAccept: "Accept",
  hyp_tiny_saas_download_Platforms:
    "Any time, anywhere. Trading made easy over multiple platforms",
  hyp_tiny_saas_download_Available:
    "Available on iOS, Android, Windows, and Mac. Offering you full-fledged functions, on the go",
  hyp_tiny_saas_download_Mobile: "Mobile",
  hyp_tiny_saas_download_Desktop: "Desktop",
  hyp_tiny_saas_download_DownloadText_Android: "Android Download",
  hyp_tiny_saas_download_DownloadText_iOS: "iOS Download",
};

export default ko;
