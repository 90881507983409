const en = {
  chatbot_welcome: "👋 Welcome to TT Chatbot, your personal AI assistant. Do you require frequently asked <a href='#' style='text-decoration: none;color:#FAB600' >questions about the platform</a>? I am here to assist you! I will provide market insights based on data from the TT platform.",
  chatbot_bytheway: "By the way, you can create a chatbot just like me for your website!",
  chatbot_expired: "The session has expired. The system will not consider the information in the history chat",
  chatbot_analyzing: "Analyzing your question",
  chatbot_system: "Sorry, I can't identify what {XXX} stands for. Please provide more information or correct your input",
  chatbot_identify: "System error, please ask again.",
  chatbot_changeLanguage: "Change Language",
  chatbot_language: "Language",
  chatbot_chinese: "Chinese",
  chatbot_endChat: "End Chat",
  chatbot_cancel: "Cancel",
  chatbot_looking: "Are you looking for",
  chatbot_customized: "What services can be customized?",
  chatbot_supported: "Which currencies are supported?",
  chatbot_create: "You can create a chatbot just like me for your website!",
  chatbot_start: "Start Chat",
  chatbot_AIAssistant: "Welcome to TT Chatbot, your personal AI assistant, I am here to assist you! ",
  chatbot_currentLanguage: "Resume",
  chatbot_solved: "Solved",
  chatbot_unsolved: "Unsolved",
  chatbot_enterQuestion: "Please enter your question",
  chatbot_feedback: "Thank you for your feedback",
};

export default en;
