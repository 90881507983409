const en = {
  slogan_title: "White-Label Crypto Exchange",
  slogan_desc1: "Robust, secure & scalable.",
  slogan_desc2: "TinyTrader exchanges have been disrupting the market since 2017",
  seven_day_setup: "7-day setup",
  multi_language: "Multi-language",
  support_24hours: "24/7 Support",
  request_callback: "REQUEST A CALLBACK",
  learn_more: "Learn More",
  product_solutions_title_main: "Comprehensive crypto ecosystem Bespoke blockchain solutions.",
  product_solutions_title_main_detail: "TinyTrader provides a one-stop SaaS solution with all the tools you need to launch and grow your crypto exchange.",
  matching_core:"Matching Core",
  matching_core_desc: "Our transaction matching engine executes bid-ask orders at a capacity of 70K TPS, with all the security benefits of the blockchain",
  trading_ui:"Trading UI",
  trading_ui_desc:"TinyTrader's default 'minimal UI' can be endlessly customized to best reflect your brand across multiple platforms",
  liquidity: "Liquidity",
  liquidity_desc:"Our white-label solution facilitates liquidity provision algorithms to achieve the tightest spreads in the market",
  operational_terminal: "Operational Terminal",
  operational_terminal_desc:"Advanced Back-end office system aimed to manage your users, affiliates, documents and cashflow.",
  key_features: "Key Features",
  asset_custody: "Asset Custody",
  asset_custody_desc: "Benefit from the security and scalability of digital asset custody from our partners, HyperBC.",
  wallet: "Wallet",
  wallet_desc: "Secure and unlimited provision of robust multi-chain wallets for digital asset management at scale.",
  affiliate_partnetships: "Affiliate Partnetships",
  affiliate_partnetships_desc: "Advanced partnership schemes and peripheral tools boost your growth at all stages.",
  copy_trading: "Copy-trading",
  copy_trading_desc: "A collaborative investment mode that allows users to learn and profit from the techniques of vetted advanced traders.",
  user_authentication: "User Authentication",
  user_authentication_desc: "Integrated systems of manual and automated KYC in the administrative terminal will help you know all information about your client.",
  security: "Security",
  security_desc: "Protect your users data with extensive system controls to mitigate leakages, technical and fraud threats.",
  //Solutions
  solutions:"Solutions",
  solutions_desc:"<b>TinyTrader</b> provides comprehensive <b>digital asset ecosystems</b> to support<b> the growth</b> of your cryptocurrency exchange",
  back_office:"Back-Office",
  back_office1:"Partnerships Management",
  back_office2:"Auto & Manual KYC Procedures",
  back_office3:"Copy-Trading Management",
  back_office4:"Multilingual Support",
  back_office5:"Trading Commissions Settings",
  back_office6:"Trade History Analysis",
  back_office7:"Documents Storage",

  trading_ui: "Trading UI",
  trading_ui_1: "Perpetual Futures, Options, and CFD Trading Modes",
  trading_ui_2: "Intuitive, informative TradingView charts",
  trading_ui_3: "Order History, Balance & Account Summary",
  trading_ui_4: "Futures P&L Calculator",
  trading_ui_5: "Multi-platform OS-agnostic development",

  matching_core: "Matching Core",
  matching_core_1:"Execution capacity of 70 000 TPS",
  matching_core_2:"Max trade execution time: 30 ms",
  matching_core_3:"Ability to execute all types of orders <br /> (Market, Limit, Trigger, Fill or Kill, Immediate or Cancel)",
  matching_core_4:"Isolated & Cross-Margin Trading Mode",

  liquidity:"Liquidity",
  liquidity_1: "Internal Order-book Organic Liquidity",
  liquidity_2: "Algorithmic Liquidity Enhancement",
  liquidity_3: "Liquidity & Spread Adjustments",
  liquidity_4: "Asset Management",

  marketing: "Marketing",
  marketing_1: "Multi-level Affiliate System",
  marketing_2: "Advanced Copy-trading Integration",
  marketing_3: "Content Management System",
  marketing_4: "Banners and Announcements",
  marketing_5: "Mission Reward System",
  marketing_6: "Airdrop Management System",

  security_1:"Antiphishing & DDoS Protection",
  security_2:"MPC Secure Multi-Party Computing",
  security_3:"Digital Signature Algorithm (ECDSA)",
  security_4:"Homomorphic Encryption (HE)",
  security_5:"Encrypted Database",
  security_6:"TEE Trusted Environment",
  security_7:"Multi-Cloud TEE Security Architecture",
  security_8:"Multifactor authentication (MFA)",

   //Why US
  why_us: "Why Us?",
  why_us_1: "TinyTrader’s bespoke whitelabel cryptocurrency trading platforms are renowned for their robust, secure, and scalable architecture - and the award winning company has been recognized for its dedicated consulting and responsive support.",
  why_us_2: "TinyTrader exchanges have gone on to become global leaders in their categories, and with our support produce daily volumes in excess of 8 billion USD per day.",
  why_us_3: "Don’t play the market, own the market.",

  //Our Clients
  our_clients: "Our Clients",
  our_clients_title: "We provide end-to-end white-label solutions to clients worldwide",
  our_clients_desc_coinw: "CoinW ranks among the top exchanges in the world, and is renowned for its broad range of trading options and features, including futures trading, advanced order types, an expansive affiliate network, and some of the world's highest rebate bonuses.",
  our_clients_desc_biking: "Biking Exchange is committed to the investment and management of crypto digital assets, with a licensed and registered MSB certificate in Canada.",
  our_clients_desc_ulink: "Ulink Exchange is a world leading cryptocurrency exchange that is committed to offering derivatives trading with security, transparency, fairness and high efficiency. One of the most trusted cryptocurrency trading platforms across Asia.",
  our_clients_desc_sunbit: "Sunbit Exchange is a world class crypto-asset trading. The daily trading volume reaches more than 50 million U.S. dollars. The exchange itself is focuses on smoothness, secure, and efficient trading experiences.",

  //Client Testimonials
  clientTestimonials: "Client Testimonials",
  client_testimonials_coinw: "TinyTrader has been a game-changer for our business. Their whitelabel cryptocurrency trading platform is easy to use, reliable, and packed with features that our customers love. The support team has been incredibly helpful, and we've seen a significant increase in customer satisfaction and retention since switching to TinyTrader. Highly recommend!",
  client_testimonials_coinw_positon: "Business Development Director",
  client_testimonials_biking: "We were hesitant to switch to a new whitelabel cryptocurrency trading platform, but TinyTrader has proven to be the best decision we've made for our business. The platform is user-friendly, customizable, and has helped us attract new customers. The team at TinyTrader is always available to assist with any questions or issues, and they go above and beyond to make sure we are satisfied. We couldn't be happier with our choice to partner with TinyTrader.",
  client_testimonials_biking_positon: "Chief Technical Officer",
  client_testimonials_ulink: "We were looking for a cost-effective whitelabel cryptocurrency trading platform that could still deliver top-quality service. TinyTrader exceeded our expectations in every way. The platform is intuitive, easy to use, and has helped us streamline our trading processes. The support team is always available to answer our questions and help us get the most out of the platform. We highly recommend TinyTrader to any business looking to get into the cryptocurrency space.",
  client_testimonials_ulink_positon: "Chief Marketing Officer",
  client_testimonials_sunbit: "We've been using TinyTrader for our whitelabel cryptocurrency trading platform for over a year now, and we couldn't be happier with the results. The platform is robust, reliable, and has helped us increase our trading volume significantly. The team at TinyTrader is always available to provide support and assistance, and they are always willing to go the extra mile to make sure we are satisfied. We highly recommend TinyTrader to any business looking for a top-quality cryptocurrency trading platform.",
  client_testimonials_sunbit_positon: "Business Development Manager",
  prev: "PREV",
  next: "NEXT",

  //Featured in
  featured_in: "Featured in",

  //Contact Us
  cotact_us: "Contact Us",
  cotact_us_title: "Book A Brief Consultation With Our Blockchain Experts",
  name: "Name",
  email: "Email",
  phone_number:"Phone Number",
  social_media:"Social Media",
  preferred_language:"Preferred Language",
  comments_city_timezone: "Comments / City / Timezone",
  cotact_us_remarks: "TinyTrader uses the provided information to contact you about our relevant product and services",

  // FAQ
  faq: "FAQ",
  faq_title: "Everything you need to know to #OwnTheMarket",
  faq_1_q:"What is the capacity of the Matching Engine?",
  faq_1_a:"TinyTrader’s Matching Engine can handle up to 70 000 transactions per second (TPS).",
  faq_2_q:"How long does it take to set up an exchange?",
  faq_2_a:"After all the needed materials provided by the clients ( Logo, UI color-code, domain names, etc..) the average time to setup is 7 days.",
  faq_3_q:"Which order types are supported by your matching engine?",
  faq_3_a:"Our SaaS solution provides the opportunity to execute all types of orders: Market, Limit, Fill-or-Kill and Immediate-or-Cancel.",
  faq_4_q:"Do you provide opportunities to list the exchange on ranking websites?",
  faq_4_a:"We provide required data endpoints and support to list your crypto exchange on ranking portals.",
  faq_5_q:"Which platforms do your solutions support?",
  faq_5_a:"Our exchange solution is supported by all major mobile and desktop platforms, as well as through the web-app.",
  faq_6_q:"Is it possible to host an exchange on local servers?",
  faq_6_a:"No, TinyTrader uses Amazon Web Services in order to best protect yours and users’ data & experience.",

  // Footer
  mailing_address: "MAILING ADDRESS",
  email_address: "EMAIL ADDRESS",
  phone_num: "PHONE NUMBER",

  //Thank You For Commit
  thank_you: "TinyTrader your Gateway to Running a Profitable Exchange!",
  thank_you_1: "Thank you for filling out the form. Your message has been received. Our team will get in touch with you soon.",
  thank_you_2: "Contact us via Telegram & WhatsApp directly",
  thank_you_3: "Or book a meeting ",

  product:"Product",
  case:"Case",
  solution: "Solution",
};

export default en;