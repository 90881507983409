const en = {
  account1: "Telegram",
  account2: "Wechat",
  account3: "Facebook",
  account4: "Linked In",
  account5: "Medium",
  privacy_policy: "Privacy Policy",
  services: "Trading Platform Services",
  servicesSubTitle: "Products and Services",
  servicesT1: "Futures Trading System",
  servicesT1ST1Title: "High-performance Matching Engine",
  servicesT1ST1Desc:
    "The powerful, highly reliable, and expandable matching system",
  servicesT1ST2Title: "Multiple Trading Modes",
  servicesT1ST2Desc:
    "Perpetual contracts, contracts for differences, and Infinite contract; Swap and calculate USDT/contract; two-way hedge positions and high leverage",
  servicesT1ST3Title: "Professional Trading",
  servicesT1ST3Desc:
    "Provide technical analysis indicators and drawing tools of the Candlestick chart, multiple order strategies, and copy trading",
  servicesT1ST4Title: "Supporting Functions",
  servicesT1ST4Desc:
    "Supporting financial system, risk control system, and operation management",
  servicesT2: "Options Trading System",
  servicesT2ST1Title: "Fund utilization is more flexible",
  servicesT2ST1Desc:
    "Options allow users to use a small amount of capital to invest on a large scale, so that users can use limited funds to achieve maximum capital utilization",
  servicesT2ST2Title: "Top-notch liquidity",
  servicesT2ST2Desc:
    "Tinytrader offers best-in-class liquidity and market depth. This provides our users with a fair and transparent trading experience with minimal slippage",
  servicesT2ST3Title: "Limited Risk, Unlimited Upside",
  servicesT2ST3Desc:
    "Users, as option holders, can earn unlimited profits when they are right and limit losses when they are wrong",
  servicesT2ST4Title: "Expand trading opportunities",
  servicesT2ST4Desc:
    "Various options can be flexibly combined to continuously create income without fear of market fluctuations",
  servicesT3: "Spot Trading System",
  servicesT3ST1Title: "High-quality Deep Support",
  servicesT3ST1Desc: "Better deal matching to make popular pairs more liquid",
  servicesT3ST2Title: "Secure Wallet Technology",
  servicesT3ST2Desc:
    "The stable and secure wallet provides underlying technical support, easy deposit and withdrawal, and extensive operational significance",
  servicesT3ST3Title: "Support Currencies from Multiple Main Chains",
  servicesT3ST3Desc:
    "It can conveniently deposit and withdraw cryptocurrency pairs in multiple mainchains, and lowers the threshold to digital assets",
  servicesT4: "OTC Trading System",
  servicesT4ST1Title: "Efficient and Fast",
  servicesT4ST1Desc:
    "Efficient and fast deal matching between fiat money and digital currency",
  servicesT4ST2Title: "Multi-channel Approach",
  servicesT4ST2Desc:
    "The platform's easy-to-operate deposit and withdrawal channel can effectively grow users",
  servicesT4ST3Title: "Safe and Sound Risk Control",
  servicesT4ST3Desc:
    "Safe and sound risk control mechanism to ensure the security of user's deposit and withdrawal",
  servicesT5: "NFT Trading System",
  servicesT5ST1Title: "Liquidity",
  servicesT5ST1Desc:
    "Increase the liquidity in the platform through NFT trading",
  servicesT5ST2Title: "Diversified Trading",
  servicesT5ST2Desc:
    "The platform enables NFT trading in multiple environments such as fixed price trading or auction and supports trading in multiple currencies",
  servicesT5ST3Title: "Background Management System",
  servicesT5ST3Desc:
    "Users can systematically manage the platform through the background and can get a quick glance at the data in the platform operation and maintenance",
  servicesT6: "Bonus Trading System",
  servicesT6ST1Title: "Free Trial",
  servicesT6ST1Desc:
    "Users can try various trading systems such as bonus contracts, and bull-bear markets when they get virtual bonus",
  servicesT6ST2Title: "Limitless Airdrop",
  servicesT6ST2Desc:
    "Users can get the bonus from the platform and use it before the expiry",
  servicesT6ST3Title: "Profitable Real Money",
  servicesT6ST3Desc:
    "The profits obtained by users through the bonus contract or bull-bear markets can be transferred to real money at a certain amount",
  servicesT7: "Futures Grid Bot",
  servicesT7ST1Title: "24/7 Automated Trading",
  servicesT7ST1Desc:
    "With the Futures Grid Bot, you don't have to constantly monitor price fluctuations. Simply enter your position(s), then sit back, relax and let the bot work its magic.",
  servicesT7ST2Title: "Three Modes — Long, Short and Neutral",
  servicesT7ST2Desc:
    "With long selected, the bot will only open and close long positions. With short selected, the bot will only open and close short positions. With netural selected, the bot will open or close shorts above the market price, and open or close longs below the market price.",
  servicesT7ST3Title: "Amplify Yields with Leverage",
  servicesT7ST3Desc:
    "Trade with leverage and amplify your position size to earn higher profits.",
  solutions: "New Financial Derivatives Solutions",
  solutionsSubTitle:
    "For more than a decade, TinyTrader has served dozens of exchanges, provided the most advanced new financial derivatives solutions, and built a service framework that can support hundreds of mature main chain tokens",
  solutionsT1: "Exchange Solutions",
  solutionsT1ST1Title: "Flexibility",
  solutionsT1ST1Desc:
    "Users can freely choose the matching function module, App layout style, and deployment mode to build a more distinctive and targeted exchange system",
  solutionsT1ST2Title: "Activeness",
  solutionsT1ST2Desc:
    "The combination of multiple marketing tools can not only improve the efficiency of user acquisition but also greatly increase the retention rate and activity percentile of current users",
  solutionsT1ST3Title: "Richness",
  solutionsT1ST3Desc:
    "Multiple trading modules and rich trading modes provide users with extensive trading scenarios",
  solutionsT2: "Financial Derivatives Solutions",
  solutionsT2ST1Title: "Various Financial Products",
  solutionsT2ST1Desc:
    "It covers perpetual contracts, contracts for differences, various options, and copy-trading products to help users expand their presence in derivative fields and seize market popularity",
  solutionsT2ST2Title: "Firm Technical Foundation",
  solutionsT2ST2Desc:
    "The cloud node services, offline signature systems, hot and cold wallets, and other technical means to ensure the flow and efficiency of financial functions",
  solutionsT2ST3Title: "Top Risk Control Measures",
  solutionsT2ST3Desc:
    "It uses the highest security measures and a comprehensive risk control system to ensure the security of users' financial assets",
  solutionsT3: "SaaS Product Solutions",
  solutionsT3ST1Title: "Integrated Services",
  solutionsT3ST1Desc:
    "The SAAS system involves comprehensive management and operation of APP, WEB, management background, agent background, event background, etc",
  solutionsT3ST2Title: "Sound Trading System",
  solutionsT3ST2Desc:
    "The platform system integrates a derivatives trading system, payment system, NFT trading system, bonus system, and copy-trading system",
  solutionsT3ST3Title: "Super-high Liquidity",
  solutionsT3ST3Desc:
    "It has leading liquidity and supports more than 1000 trading pairs",
  solutionsT4: "User Growth Solutions",
  solutionsT4ST1Title: "Bonus Model",
  solutionsT4ST1Desc:
    "The bonus model is the try-out money offerings innovatively developed by TinyTrader. Users can get the bonus in various ways and transfer them after making profits in the exclusive trading area",
  solutionsT4ST2Title: "Copy-trading Community",
  solutionsT4ST2Desc:
    "The copy-trading function can effectively improve community interaction, and also provide more references for users to choose strategies. After the copy-trading function is started, all the opening and closing positions of the copy traders will be executed synchronously",
  solutionsT4ST3Title: "Task Center",
  solutionsT4ST3Desc:
    "TinyTrader's customizable user incentive center can set different tasks according to the operation goals and  add multiple tasks with various filtering conditions, being better aligned with the operation and market goals to provide product support",
  solutionsT4ST4Title: "Broker System",
  solutionsT4ST4Desc:
    "It adopts broker system, and community-based user management system, greatly increasing platform user and producing scale efficiency",
  solutionsT5: "Asset Custody Solutions",
  solutionsT5ST1Title: "Safe and Stable",
  solutionsT5ST1Desc:
    "Multiple departments collaboratively manage assets on the platform with the highest-level MPC encryption technology for distributed management to eliminate potential risks",
  solutionsT5ST2Title: "Efficient and Convenient",
  solutionsT5ST2Desc:
    "More than 100 mainstream chain nodes are accessed to enable a series of convenient trading including asset custody and cross-exchange asset allocation",
  solutionsT5ST3Title: "Risk Control Assurance",
  solutionsT5ST3Desc:
    "It provides solid risk control services, selects reliable compliance providers, establishes a comprehensive risk control and compliance system, and satisfies the compliance standards in multiple regions across the world",
  solutionsT6: "Web 3.0 Solutions",
  solutionsT6ST1Title: "Web 3.0 Technology",
  solutionsT6ST1Desc:
    "It is one of the most stable and secure web3.0 service providers in the industry with comprehensive top blockchain technologies",
  solutionsT6ST2Title: "Web 3.0 Products",
  solutionsT6ST2Desc:
    "A exemplary web3.0 product is created on the sound NFT platform and mature cloud service",
  solutionsT6ST3Title: "Web 3.0 Member",
  solutionsT6ST3Desc:
    "A consulting team and technician team composed of dozens of experts in the industry, providing  insights on key industry trends",
  solutionsT7: "Security Solutions",
  solutionsT7ST1Title: "Website Security Audit",
  solutionsT7ST1Desc:
    "Professional team in TinyTrader will review the codes and environment of your website to identify the risk",
  solutionsT7ST2Title: "Digital Wallet Security Audit",
  solutionsT7ST2Desc:
    "Following our recommendations in the audit report, you will know how to remediate vulnerability of your digital wallet App",
  solutionsT7ST3Title: "Smart Contracts Security Audit",
  solutionsT7ST3Desc:
    "Verify your smart contracts to avoid security issues before deploy then on blockchain",
  solutionsT8: "Operational Consulting Services",
  solutionsT8ST1Title: "Online Operation Support",
  solutionsT8ST1Desc:
    "We provide support for your online launch and devise strategies to assist in the rapid commencement of operational activities",
  solutionsT8ST2Title: "Long-term Operation Support",
  solutionsT8ST2Desc:
    "We offer long-term operational plans to help achieve comprehensive and effective operations, reaching predefined objectives",
  solutionsT8ST3Title: "Customized Solution Support",
  solutionsT8ST3Desc:
    "We provide customized communication plans for significant events, aiding in the accomplishment of specific project goals",
  support: "Service Support",
  supportSubTitle:
    "TinyTrader provides concierge-like services for all users. All requests can be responded to and tracked instantly to satisfy vendors",
  supportT: "Our services",
  supportT1: "Liquidity",
  supportT1Desc:
    "Effectively solve the pain point - lack of liquidity caused by fewer users in the early stage",
  supportT2: "One-on-one Technical Consultant",
  supportT2Desc:
    "Professional one-on-one consultation to solve your questions about technology development at any time",
  supportT3: "Business Operation Service",
  supportT3Desc:
    "Specialized and customized value-added operation services for specific businesses",
  supportT4: "Product Operation Training",
  supportT4Desc:
    "Systematic training on industry ecosystem and product operation to improve team awareness",
  supportT5: "Related Service",
  supportT5Desc:
    "Provide ideas and plans for related products according to the nature of the brand and market demand",
  supportT6: "Regular System Maintenance and Upgrading",
  supportT6Desc:
    "Support maintenance and related services for product iteration and R&D function",
  supportT7: "API interface",
  supportT7Desc:
    "Provide interface connection or code optimization during the outside collection of various types and deep trading match-making",
  supportPowerful: "Powerful Service",
  supportPowerfulT1Title: "Experienced Technology Service",
  supportPowerfulT1Desc:
    "A stable system is critical to the HFT financial derivatives. The TinyTrader team leverages its extensive experience to ensure that trading products will still operate stably in extreme market conditions and rapid growth of instantaneous traffic without downtime.",
  supportPowerfulT2Title: "Quickly Build Products to Seize The Market",
  supportPowerfulT2Desc:
    "Loss of missing opportunities is far greater than the cost of development as the market changes rapidly. TinyTrader guarantees to quickly build products for users, and launch them immediately before keeping stable performance to seize every chance.",
  supportPowerfulT3Title: "Ultimate Integrity to Protect User Assets",
  supportPowerfulT3Desc:
    "The assets are stored through the separation of hot and cold wallets, multiple offline signatures, and other methods to ensure the security of users and assets. Through powerful DDos mitigation, it perfectly protects users from various types of DDos attacks by 100% mitigating SYN Flood, UDP Flood, ICMP Flood, and other attacks. With sufficient protective resources, the website never has downtime.",
  supportPowerfulT4Title:
    "Widen Users and Support Multi-dimensional Operations",
  supportPowerfulT4Desc:
    "TinyTrader allows multiple operation supports, such as user bonus, task center, and community interaction. TinyTrader provides users with extensive product supports to help user growth and market expansion.",
  supportOpening: "Opening Process",
  supportOpeningT1: "Know Your Needs",
  supportOpeningT2: "One-on-one Customized Exclusive Scheme",
  supportOpeningT3: "Sign The Contract",
  supportOpeningT4: "Collect Data",
  supportOpeningT5: "Online Deployment",
  supportOpeningT6: "Training",
  supportOpeningT7: "Acceptance by Customers",
  supportOpeningT8: "Online Operation Management",
  supportCompliance: "Compliance Services",
  supportComplianceT: "TinyTrader - Compliance Financial License",
  supportComplianceD1:
    "Headquartered in Singapore, TinyTrader is a full-stack service platform specializing in the R&D of new blockchain-based financial derivatives SAAS technology, and blockchain financial products.",
  supportComplianceD2:
    "TinyTrader has a team of more than 300 top technicians who have served tens of thousands of clients after three years of technology accumulation. The team members are all from internationally renowned Internet companies with years of experience in the development of financial products and blockchain applications.",
  supportComplianceD3:
    "TinyTrader is committed to providing blockchain professionals with comprehensive support for blockchain products and technical services, including building trading platforms, cloud services, development of new financial derivatives, development of blockchain applications, etc.",
  case: "Success Stories",
  caseSubTitle:
    "We offer technical support for many exchanges and become reliable partners of various brands",
  caseT1: "Our Clients",
  caseT2: "Top Success Stories",
  caseT2ST1Title: "Hyper",
  caseT2ST1Desc:
    "Hyper W is a cryptocurrency financial derivatives exchange officially registered in the Republic of Seychelles. The platform is committed to providing a technical architecture with stock exchange-level security and offering diversified financial derivatives trading services to millions of users. Hyper W pursues innovation, efficiency and security, providing high-quality and innovative products and services for global users, and aims to be a world-class cryptocurrency derivatives service provider. HyperW was initiated by a group of senior financial engineering experts. The team has many years of experience in the financial derivatives industry. The core members are from top Chinese universities such as Tsinghua University, Peking University, and Fudan University and include elites from such as Goldman Sachs, Baidu, Oracle, Tencent, and Alibaba. They aspire to build a new-generation decentralized derivatives trading platform.",
  caseT2ST2Title: "Ulink",
  caseT2ST2Desc:
    "Ulink is the world's top compliant professional blockchain derivatives trading platform, with headquarters in Singapore. Ulink sticks to the philosophy of Safety. Freedom. User, and provides trading services for more than .2 million users in Europe, America, Southeast Asia, Hong Kong, Singapore and other regions. Ulink sticks to the philosophy of Safety. Freedom. User, and provides trading services for more than .2 million users in Europe, America, Southeast Asia, Hong Kong, Singapore and other regions. Ulink sticks to the philosophy of Safety. Freedom. User, and provides trading services for more than .2 million users in Europe, America, Southeast Asia, Hong Kong, Singapore and other regions. Currently, it has launched independent businesses all over the world. In the future, it will spare no effort to do business based on Safety. Freedom. User. Ulink's goal is to create a complete global financial ecosystem of digital assets and build the global blockchain ecosystem.",
  caseT2ST3Title: "BWFX",
  caseT2ST3Desc:
    "BWFX (BWFX. site) is the world’s leading, compliant and professional blockchain derivatives trading platform headquartered in Singapore. Embracing the trading value of security, freedom and user first, BWFX offers trading services to more than 1.2 million users across Europe, the Americas, Southeast Asia, Hong Kong, Singapore, among others. BWFX has a team of blockchain experts from the world's largest 100 companies who combine top security technologies, and is committed to providing users with safe and convenient trading services.",
  caseT2ST4Title: "Biking",
  caseT2ST4Desc:
    "Biking, the world's leading blockchain digital asset contract trading platform, continues to provide digital currency transactions, blockchain education, blockchain project incubation, blockchain asset issuance platforms, and blockchain institutes, as well as blockchain charities to the world. At present, Biking has users across more than 30 countries and regions.Its core memory matching technology enables 1.4 million orders per second, which secures Biking a $ 20 million strategic investment (equivalent to 130 million yuan) from a Intabing which located at the capital of Singapore.It also obtain MSB licenses in the United States and Canada as a compliant overseas contract exchange. ",
  caseT2ST5Title: "Sunbit",
  caseT2ST5Desc:
    "Sunbit is a safe leading digital asset derivatives trading platform worldwide. It is licensed to compliantly operating digital currency trading platform and financial derivatives. Sunbit is developed and operated by international blockchain professionals who are  experienced in trading system, risk control, settlement and security management. Sunbit strives to provide safe, stable and convenient digital asset trading services for global users in the manner of compliance, professionalism and friendliness by putting user and service first. ",
  caseT2ST6Title: "HPX",
  caseT2ST6Desc:
    "HPX is a blockchain digital asset trading platform focusing on blockchain user experience. HPX software provides users with convenient crypto-to-fiat trading and a safe and comfortable trading environment. Users can conduct multi-currency tradings, digital asset trend inquiries, and online mining on HPX. It’s online professional consultants can reply to   questions from users, and the useful courses help users have more knowledge. ",
  caseT3: "About Us",
  more: "More",
  why: "Why Us",
  why1Title: "Top Security to Protect User Assets",
  why1Desc:
    "Store assets by  segregating hot wallets and cold wallets and through offline multi-signatures to ensure the security of users and assets. With powerful DDos cleaning capabilities, it perfectly defends against various attacks like SYN Flood, UDP Flood, and ICMP Flood, .saving the website free from shut down.",
  why2Title: "Innovative, Comprehensive and Compliant Solutions",
  why2Desc:
    "TinyTrader’s innovative comprehensive and compliant solutions solutions include digital assets exchange solutions, NFT trading platform solutions, wallet solutions, liquidity solutions, and asset custody solutions.",
  why3Title: "National-level Strategic Internet Technology Center",
  why3Desc:
    "Stability of the system is vital to high-frequency trading financial derivatives. With professional technology and rich experience, TinyTrader team prevent downtime during extreme market conditions and with surge in traffic.",
  why4Title: "Years of Industry Experience",
  why4Desc:
    "We offer businesses easy-to-integrate, scalable and secure blockchain solutions backed by our strong technical expertise and extensive experience.",
  why5Title: "Professional Strategy Assistance",
  why5Desc:
    "We select a professional third-party consulting team specialized in operations for clients in the Asia-Pacific region. We provide reliable and effective operational strategies, helping to address clients' operational deficiencies and enabling them to achieve predefined objectives quickly.",
  why6Title: "Abundant Resource Backing",
  why6Desc:
    "We integrate industry resources and adhere to an open service approach, offering clients the necessary resources during product operations, including channel resources, business partnerships, media resources, market-making resources, community resources, and capital resources.",
  about: "About us",
  about1:
    "Headquartered in Singapore, TinyTrader is a full-stack service platform specializing in the R&D of new blockchain-based financial derivatives SAAS technology, and blockchain financial products.",
  about2: "Team Members",
  about3: "Clients",
  about4: "Countries",
  about5: "Daily Trading Volume",
  about6: "$ 5 billion",
  ourPartners: "Our Partners",
  banner: "<div>Build Your</div> <div>Own</div> <b>Exchange</b>",
  bannerContent1: "7-day setup",
  bannerContent2: "Multi-Asset Liquidity",
  bannerContent3: "24/7 Support",
  home: "Home",
  products: "Products",
  news: "News",
  apply: "Apply For Trial",
  applyDesc: "Learn more about transaction and information that interests you",
  name: "Name",
  nameError: "Please input your name",
  email: "Email",
  emailError: "Please enter a valid email address",
  socialMedia: "Social Media",
  socialMediaError: "Please enter your account",
  message: "Message",
  submit: "Submit",
  seeMore: "See More",
  slogan: "Tiny Steps Towards The Future",
  coreProducts: "Core products",
  coreProductsDesc:
    "Our core products  covers all of the cryptocurrency core functions in the market, such as spot, derivatives, OTC,NFTs, strategy trading, asset value-added, payments, etc. We offer these across three different interfaces: desktop, PC, and mobile apps.",
  headerMenuT1: "Products",
  headerMenuT1ST1: "Digital Assets Exchange",
  headerMenuT1ST1D1:
    "Cover all of the cryptocurrency core functions in the market, such as spot, derivatives, OTC, NFTs, strategy trading, asset value-added, payments, etc",
  headerMenuT1ST1D2:
    "Integrate leading risk control strategies to ensure the security of exchanges",
  headerMenuT1ST1D3:
    "Provide four deployment solutions to meet your needs: SaaS(Public Cloud), Privatization (Hosting), SDK,Privatization",
  headerMenuT1ST1D4:
    "Offer these across three different interfaces: desktop, PC, and mobile apps",
  headerMenuT1ST1D5:
    "Support main chains and corresponding tokens of top three exchanges",
  headerMenuT1ST1D6: "Offer industry leading liquidity services",
  headerMenuT1ST1D7: "Matching Engine: powerful performance, high reliability",
  headerMenuT1ST2: "Operational Tools",
  headerMenuT1ST2D1:
    "Develop a variety of operational tools to support clients in increasing traffic and active users",
  headerMenuT1ST2D2:
    "Wealth Strategy Trading: support copy trading and grid trading",
  headerMenuT1ST2D3:
    "Deposit tokens to generate interest, improve user stickiness, and manage funds on the exchange",
  headerMenuT1ST2D4:
    "Acquire and retain new users through red packets, airdrops, invitation rewards, and demo trading",
  headerMenuT1ST3: "NFT Trading Platform",
  headerMenuT1ST3D1:
    "Increase the liquidity in the platform through NFT trading",
  headerMenuT1ST3D2: "Intelligent management tools and improved data insight",
  headerMenuT1ST3D3:
    "Support NFT trading, blind box trading and multi currency trading in multiple environments such as fixed price trading and auction",
  headerMenuT1ST3D4:
    "Store on IPFS, decentralized storage ensures data stability and reliability",
  headerMenuT1ST4: "API",
  headerMenuT1ST4D1:
    "We provide a wide range of API to help developers integrate the capabilities of digital exchanges into their software applications or online services",
  headerMenuT1ST4D2:
    "Our SaaS services, such as options, futures, and marketing activities, use the same API, so you don't have to learn different API for each service",
  headerMenuT1ST4D3:
    "For API specific to each service, they are accessible through the SDK provided for web, Android, and iOS applications",
  headerMenuT1ST4D4:
    "For service specific API (e.g. Order placing, Position Closing, Market Data retrieving and etc.) they are behind the SDK provided for Web, Android and iOS apps",
  headerMenuT1ST5: "Credit Card",
  headerMenuT1ST5D1: "Deposit Digital Currency,Enjoy Global Payment",
  headerMenuT1ST5D2:
    "Credit Card is issued by TinyTrader.It offers virtual card & physical card",
  headerMenuT1ST5D3: "Help the platform expand global customers",
  headerMenuT1ST5D4: "Support Visa, MasterCard, and UnionPay",
  headerMenuT1ST5D5:
    "Funds shall be entrusted by a compliant custodian service provider to ensure the safety of funds",
  headerMenuT1ST5D6:
    "Customized cover design, multi-style personalized display",
  headerMenuT1ST5D7:
    "Supported by 50 million merchants in over 176 countries worldwide",
  headerMenuT2: "Solutions",
  headerMenuT2ST1: "Wallet",
  headerMenuT2ST1D1:
    "Extreme safe, asset safety protection in all aspects from the environment to transaction data",
  headerMenuT2ST1D2: "One wallet to easily manage 50+ public chain assets",
  headerMenuT2ST1D3:
    "Investment options provided by professional financial institutions",
  headerMenuT2ST1D4:
    "Intelligent routing, automatically calculating the optimal price, slippage, and Gas, making transactions easier",
  headerMenuT2ST2: "Liquidity",
  headerMenuT2ST2D1:
    "Gain access to the deep liquidity pools of major exchanges",
  headerMenuT2ST2D2: "Support lower bid-ask spreads, and better bid-ask prices",
  headerMenuT2ST2D3: "Includes spot liquidity and derivatives liquidity",
  headerMenuT2ST2D4: "Liquidity tailored for all your business models",
  headerMenuT2ST3: "Asset Custody",
  headerMenuT2ST3D1:
    "Provide customized solutions to meet the bandwidth of your business’s digital assets, scale, and financial operations",
  headerMenuT2ST3D2:
    "Designated business wallet address on a real-time, daily, or fixed settlement basis based on your preference",
  headerMenuT2ST3D3: "Own personalized and user-friendly dashboard",
  headerMenuT2ST3D4: "Minimize Operational R&D Costs",
  headerMenuT2ST3D5: "Support 50+ blockchains and 160+ mainstream currencies",
  headerMenuT2ST4: "Web3 Infrastructure",
  headerMenuT2ST5: "Distributed Storage",
  headerMenuT2ST6: "Operational Consulting Services",
  headerMenuT2ST6D1:
    "Select a professional third-party consulting team specialized in operations for clients in the Asia-Pacific region, with members from renowned platforms such as Huobi, Binance, OKX, and others, who possess extensive experience in project operations",
  headerMenuT2ST6D2:
    "Assist clients in building a professional operational framework to achieve rapid online operations",
  headerMenuT2ST6D3:
    "Tailor operational, marketing, public relations, community, and event strategies based on each client's unique characteristics",
  headerMenuT2ST6D4:
    "Acilitate the integration and coordination of resources such as channels, media, communities, and business partnerships based on clients' specific needs",
  headerMenuT2ST6D5:
    "Offer comprehensive end-to-end operational consulting services, organizing specialized teams to provide one-on-one consulting for project operations and market promotion",
  headerMenuT2ST6D6:
    "Provide customized operational consulting services, including activity planning, event marketing, user growth, media exposure, and community development",
  headerMenuT3: "About",
  headerMenuT3ST1: "Service Support",
  headerMenuT3ST2: "Success Stories",
  PhoneNumber: "PHONE NUMBER",
  EMailAddress: "EMAIL ADDRESS",
  LearnMore: "Learn More",
  navigation: "Navigation",
  contact_your:
    "Your application has been received, CSR will contact you later!",
  newSolutions: "Digital Assets Exchange Solutions",
  newSolutionsSubTitle:
    "TinyTrader provides a one-stop SaaS solution with all the tools you need to launch and grow your crypto exchange.",
  newSolutionsT1: "Core Trading Platfrom",
  newSolutionsT1ST1Title: "Futures Trading System",
  newSolutionsT1ST2Title: "Option Trading System",
  newSolutionsT1ST3Title: "Spot Trading System",
  newSolutionsT1ST4Title: "OTC Trading System",
  newSolutionsT1ST5Title: "NFT Trading System",
  newSolutionsT1ST6Title: "Bonus Trading System",
  newSolutionsT1ST7Title: "Futures Grid Bot",
  newSolutionsT2: "Matching Core",
  newSolutionsT2ST1Title: "Execution capacity of 70 000 TPS",
  newSolutionsT2ST2Title: "Max trade execution time: 30 ms",
  newSolutionsT2ST3Title:
    "Ability to execute all types of orders (Market, Limit, Trigger, Fill or Kill, Immediate or Cancel)",
  newSolutionsT2ST4Title: "Isolated & Cross-Margin Trading Mode",
  newSolutionsT3: "Trading UI",
  newSolutionsT3ST1Title: "Perpetual Futures, Options, and CFD Trading Modes",
  newSolutionsT3ST2Title: "Intuitive, informative TradingView charts",
  newSolutionsT3ST3Title: "Order History, Balance & Account Summary",
  newSolutionsT3ST4Title: "Futures P&L Calculator",
  newSolutionsT3ST5Title: "Multi-platform OS-agnostic development",
  newSolutionsT4: "Back-Office",
  newSolutionsT4ST1Title: "Partnerships Management",
  newSolutionsT4ST2Title: "Auto & Manual KYC Procedures",
  newSolutionsT4ST3Title: "Copy-Trading Management",
  newSolutionsT4ST4Title: "Multilingual Support",
  newSolutionsT4ST5Title: "Trading Commissions Settings",
  newSolutionsT4ST6Title: "Trade History Analysis",
  newSolutionsT4ST7Title: "Documents Storage",
  newSolutionsT5: "Operational Tools",
  newSolutionsT5ST1: "Futures Gird Bot",
  newSolutionsT5ST2: "Earn",
  newSolutionsT5ST2Title:
    "Tinytrader is an asset management platform, which raises the value of crypto assets. We provide competitive annual yield, high incomes, and first-class security. ",
  newSolutionsT5ST2T1: "Accessible",
  newSolutionsT5ST2D1:
    "zero cost, and stably increasing income as soon as you deposit",
  newSolutionsT5ST2T2: "First-class security",
  newSolutionsT5ST2D2:
    "Isolated hot and cold wallets, multiple security audits",
  newSolutionsT5ST2T3: "High-yielding portfolio",
  newSolutionsT5ST2D3:
    "from short to long cycle, flexible quota for various money-earning needs, and 30+ idle digital assets to earn income",
  newSolutionsT5ST3: "Copy Trading",
  newSolutionsT5ST3Title1: "Top traders",
  newSolutionsT5ST3Desc11:
    "KOLs share trading policies to attract more copied traders to survive bull and bear",
  newSolutionsT5ST3Desc12: "Set up copy trading group to share profits",
  newSolutionsT5ST3Title2: "Copy trading user",
  newSolutionsT5ST3Desc2: "Copy the trading in one click to make money",
  newSolutionsT5ST4: "Demo Trading",
  newSolutionsT5ST4T1: "Accessible",
  newSolutionsT5ST4D1:
    "Massive trading know-how and tutorials to know trading market and learn trading skills",
  newSolutionsT5ST4T2: "Risk-averse",
  newSolutionsT5ST4D2:
    "Current users practice ad try trading strategies to find the most suitable one",
  newSolutionsT5ST4T3: "Free bonus",
  newSolutionsT5ST4D3: "Free bonus to get unlimited incomes",
  newSolutionsT5ST4T4: "Withdrawable",
  newSolutionsT5ST4D4:
    "Users learn trading skills in losses, and withdraw excessive profits",
  newSolutionsT5ST5: "Marketing Components",
  newSolutionsT5ST5Text1:
    "Rewards for the task can form user habits and increase user activity and retention with tasks",
  newSolutionsT5ST5Text2:
    "Users who invite new users to register and trade can get rebates and rewards from the exchange",
  newSolutionsT5ST5Text3:
    "Send red envelopes to help promote the exchange and attract users",
  newSolutionsT5ST5Text4: "Trading Competition gives you uplift",
  newSolutionsT7: "System Architecture",
  newSolutionsT8: "Comparison of Deployment Packages",
  newSolutionsT8ST1Title: "SaaS (Public Cloud)",
  newSolutionsT8ST1Desc1: "Have the market",
  newSolutionsT8ST1Desc2: "Have operational capability",
  newSolutionsT8ST1Desc3: "Save of technicians",
  newSolutionsT8ST2Title: "Privatization (Hosting)",
  newSolutionsT8ST2Desc1: "Have the market",
  newSolutionsT8ST2Desc2: "Have operational capability",
  newSolutionsT8ST2Desc3: "Have technical staff",
  newSolutionsT8ST2Desc4:
    "Want to deploy independently but want to save cost and do not require source code",
  newSolutionsT8ST3Title: "SDK",
  newSolutionsT8ST3Desc1: "Have the market",
  newSolutionsT8ST3Desc2: "Have operational capability",
  newSolutionsT8ST3Desc3: "Have technical staff",
  newSolutionsT8ST3Desc4:
    "Want to integrate the capabilities of digital exchanges into your own products and services",
  newSolutionsT8ST4Title: "Privatization",
  newSolutionsT8ST4Desc1: "Want to fully master the trading system",
  newSolutionsT8ST4Desc2:
    "Wish to customize the system to the maximum extent according to own operational ideas",
  newSolutionsT8ST4Desc3: "Want to serve designated/special countries",
  newSolutionsT8ST4Desc4: "Have Risk Security Technical Support",

  // 新版首页
  headerApply: "Book A Demo",
  tabTech: "Tech",
  tabLiquidity: "Liquidity",
  liquidityMenuTitle1: "TT Hub",
  tabContact: "Contact Us",
  tinyTrader: "Tinytrader",
  techMenuTitle1: "Digital Asset Exchange",
  techMenuDesc1: "Full range trading platform solutions",
  techMenuTitle2: "TT Crypto Card",
  techMenuDesc2: "Digital banking card solution",
  techMenuTitle3: "Open API",
  techMenuDesc3: "Integration to existing platform solution",
  techMenuTitle4: "Proprietary Trading",
  techMenuDesc4: "Trading with firm capital solution",
  techMenuChildrenTitle1: "TT0: Spot",
  techMenuChildrenDesc1: "Spot Trading System",
  techMenuChildrenTitle2: "TT1: Futures",
  techMenuChildrenDesc2: "Futures Trading System",
  techMenuChildrenTitle3: "TT2: Options",
  techMenuChildrenDesc3: "Options Trading System",
  tabAbout: "About",
  aboutMenuTitle: "About Us",
  aboutMenuDesc:
    "We're revolutionizing 21st-century trading platforms by eradicating barriers, enhancing user experience, and pioneering seamless trading and liquidity solutions.",
  aboutMenuChildrenTitle1: "Company Overview",
  aboutMenuChildrenTitle2: "Team",
  aboutMenuChildrenTitle3: "Careers",
  tabResources: "Resources",
  resourcesMenuDesc:
    "Designed a comprehensive resources center for today's institutional businesses, offering timely news updates, insightful education, and handpicked marketplace partners. Elevate your trading infrastructure with us.",
  resourcesChildrenTitle1: "TT Marketplace",
  resourcesChildrenTitle2: "News",
  resourcesChildrenTitle3: "Education",
  resourcesChildrenTitle4: "Events",

  bannerTitle1: "Advanced White-Label",
  bannerTitle2: "Trading Technology & Liquidity",
  bannerTitle3: "Solutions Provider",
  bannerTitle4: "Tiny Steps to Trade in the Future",
  ttLearnMore: "Learn More",
  bannerNumber1: "50+",
  bannerNumberDesc1: "Institutional Clients",
  bannerNumber2: "100+",
  bannerNumberDesc2: "Employees",
  bannerNumber3: "10+",
  bannerNumberDesc3: "Countries",
  bannerNumber4: "$5B+",
  bannerNumberDesc4: "Volume",

  footerCopyright1: "TinyTrader. All Rights Reserved.",
  footerCopyright2:
    "Trademarks In Singapore (Official Registration Numbers To Be Confirmed). TinyTrader Represents A Formalized Joint Venture",
  footerCopyright3:
    "Established Through Contractual Alliances Among Distinct Legal Entities And Esteemed Individuals Within Singapore",
  footerSlogan:
    "TinyTrader (TT) Is an advanced white-label trading technology and liquidity solutions provider that connects digital-asset trading between fiat and digital currencies for global trading platforms, brokers, developers, and institutions.",
  footerProducts: "Products",
  footerProducts1: "Digital Asset",
  footerProducts2: "TT Spot",
  footerProducts3: "TT Futures ",
  footerProducts4: "TT Options",
  footerProducts8: "Prop Trading",
  footerProducts5: "Crypto Card",
  footerProducts6: "Open API ",
  footerProducts7: "TT Marketplace",
  footerLearn: "Learn",
  footerLearn1: "News",
  footerLearn2: "Event",
  footerLearn3: "About",
  footerLearn4: "Carrers",
  footerLegal: "Legal",
  footerLegal1: "Terms of Services",
  footerLegal2: "Privacy Policy",
  footerLegal3: "Cookies Policy",
  footerSupport: "Support",
  footerSupport1: "Contact Us",
  footerSupport2: "FAQ",
  footerSupport3: "Prop Trading App Download",
  emailTitle: "Stay Connected!",
  emailDesc: "Get Our Newsletter",
  emailBtn: "Enter your Email",

  whoTitle: "Who We Are",
  whoDesc:
    "We are committed to empowering every client, from traditional finance to emerging fintech, with our cutting-edge trading technologies and liquidity solutions",

  clientTitle: "Open to Every Type of a Client",
  clientTitle1:
    "We are committed to empowering every client, from traditional finance to emerging fintech, with our cutting-edge trading technologies and liquidity solutions",
  clientTitle2: "From Traditional Finance To Emerging Fintech,",
  clientTitle3: "With Our Cutting-Edge Cerivatives And Liquidity Solutions",
  clientListTitle1: "TradFi Companies",
  clientListDesc1:
    "We provides entities such as FOREX, CFD firms and brokerages with seamless integration to the digital asset market, establishing an additional revenue stream, whilst circumventing the necessity of building a proprietary platform from the ground up.",
  clientListTitle2: "Financial Technologies ",
  clientListDesc2:
    "Our offering for wallets, custodians, and digital trading venues provide opportunity to deliver a secure, scalable, and customizable platform that amplifies your service offerings and equips you with a competitive edge trading technologies landscape.",
  clientListTitle3: "Crypto Projects",
  clientListDesc3:
    "We provides a robust, intuitive trading infrastructure, ideal for tokens and crypto project owners. This invariably enhances the visibility of your project and ensures optimal market market.",

  solutionsTitle: "Our Solutions",
  solutionsTitle1:
    "We provide a complete solutions suite covering infrastructure development and ecosystem support for forward-looking businesses exploring new revenue streams",
  solutionsTitle2: "Ecosystem Support For Forward-Looking Businesses Exploring",

  solutionsMenuTitle1: "White Label Crypto Exchange",
  solutionsMenuList1Item1:
    "Execution in matching engine with high speed, efficiency and utmost reliability",
  solutionsMenuList1Item2:
    "Provided with the full-range digital asset trading platform functionality, including spot trading, derivatives (futures and options), prop trading, P2P, including ecosystem services",
  solutionsMenuList1Item3:
    "Integrated risk management solutions to guarantee exchange security",
  solutionsMenuList1Item4:
    "Delivered deployment solutions: SaaS (Public Cloud), SDK and API integrations",
  solutionsMenuList1Item5:
    "Availability across platforms: Desktop, PC, and mobile applications",
  solutionsMenuList1Item6:
    "Provided aggregated liquidity services on the white label crypto exchange platform",

  solutionsMenuTitle2: "Operational Tools",
  solutionsMenuList2Item1:
    "Cover all of the cryptocurrency core functions in the market, such as spot, derivatives, OTC, NFTs, strategy trading, asset value-added, payments, etc",
  solutionsMenuList2Item2:
    "Integrate leading risk control strategies to ensure the security of exchanges",
  solutionsMenuList2Item3:
    "Provide four deployment solutions to meet your needs: SaaS (Public Cloud), Privatization (Hosting), SDK, Privatization",
  solutionsMenuList2Item4:
    "Offer these across three different interfaces: desktop, PC, and mobile apps",
  solutionsMenuList2Item5:
    "Support main chains and corresponding tokens of top three exchanges",
  solutionsMenuList2Item6: "Offer industry leading liquidity services",
  solutionsMenuList2Item7:
    "Matching Engine: powerful performance, high reliability",

  solutionsMenuTitle3: "API",
  solutionsMenuList3Item1:
    "Comprehensive API support enhancing exchange infrastructure integration",
  solutionsMenuList3Item2:
    "Seamless WebSocket and RestAPI integration for real-time data exchange and operations",
  solutionsMenuList3Item3:
    "Program API provision, enabling software development grounded in our systems",
  solutionsMenuList3Item4:
    "Open architecture, promoting adaptability and customization for diverse applications",
  solutionsMenuList3Item5:
    "Empower developers with detailed documentation and support, ensuring efficient system utilization",
  solutionsMenuList3Item6:
    "Prioritize security and efficiency, ensuring rapid data transfers with utmost integrity",
  solutionsMenuList3Item7:
    "Foster innovation, supporting third-party extensions and collaborative integrations",

  solutionsMenuTitle4: "Crypto Card",
  solutionsMenuList4Item1:
    "Secured digital banking integration service powered by white-label integration",
  solutionsMenuList4Item2:
    "Secure issuance of digital assets payment cards, ensuring user trust and reliability",
  solutionsMenuList4Item3:
    "Seamless integration with the exchange, facilitating instantaneous digital assets transactions",
  solutionsMenuList4Item4:
    "Equip users with the ability to transact both online and offline worldwide",
  solutionsMenuList4Item5:
    "Maintain the highest standards of security, ensuring asset protection and transactional integrity",
  solutionsMenuList4Item6:
    "Simplify user experiences through offering of instant virtual card provisioning, eliminating lengthy forms",
  solutionsMenuList4Item7:
    "Flexibility in fund management: Provide seamless options for users to convert back to USDT",

  solutionsMenuTitle5: "Liquidity",
  solutionsMenuList5Item1:
    "Internal organic liquidity pool for consistent market depth",
  solutionsMenuList5Item2:
    "Seamless liquidity bridging with top-tier crypto exchanges",
  solutionsMenuList5Item3: "Advanced Market Making (MM) algorithms",
  solutionsMenuList5Item4:
    "RestAPI and WebSocket API across markets external MM connectivity",

  liquidityTitle: "Liquidity Solutions",
  liquidityDesc1:
    "We offer a variety of liquidity solutions, including Remarketer automated market",
  liquidityDesc2:
    "making software and solutions that provide spot liquidity, derivatives liquidity ",
  liquidityDesc3: "and leveraged ETF liquidity.",
  liquidityBtn1: "Internal Liquidity Pool",
  liquidityBtn2: "Liquidity Bridges",
  liquidityBtn3: "External API Connectivity",

  marketplaceTitle: "TT Marketplace",
  marketplaceDesc1:
    "Ideal venue for businesses seeking marketing collaborations, co-marketing activities, integrations, and revenue-sharing opportunities. With a diverse community of companies, we offer an open and dynamic environment for strategic partnerships. By harnessing the power of synergistic alliances, businesses can expand their reach and access TT’s extensive customer base, which continues to grow exponentially.",
  marketplaceDesc2:
    "By Harnessing The Power Of Synergistic Alliances, Businesses Can Expand Their Reach And Access TT’s Extensive Customer Base, Which Continues To Grow Exponentially.",

  awardsTitle: "Awards & Licensing",
  awardsItem1Title: "ISO 27001:2022",
  awardsItem1Desc: "Information security, cybersecurity and privacy protection",
  awardsItem2Title: "DMCC DLT License",
  awardsItem2Desc:
    "Authority for blockchain and cryptocurrency businesses in Dubai, UAE",
  awardsItem3Title: "RAK DAO Digital-Assets Oasis License",
  awardsItem3Desc: "Digital asset businesses in Ras Al Khaimah, UAE",
  awardsItem4Title: "Best Blockchain Data Solution 2023",
  awardsItem4Desc:
    "Awarded as best Blockchain Solution Provider of the year by Sigma",
  awardsItem5Title: "Best Liquidity Provider 2023",
  awardsItem5Desc:
    "Awarded as the best Liquidity Provider of the year by CryptoExpo",
  awardsItem6Title: "Best White-Label Solution Provider 2023",
  awardsItem6Desc: "Awarded as White-Label Solution Provider by WikiExpo",

  partnersTitle: "Our Partners",
  partnersDesc: "Don’t take our word for it. Trust our customers",
  partnersItem1Title:
    "CoinW is globally recognized as a leading exchange platform, characterized by its advanced trading capabilities. It offers trading in different markets,",
  partnersItem1Desc:
    "a comprehensive affiliate  system, and some of the industry's highest rebate bonus structures.",
  partnersItem2Title:
    "OKX is a leading cryptocurrency exchange renowned for its",
  partnersItem2Desc:
    "advanced technology, offering users a platform to trade, invest, and manage their digital assets securely and efficiently.",
  partnersItem3Title:
    "Alchemy Pay is globally acknowledged as a forefront payment gateway, characterized by its cutting-edge transaction solutions. It provides seamless crypto-to-fiat conversions, intricate payment protocol integrations,",
  partnersItem3Desc: "",
  partnersItem4Title:
    "Jumio is universally esteemed as a pinnacle in digital identity verification, characterized by its state-of-the-art authentication mechanisms. It offers advanced biometric scanning, intricate document verification processes,",
  partnersItem4Desc: "",
  partnersItem5Title:
    "AWS (Amazon Web Services) is universally recognized as a paramount entity in cloud computing, epitomized by its pioneering infrastructure services.",
  partnersItem5Desc: "",
  partnersItem6Title:
    "CoinMarketCap is the world's most-referenced price-tracking website for cryptoassets in the rapidly growing cryptocurrency space.",
  partnersItem6Desc: "",

  noFoundTitle: "Oops it seems you follow backlink",
  noFoundBtn: "Go To Home",

  comingTitle: "We’re Coming Soon..",
  comingDesc:
    "Lorem ipsum dolor sit amet consectetur. Vel sit facilisi mauris vitae aliquam velit molestie imperdiet eget. In sociis gravida posuere arcu egestas. ",
  comingItem1: "360",
  comingItem1Unit: "DAYS",
  comingItem2: "24",
  comingItem2Unit: "HOURS",
  comingItem3: "60",
  comingItem3Unit: "MUNUTES",
  comingItem4: "60",
  comingItem4Unit: "SECONDS",
  cookieTitle:
    "We use cookies to personalize and improve your experience on our website. By clicking “Accept” you consent to our",
  cookiePolicy: "Cookies Policy.",
  cookieDecline: "Decline",
  cookieAccept: "Accept",
  hyp_tiny_saas_download_Platforms:
    "Any time, anywhere. Trading made easy over multiple platforms",
  hyp_tiny_saas_download_Available:
    "Available on iOS, Android, Windows, and Mac. Offering you full-fledged functions, on the go",
  hyp_tiny_saas_download_Mobile: "Mobile",
  hyp_tiny_saas_download_Desktop: "Desktop",
  hyp_tiny_saas_download_DownloadText_Android: "Android Download",
  hyp_tiny_saas_download_DownloadText_iOS: "iOS Download",
};

export default en;
