import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "lib-flexible";
import "./utils/i18n";
import loadable from "@loadable/component";
import reportWebVitals from "./reportWebVitals";
import "@/assets/styles/index.scss";

// const Home = loadable(() => import("./home"));
// const HomeNewSolutions = loadable(() => import("./home/NewSolutions"));
// const HomeSolutions = loadable(() => import("./home/Solutions"));
// const HomeSupport = loadable(() => import("./home/Support"));
// const HomeCase = loadable(() => import("./home/Case"));
// // const Home404 = loadable(() => import("./home/404"));
// const HomeNews = loadable(() => import("./home/News"));
// const NewsDetail20230111 = loadable(() => import("./home/NewsDetail20230111"));
// const NewsDetail20230401 = loadable(() => import("./home/NewsDetail20230401"));
// const NewsDetail20230505 = loadable(() => import("./home/NewsDetail20230505"));
// const NewsDetail20230619 = loadable(() => import("./home/NewsDetail20230619"));
// const NewsDetail20230815 = loadable(() => import("./home/NewsDetail20230815"));
const BookDemoV2 = loadable(() => import("./bookDemoV2"));
const BookDemoV2Thankyou = loadable(() => import("./bookDemoV2/Thankyou"));
// const Privacy = loadable(() => import("./privacy"));

// TT 英文版
const TTHome = loadable(() => import("./pages/index"));
const Tech = loadable(() => import("./pages/Tech/Index"));
const Spot = loadable(() => import("./pages/Tech/Spot"));
const PropTrader = loadable(() => import("./pages/Tech/PropTrader"));
const Features = loadable(() => import("./pages/Tech/Features"));
const Options = loadable(() => import("./pages/Tech/Options"));
const Card = loadable(() => import("./pages/Tech/Card"));
const Crypto = loadable(() => import("./pages/Tech/Crypto"));
const About = loadable(() => import("./pages/About/Index"));
const Team = loadable(() => import("./pages/About/Team"));
const Careers = loadable(() => import("./pages/About/Careers"));
const Job = loadable(() => import("./pages/About/Job"));
const TNews = loadable(() => import("./pages/Resource/News"));
const NewsDetail = loadable(() => import("./pages/Resource/NewsDetail"));
const Events = loadable(() => import("./pages/Resource/Events"));
const EventsDetail = loadable(() => import("./pages/Resource/EventsDetail"));
// const Education = loadable(() => import("./pages/Resource/Education"));
const Publications = loadable(() => import("./pages/Resource/Publications"));
const Marketplace = loadable(() => import("./pages/Resource/Marketplace"));
const Liquidity = loadable(() => import("./pages/Liquidity"));
const Faq = loadable(() => import("./pages/Faq"));
const Legal = loadable(() => import("./pages/Legal"));
const Terms = loadable(() => import("./pages/Terms"));
const Privacy = loadable(() => import("./pages/Privacy"));
const CookiesPolicy = loadable(() => import("./pages/CookiesPolicy"));
const Contact = loadable(() => import("./pages/Contact"));
const TT404 = loadable(() => import("./pages/404"));
const Coming = loadable(() => import("./pages/Coming"));
const FormResult = loadable(() => import("./pages/FormResult"));


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/home" element={<TTHome />} />
        <Route path="/newsolutions/" element={<TTHome />} />
        <Route path="/solutions/" element={<TTHome />} />
        <Route path="/support/" element={<TTHome />} />
        <Route path="/case/" element={<TTHome />} />
        <Route path="/news/" element={<TTHome />} />
        <Route path="/news/20230111" element={<TTHome />} />
        <Route path="/news/20230401" element={<TTHome />} />
        <Route path="/news/20230505" element={<TTHome />} />
        <Route path="/news/20230619" element={<TTHome />} />
        <Route path="/news/20230815" element={<TTHome />} />
        {/* <Route path="privacy" element={<Privacy />} /> */}
        <Route path="/lp/book-demo" element={<BookDemoV2 />} />
        <Route path="/lp/book-demo/thankyou" element={<BookDemoV2Thankyou />} />
        <Route path="/book-demo-l" element={<BookDemoV2 />} />
        <Route path="/book-demo-l/thankyou" element={<BookDemoV2Thankyou />} />

        {/* <Route path="/404" element={<Home404 />} /> */}
        {/* <Route path="*" render={() => <Navigate to="/404" />} /> */}
        {/* <Route path="*" element={<Home404 />} /> */}
        {/* TT 英文版 */}
        <Route path="/" element={<TTHome />} />
        <Route path="/tech/" element={<Tech />} />
        <Route path="/spot/" element={<Spot />} />
        <Route path="/proptrader/" element={<PropTrader />} />
        <Route path="/futures/" element={<Features />} />
        <Route path="/options/" element={<Options />} />
        <Route path="/card/" element={<Card />} />
        <Route path="/white-label-crypto-exchange" element={<Crypto />} />
        <Route
          path="/crypto"
          element={<Navigate to="/white-label-crypto-exchange" />}
        ></Route>
        <Route path="/about/" element={<About />} />
        <Route path="/team/" element={<Team />} />
        <Route path="/careers/" element={<Careers />} />
        <Route path="/job/:id" action={({ params }) => {}} element={<Job />} />
        <Route path="/tnews/" element={<TNews />} />
        <Route
          path="/tnews/:id"
          action={({ params }) => {}}
          element={<NewsDetail />}
        />
        <Route path="/events/" element={<Events />} />
        <Route
          path="/events/:id"
          action={({ params }) => {}}
          element={<EventsDetail />}
        />
        {/* <Route path="/education/" element={<Education />} /> */}
        <Route
          path="/publications/:id"
          action={({ params }) => {}}
          element={<Publications />}
        />
        <Route path="/marketplace" element={<Marketplace />} />
        <Route path="/liquidity" element={<Liquidity />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/legal" element={<Legal />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/cookies" element={<CookiesPolicy />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/coming" element={<Coming />} />
        <Route path="/formresult" element={<FormResult />} />
        <Route path="/formsubmittedcrypto" element={<FormResult />} />
        <Route path="/formsubmittedprop" element={<FormResult />} />
        <Route path="/formsubmittedforex" element={<FormResult />} />
        <Route path="/forex" element={<TTHome />} />
        <Route path="*" element={<TT404 />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
