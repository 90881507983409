import home from "./home-ko";
import landing from "./landing-ko";
import news from "./news-ko";
import tech from "./tech-ko";
import contact from "./contact-ko";
import liquidity from "./liquidity-ko";
import about from "./about-ko";
const ko_KR = {
  translation: {
    contact_your: "지원서가 접수되었습니다. CSR에서 나중에 연락드리겠습니다.",
    email: "이메일",
    account1: "Telegram",
    account2: "Wechat",
    account3: "Facebook",
    account4: "Linked In",
    account5: "Medium",
    account6: "Whatsup",
    landing_page_email_error: "Please enter a valid email address",
    landing_page_phone_error: "Please enter a valid phone number",
    landing_page_name_warning: "Please input your name",
    landing_page_countrys: {
      china: "China",
      Bhutan: "Bhutan",
      "TimorLeste(East Timor)": "Timor-Leste(East Timor)",
      Taiwan: "Taiwan",
      Macau: "Macau",
      "Hong Kong": "Hong Kong",
      "Central African Republic": "Central African Republic",
      Denmark: "Denmark",
      Ukraine: "Ukraine",
      Uzbekistan: "Uzbekistan",
      Uganda: "Uganda",
      Uruguay: "Uruguay",
      Chad: "Chad",
      Yemen: "Yemen",
      Armenia: "Armenia",
      Israel: "Israel",
      Iraq: "Iraq",
      Iran: "Iran",
      Belize: "Belize",
      "Cape Verde": "Cape Verde",
      Russia: "Russia",
      Bulgaria: "Bulgaria",
      Croatia: "Croatia",
      Gambia: "Gambia",
      Iceland: "Iceland",
      Guinea: "Guinea",
      GuineaBissau: "Guinea - Bissau",
      Liechtenstein: "Liechtenstein",
      CongoRepublic: "Congo, Republic",
      CongoDemocraticRepublic: "Congo, Democratic Republic",
      Libya: "Libya",
      Liberia: "Liberia",
      Canada: "Canada",
      Ghana: "Ghana",
      Gabon: "Gabon",
      Hungary: "Hungary",
      "South Africa": "South Africa",
      Botswana: "Botswana",
      Qatar: "Qatar",
      Rwanda: "Rwanda",
      Luxembourg: "Luxembourg",
      Indonesia: "Indonesia",
      India: "India",
      Guatemala: "Guatemala",
      Ecuador: "Ecuador",
      Eritrea: "Eritrea",
      Syria: "Syria",
      Cuba: "Cuba",
      Kyrgyzstan: "Kyrgyzstan",
      Djibouti: "Djibouti",
      Kazakhstan: "Kazakhstan",
      Colombia: "Colombia",
      "Costa Rica": "Costa Rica",
      Cameroon: "Cameroon",
      Tuvalu: "Tuvalu",
      Turkmenistan: "Turkmenistan",
      Turkey: "Turkey",
      "Saint Lucia": "Saint Lucia",
      "Saint Kitts and Nevis": "Saint Kitts and Nevis",
      "Sao Tome and Principe": "Sao Tome and Principe",
      "Saint Vincent and the Grenadines": "Saint Vincent and the Grenadines",
      "San Marino": "San Marino",
      Guyana: "Guyana",
      Tanzania: "Tanzania",
      Egypt: "Egypt",
      Ethiopia: "Ethiopia",
      Kiribati: "Kiribati",
      Tajikistan: "Tajikistan",
      Senegal: "Senegal",
      Serbia: "Serbia",
      "Sierra Leone": "Sierra Leone",
      Cyprus: "Cyprus",
      Seychelles: "Seychelles",
      Mexico: "Mexico",
      Togo: "Togo",
      "Dominican Republic": "Dominican Republic",
      Dominica: "Dominica",
      Austria: "Austria",
      Venezuela: "Venezuela",
      Bangladesh: "Bangladesh",
      Angola: "Angola",
      Anguilla: "Anguilla",
      "Antigua and Barbuda": "Antigua and Barbuda",
      Andorra: "Andorra",
      Micronesia: "Micronesia",
      Nicaragua: "Nicaragua",
      Nigeria: "Nigeria",
      Niger: "Niger",
      Nepal: "Nepal",
      Bahamas: "Bahamas",
      Pakistan: "Pakistan",
      Barbados: "Barbados",
      "Papua New Guinea": "Papua New Guinea",
      Paraguay: "Paraguay",
      Panama: "Panama",
      Bahrain: "Bahrain",
      Brazil: "Brazil",
      "Burkina Faso": "Burkina Faso",
      Burundi: "Burundi",
      Greece: "Greece",
      Palau: "Palau",
      "Cayman Islands": "Cayman Islands",
      Germany: "Germany",
      Italy: "Italy",
      "Solomon Islands": "Solomon Islands",
      Latvia: "Latvia",
      Norway: "Norway",
      "Czech Republic": "Czech Republic",
      Moldova: "Moldova",
      Morocco: "Morocco",
      Monaco: "Monaco",
      Brunei: "Brunei",
      Fiji: "Fiji",
      Swaziland: "Swaziland",
      Slovakia: "Slovakia",
      Slovenia: "Slovenia",
      "Sri Lanka": "Sri Lanka",
      Singapore: "Singapore",
      "New Zealand": "New Zealand",
      Japan: "Japan",
      Chile: "Chile",
      KoreaNorth: "Korea, North",
      Cambodia: "Cambodia",
      Grenada: "Grenada",
      Georgia: "Georgia",
      "Vatican City": "Vatican City",
      Belgium: "Belgium",
      Mauritania: "Mauritania",
      Mauritius: "Mauritius",
      Tonga: "Tonga",
      "Saudi Arabia": "Saudi Arabia",
      France: "France",
      Poland: "Poland",
      "Bosnia and Herzegovina": "Bosnia and Herzegovina",
      Thailand: "Thailand",
      Zimbabwe: "Zimbabwe",
      Honduras: "Honduras",
      Haiti: "Haiti",
      Australia: "Australia",
      Ireland: "Ireland",
      Estonia: "Estonia",
      Jamaica: "Jamaica",
      "Trinidad and Tobago": "Trinidad and Tobago",
      Bolivia: "Bolivia",
      Nauru: "Nauru",
      Sweden: "Sweden",
      Switzerland: "Switzerland",
      Vanuatu: "Vanuatu",
      Belarus: "Belarus",
      Bermuda: "Bermuda",
      Kuwait: "Kuwait",
      Comoros: "Comoros",
      "Cote Ivoire": "Cote Ivoire",
      Kosovo: "Kosovo",
      Peru: "Peru",
      Tunisia: "Tunisia",
      Lithuania: "Lithuania",
      Somalia: "Somalia",
      Jordan: "Jordan",
      Namibia: "Namibia",
      Myanmar: "Myanmar",
      Romania: "Romania",
      "United States": "United States",
      USVirginIslands: "U.S.Virgin Islands",
      Laos: "Laos",
      Kenya: "Kenya",
      Finland: "Finland",
      Sudan: "Sudan",
      Suriname: "Suriname",
      "United Kingdom": "United Kingdom",
      "British Virgin Islands": "British Virgin Islands",
      Netherlands: "Netherlands",
      Mozambique: "Mozambique",
      Lesotho: "Lesotho",
      Philippines: "Philippines",
      "El Salvador": "El Salvador",
      Samoa: "Samoa",
      Portugal: "Portugal",
      Mongolia: "Mongolia",
      Spain: "Spain",
      Benin: "Benin",
      Zambia: "Zambia",
      "Equatorial Guinea": "Equatorial Guinea",
      Vietnam: "Vietnam",
      Azerbaijan: "Azerbaijan",
      Afghanistan: "Afghanistan",
      Algeria: "Algeria",
      Albania: "Albania",
      Oman: "Oman",
      Argentina: "Argentina",
      "United Arab Emirates": "United Arab Emirates",
      "South Korea": "South Korea",
      Macedonia: "Macedonia",
      Maldives: "Maldives",
      Malawi: "Malawi",
      Malaysia: "Malaysia",
      "Marshall Islands": "Marshall Islands",
      Malta: "Malta",
      Madagascar: "Madagascar",
      Mali: "Mali",
      Lebanon: "Lebanon",
      Montenegro: "Montenegro",
    },
    //Contact Us
    cotact_us: "Contact Us",
    cotact_us_title: "Book A Brief Consultation With Our Blockchain Experts",
    name: "Name",
    email: "Email",
    phone_number: "Phone Number",
    social_media: "Social Media",
    preferred_language: "Preferred Language",
    comments_city_timezone: "Comments / City / Timezone",
    cotact_us_remarks:
      "TinyTrader uses the provided information to contact you about our relevant product and services",
    request_callback: "REQUEST A CALLBACK",
    search: "search",
    "404-1": "5초 후에 TinyTrader 홈페이지로 이동합니다",
    "404-2": "홈페이지로 돌아가기",
  },
  home,
  landing,
  news,
  tech,
  contact,
  liquidity,
  about,
};

export default ko_KR;
