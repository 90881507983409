const en = {
  bannerTitle1: "Digital Assets Exchange Solutions",
  bannerTitle2: "",
  bannerDesc:
    "We offer a turnkey solution to establish your independent brand. Our modular delivery options allow for tailored configurations in just 1 month.",
  ttLearnMore: "Find More",
  traderLearnMore: "Learn More",
  solutionsTitle: "Our Solutions",
  solutionsTitle1: "Spot Trading",
  solutionsTitle2: "Futures Trading",
  solutionsTitle3: "Options Trading",
  solutionsTitle4: "Prop Trading",
  solutionsTitle5: "Crypto Card",
  solutionsTitle6: "API Connectivity",
  solutionsTitle7: "Liquidity",
  videoTitle: "Turnkey Digital Assets Exchange Solution",
  coreTitle: "Core Components",
  coreTitle1: "Matching Engine",
  coreTitleDesc1:
    "Our Transaction Matching Engine Executes Bid-ask Orders At A Capacity Of 70K TPS, With All The Security Benefits Of The Blockchain",
  coreTitle2: "Trading Platform",
  coreTitleDesc2:
    "TinyTrader's Default 'minimal UI' Can Be Endlessly Customized To Best Reflect Your Brand Across Multiple Platforms",
  coreTitle3: "Liquidity",
  coreTitleDesc3:
    "Our White-Label Solution Facilitates Liquidity Provision Algorithms To Achieve The Tightest Spreads In The Market",
  coreTitle4: "Operational Tools",
  coreTitleDesc4:
    "Advanced Back-End Office System Aimed To Manage Your Users, Affiliates, Documents And Cashflow.",
  marketingTitle: "Marketing Features",
  marketingTitle1: "Task Rewards",
  marketingDesc1:
    "Task Rewards Users To Cultivate Their Usage Habits And Increase Their Activity And Retention Rate By Completing Tasks",
  marketingTitle2: "Demo trading",
  marketingDesc2:
    "Guide New Users To Get Started Quickly, And Old Users To Buildtheir Own Trading Strategies To Avoid The Risk Of Real Transactions.",
  marketingTitle3: "Invitation rewards",
  marketingDesc3:
    "Existing Users Can Get Exchange Commission Rebates And Platform Rewards When They Invite New Users To Register And Trade.",
  marketingTitle4: "Copy trading",
  marketingDesc4:
    "At The Initial Stage, New Users Can Avoid A Lot Of Risks And Carry Out Documentary Transactions To Gain Profits.",
  marketingTitle5: "Affiliate Partnerships",
  marketingDesc5:
    "Help Exchanges Promote, Attract Users To Receive Red Packets, And Then Register For Trading",
  marketingTitle6: "Grid trading",
  marketingDesc6: "Grid Strategy Trading To Earn Spreads During Market Swings.",
  marketingTitle7: "Airdrop",
  marketingDesc7:
    "Cash Rewards That The Platform Gives Users To Improve User Experience And Retain Users.",
  marketingTitle8: "Earn",
  marketingDesc8:
    "Deposit Tokens To Generate Interest, Improve User Stickiness Andfund Scale Managed By The Sxchange",
  systemTitle: "System Architecture",
  comparisonTitle: "Comparison of Deployment Packages",
  comparisonMore: "Contact Us",
  comparisonTitle1: "SaaS (Public Cloud)",
  comparisonDesc1:
    "Utilize Our SDK To Embed Our Robust Features Into Yvvvour Existing Architecture. Benefit From Optimized Libraries, API Endpoints, And Comprehensive Documentation For A Streamlined Integration Process",
  comparisonTitle2: "Privatization",
  comparisonDesc2:
    "Leverage Our Cloud-based Solution Hosted On A Multi-Tenant Architecture. Benefit From Auto-Scaling, Automated Updates, And A Distributed Network For Enhanced Performance And Uptime",
  comparisonTitle3: "Privatization (Hosting)",
  comparisonDesc3:
    "Opt For A Dedicated Instance Of Our Platform. This Offers Isolated Server Environments, Customizable Data Pipelines, And Stricter Compliance Adherence Tailored For Stringent Organizational Requirements.",

  traderBannerTitle: "White Label Crypto Proprietary Trading",
  traderBannerDesc:
    "Create your own Prop Trading firm to facilitate a challenging program that allows users to earn higher profits by leveraging provided funds",
  traderFeaturesTitle1: "WordPress-based",
  traderFeaturesDesc1:
    "Flexibility in design, content management, and responsive performance",
  traderFeaturesTitle2: "Flexible Challenge Setup ",
  traderFeaturesDesc2: "Customize challenges with diverse stages and terms",
  traderFeaturesTitle3: "Full Ecosystem Support ",
  traderFeaturesDesc3:
    "Smooth trading, notifications, payouts, user management and client’s cabinet",
  traderFeaturesTitle4: "Trading System Integration",
  traderFeaturesDesc4:
    "Seamless integration with diverse solutions supported by comprehensive API",
  traderFeaturesTitle5: "Tailored-sized Capital",
  traderFeaturesDesc5:
    "Various capital levels for minimized risks, enhanced profits",
  traderFeaturesTitle6: "Flexible Payout System ",
  traderFeaturesDesc6:
    "Customizable compensation aligns with trading performance and preferences",
  traderFeaturesTitle7: "Risk-Management ",
  traderFeaturesDesc7:
    "Utilize stop-loss and risk assessment for controlled trading",
  traderFeaturesTitle8: "Back-office Flexibility",
  traderFeaturesDesc8:
    "Easily customize challenges, steps, and performance parameters",
  traderFeaturesTitle9: "Multi-Terminal Access",
  traderFeaturesDesc9: "Accessible on both mobile and desktop platforms",

  traderBenefitsTitle1: "Real-time Market Data",
  traderBenefitsDesc1:
    "Our solution seamlessly integrates real-time market data from tier-one exchanges, providing users with an up-to-the-second trading experience",
  traderBenefitsTitle2: "Multi-step Challenges",
  traderBenefitsDesc2:
    "Seamlessly transition from demo to live trading, allowing traders to test and refine strategies in a risk-free demo environment before committing to live trading",
  traderBenefitsTitle3: "Analytics and Reporting",
  traderBenefitsDesc3:
    "Track key performance indicators, analyze trading strategies, and generate detailed reports to evaluate and optimize trading activities effectively",
  traderBenefitsTitle4: "Trading Risk-management",
  traderBenefitsDesc4:
    "Set parameters such as minimum trading days, daily and total loss limits, profit targets, and registration fees to align with individual risk tolerance",
  traderBenefitsTitle5: "Payment Control",
  traderBenefitsDesc5:
    "Fixed rates, tiered structures, or performance-based incentives, our platform provides the flexibility to align payouts with all trading strategies and objectives",
  traderBenefitsTitle6: "Affiliate Program",
  traderBenefitsDesc6:
    "Reward system for referrals, tailored for both users and corporate clients to boost your white label crypto prop firm",

  uIUxTraderTitle: "Prop trading UX & UI",
  uIUxTraderDesc:
    "Create Prop Trading platform that is designed with a User Interface (UI) and User Experience (UX) that closely resemble those of top-tier exchanges in the cryptocurrency market",
  uIUxTraderTitle1_1: "Intuitive Interface",
  uIUxTraderTitle1_2:
    "The platform’s UI is designed to be user-friendly and intuitive, with a layout and navigation structure similar to that of leading exchanges.",
  uIUxTraderTitle2_1: "Responsive and Scalable",
  uIUxTraderTitle2_2:
    "Our UI/UX design is responsive across various devices and screen sizes, allowing traders to access the platform seamlessly from desktops, laptops, tablets, and smartphones.",
  uIUxTraderTitle3_1: "Streamlined Trading Experience",
  uIUxTraderTitle3_2:
    "We prioritize a seamless trading experience by incorporating familiar trading tools, order types, and charting functionalities commonly found on tier-one exchanges.",

  traderFooterTitle: "Want to check out the system? Book a demo with us! ",
  traderWorkTitle: "How does White Label prop trading work? ",
  traderWorkDesc1:
    "Prop Trading, short for proprietary trading, is a next-generation solution that empowers traders by providing access to firm capital. Backed by cutting-edge challenges provisioning and performance tracking tools,  TinyTrader's white label Prop Trading is designed to revolutionize the way traders engage with the market.",
  traderWorkLine:
    "Tailored for both new and experienced traders, the platform provides an opportunity for users to learn and engage in challenges with minimised financial risks and enhanced potential profits.",
  traderWorkDesc2:
    "Launch the WordPress-based crypto prop firm in the shortest time and the most efficient way.",

  spotBannerTitle: "Spot Trading",
  spotBannerDesc:
    "Our complete Spot Trading system solution is a foundational step to establish a robust trading infrastructure. Our system is built to provide a robust Spot system and intuitive user-friendly interface for seamless onboarding of users into digital trading world.",
  featuresTitle: "Main Features",
  featuresDescTrader: "White Label Prop Trading Platform",
  spotFeaturesTitle1: "Instant Settlement",
  spotFeaturesDesc1:
    "Smooth Real-Time Transaction Completion and Asset Delivery",
  spotFeaturesTitle2: "Variety of trading pairs",
  spotFeaturesDesc2: "Expanded Scope and Flexibility of Trading Instruments",
  spotFeaturesTitle3: "45+ Chains Supported",
  spotFeaturesDesc3: "Blockchains Support To Broaden The Range of Assets",
  spotFeaturesTitle4: "120+ Tokens Available",
  spotFeaturesDesc4: "Various Token Standards Were Supported",
  spotFeaturesTitle5: "Aggregated Liquidity",
  spotFeaturesDesc5: "Optimized Order Depth And Trading Efficiency",
  spotFeaturesTitle6: "Customization",
  spotFeaturesDesc6: "Traders Personalized Settings And Strategies",

  featuresTitle1: "Perpetual Contracts",
  featuresDesc1: "No Expiration Date And Featuring Funding Rate Mechanisms",
  featuresTitle2: "26 underlying assets",
  featuresDesc2: "Mainstream Underlying To Broaden Coverage And Liquidity",
  featuresTitle3: "Leverage up to 150x",
  featuresDesc3: "Provided To Amplify Users Exposure And Potential Returns",
  featuresTitle4: "Isolated & Cross Margining",
  featuresDesc4: "To Address Traders With More Flexibility In Risk Management",
  featuresTitle5: "Derived Mark Price From Major Sources",
  featuresDesc5: "To Ensure A More Stable And Reliable Reference Price",
  featuresTitle6: "Funding Fee Proper Procedure",
  featuresDesc6: "To Periodically Adjust Positions In Perpetual Contracts",

  optionsFeaturesTitle1: "Premium-Style",
  optionsFeaturesDesc1:
    "Premium Required To Be Paid Only Once Upfront, With Fixed Cost Structure",
  optionsFeaturesTitle2: "European Settlement",
  optionsFeaturesDesc2:
    "Contracts Can Only Be Exercised On The Day Of Expiration",
  optionsFeaturesTitle3: "USDT-margined",
  optionsFeaturesDesc3:
    "The Settlement Results In A USDT Payment, Instead Of Delivery Of Cryptocurrencies",
  optionsFeaturesTitle4: "Different Maturities",
  optionsFeaturesDesc4:
    "Dailies, Weeklies, Monthlies Are Available For Specific Needs And Strategies Of Traders",

  benefitsTitle: "Benefits",
  benefitsDesc: "Why set up Futures Trading System with us?",
  benefitsDescSpot: "Why set up Spot Trading system with us?",
  benefitsDescFutures: "Why set up Futures Trading System with us?",
  benefitsDescOptions: "Why set up Options Trading System with us?",
  benefitsDescTrader: "Why set up a white label crypto Prop firm with us?",
  benefitsTitle1: "Simplicity",
  benefitsDesc1: "Enable Trading Any Coins and Tokens On Your Platform",
  benefitsTitle2: "Instant Settlement",
  benefitsDesc2:
    "Allows traders to complete transactions, ensuring prompt exchange of ownership and funds for efficient and seamless trading",
  benefitsTitle3: "Customizability",
  benefitsDesc3:
    "To suit trader’s needs, enabling to customize settings, interfaces, and trading parameters for a personalized and adaptable trading environment.",
  benefitsTitle4: "Listing",
  benefitsDesc4:
    "Showcase assets and list token providing increased visibility, liquidity, and access to a range of potential investors for successful for successful market entry",
  benefitsTitle5: "Liquidity",
  benefitsDesc5:
    "Aggregated liquidity from major venues to ensure the tightest spreads in the market across coins and tokens",
  benefitsTitle6: "Risk-management",
  benefitsDesc6:
    "Advanced risk-management features, including adjustments by trading pairs, position limits, IPs, withdrawals by individuals",

  uIUxSpotTitle: "Spot UX & UI",
  uIUxSpotDesc:
    "We provide industry-like Spot Market UX / UI to clients for smooth usability and trading experience",
  uIUxSpotTitle1: "Quick Access To Spot Trading And Swap Exchange Modules",
  uIUxSpotTitle2_1: "Quick Underlying's Selection Panel",
  uIUxSpotTitle2_2: "Variety Of Spot Trading Pairs",
  uIUxSpotTitle2_3: "Market & Limit Order Types",
  uIUxSpotTitle3_1: "Key Statistics On Orders And Trades History",
  uIUxSpotTitle3_2: "Complete Information About Past Transactions",
  uIUxSpotTitle4_1: "Liquidity Supported:",
  uIUxSpotTitle4_2: "Internal Organic Pool",
  uIUxSpotTitle4_3: "Bridge To 3rd Party Exchanges",
  uIUxSpotTitle4_4: "Connectivity For External Market-Makers",
  uIUxSpotTitle4_5: "Order Book Support",
  uIUxSpotTitle4_6: "Advanced Technical Indicators",
  uIUxSpotTitle5_1:
    "UI-Friendly Wallet Supporting Smooth Transfers of Coins and Tokens",
  uIUxSpotTitle5_2: "Local Currencies Assets Representation",

  uIUxFeaturesTitle: "Futures UX & UI",
  uIUxFeaturesDesc:
    "We provide industry-like Futures Market UX / UI to clients for experienced traders with known features and adjustment parameters",
  uIUxFeaturesTitle1_1: "Quick Access To Futures Trading Module",
  uIUxFeaturesTitle1_2:
    "Watchlists, Hots, Gainers and Losers Sections For Quick Access",
  uIUxFeaturesTitle2_1: "Variety Of Spot Trading Pairs",
  uIUxFeaturesTitle2_2: "Market, Limit And Conditional Order Types",
  uIUxFeaturesTitle3_1: "Key Statistics On Orders And Trades History",
  uIUxFeaturesTitle3_2: "Complete Information About Past Transactions",
  uIUxFeaturesTitle4_1: "Liquidity Supported:",
  uIUxFeaturesTitle4_2: "Internal Organic Pool",
  uIUxFeaturesTitle4_3: "Bridge To 3rd Party Exchanges",
  uIUxFeaturesTitle4_4: "Connectivity For External Market-Makers",
  uIUxFeaturesTitle4_5: "Order Book Supported",
  uIUxFeaturesTitle4_6: "Advanced Technical Indicators",
  uIUxFeaturesTitle5_1: "More advanced Features",
  uIUxFeaturesTitle5_2: "Grid Trading Strategies",
  uIUxFeaturesTitle5_3: "Copy Trading",

  uIUxOptionsTitle: "Options UX & UI",
  uIUxOptionsDesc:
    "We provide newly-established Options Market UX / UI to clients for experienced traders with opportunity to execute strategies across markets and standalone options",
  uIUxOptionsTitle1: "Quick Access To Options Strike Board For Trading",
  uIUxOptionsTitle2_1: "Strike View Representations",
  uIUxOptionsTitle2_2: "Options Greeks Representation",
  uIUxOptionsTitle2_3:
    "Mark Implied Volatility Derived From The Most Reliable Source",
  uIUxOptionsTitle3:
    "Order Placement Section Including Quick P&L Estimation Calculator",
  uIUxOptionsTitle4_1: "Premium-Quoted Graphs",
  uIUxOptionsTitle4_2: "Liquidity Supported:",
  uIUxOptionsTitle4_3: "Internal Organic Pool",
  uIUxOptionsTitle4_4: "Connectivity For External Market-Makers",
  uIUxOptionsTitle4_5: "Order Book Supported",
  uIUxOptionsTitle4_6: "Advanced Technical Indicators",
  uIUxOptionsTitle5_1: "Key Statistics On Orders And Trades History",
  uIUxOptionsTitle5_2: "Complete Information About Past Transactions",
  uIUxOptionsTitle5_3: "Options Exercise History",

  backOfficeTitle: "Back-office",
  backOfficeDesc:
    "We provide a range of advanced admin cabinet maintaining security controls at highest levels ",
  backOfficeTab1: "Operations",
  backOfficeTab2: "Security",

  deliveryOptions: "Delivery options",
  deliveryOptionsCardTitle1: "Ecosystem Deployment",
  deliveryOptionsCardDesc1:
    "Fits in clients willing to setup an exchange from scratch as a turnkey solution",
  deliveryOptionsCard1HoverTitle: "Our Ecosystem",
  deliveryOptionsCard1HoverTitle1: "Cryptocurrency Exchange",
  deliveryOptionsCard1HoverTitle2: "Global OTC (P2P)",
  deliveryOptionsCard1HoverTitle3: "NFT Platform",
  deliveryOptionsCard1HoverTitle4: "Operational Backend Management",
  deliveryOptionsCard1HoverTitle5: "Crypto Exchange Consulting",
  deliveryOptionsCard1HoverTitle6: "Exchange Wallet Support",
  deliveryOptionsCard1HoverTitle7: "Liquidity Improvement",
  deliveryOptionsCard1HoverTitle8: "API Connectivity",

  deliveryOptionsCardTitle2: "SDK Integration",
  deliveryOptionsCardDesc2:
    "Fits in clients willing to integrate options trading system into existing client’s platform",
  deliveryOptionsCard2HoverTitle1: "Futures Trading System",
  deliveryOptionsCard2HoverTitle2: "Operational Backend Management",
  deliveryOptionsCard2HoverTitle3: "Liquidity Improvement",
  deliveryOptionsCard2HoverTitle4: "Product & Customer Support",

  featuresBannerTitle: "Perpetual Futures",
  featuresBannerDesc:
    "Our comprehensive Perpetuals Trading system solution is essential for advanced trading. Engineered with precision, our platform integrates broad range of features for smooth trading experience and reliability. This combination ensures user experience, fortified security, and optimal performance.",

  optionsBannerTitle: "Premium Options",
  optionsBannerDesc:
    "The advanced Options Trading platform offers a SaaS (Software-as-a-Service) solution, featuring European premium options, margined and settled in USDT. Through our options infrastructure, strategies can be executed with enhanced flexibility is provided with smooth user-experience.",

  cardBannerTitle: "Crypto Card",
  cardBannerDesc: "Pay Anywhere, Anytime ",
  cardBannerDesc1:
    "Utilizing our advanced Crypto Card Integration, seamlessly bridge digital assets to point-of-sale transactions. Experience instant crypto conversions at global merchants, backed by robust security protocols, ensuring efficient and secure real-world expenditure.",
  cardFeaturesTitle1: "Get A Virtual Card Fast.",
  cardFeaturesDesc1:
    "Obtain a virtual card swiftly and conveniently for secure online transactions",
  cardFeaturesTitle2: "Top Up With USDT",
  cardFeaturesDesc2:
    "Effortlessly boost your balance using USDT for seamless transactions",
  cardFeaturesTitle3: "Feel Free To Return Your Funds To USDT",
  cardFeaturesDesc3:
    "Effortlessly boost your balance using USDT for seamless transactions",
  cardFeaturesTitle4: "Shop online & offline globally",
  cardFeaturesDesc4:
    "Experience the freedom of global shopping, both online and in-store, with ease",

  cardModuleTitle: "TinyTrader Card Global",
  cardModuleTitle1: "Virtual card & physical card availability",
  cardModuleTitle2: "Fast Approval Speed",
  cardModuleTitle3: "Convenient Recharge Processes",
  cardModuleTitle4: "High Consumption Limits",
  cardModuleTitle5: "Supports Visa, MasterCard, and UnionPay",

  operationsTitle: "Operations",

  customIsAbilityTitle: "Customisability",
  customIsAbilityTitle1: "USD/Euro Physical (MasterCard)",
  customIsAbilityTitle2: "USD/Euro Virtual (MasterCard)",
  customIsAbilityTitle3: "USD/Euro Virtual (MasterCard)",
  customIsAbilityTitle4: "USD virtual/Credit (VISA)",
  customIsAbilityTitle5: "USD virtual (VISA)",

  purchasesTitle: "Make Purchases Everywhere",
  purchasesDesc:
    "In TT Crypto Card integration, businesses can easily provide access to its users use crypto funded card to make purchases anywhere",

  advantagesTitle: "Our Advantages",
  advantagesTitle1: "Help The Platform Expand Global Customers",
  advantagesTitle2:
    "Support Crypto Card Issuance Of Visa, MasterCard And UnionPay",
  advantagesTitle3:
    "Support Global ATM Withdrawal (Local Fiat Currency Withdrawal)",
  advantagesTitle4: "Support Multi-Crypto Deposit And Consumption",
  advantagesTitle5:
    "Provide A Global Compliance Acceptance Channel With Controllable Risks",
  advantagesTitle6:
    "Merchants Do Not Need To Book Cards In Advance And Settle On Demand",
  advantagesTitle7:
    "Funds Shall Be Entrusted By A Compliant Custodian Service Provider To Ensure The Safety Of Funds",
  advantagesTitle8: "Complete API Docking In One Week",
  advantagesTitle9: "Support The Issuance Of Affinity Cards",

  cryptoBannerTitle: "White-Label Crypto Exchange Platform",
  cryptoBannerDesc:
    "A ready made exchange solution with a full-range ecosystem support",
  reachUs: "Reach us",
  bookADemo: "Book A Demo",
  cryptoBannerLabel: "Award-winning white-label software",
  cryptoItemTitle1: "Trading Terminal & Advanced Back Office System",
  cryptoItemDesc1:
    "A user-friendly trading platform that smoothly processes orders for various financial instruments, thanks to a robust central processing engine. It ensures a quick, efficient, and highly reliable matching system. ",
  cryptoItemDesc1_2:
    "Experience a complete digital asset trading platform, offering Spot, Futures, Options, Prop P2P, and more.",
  cryptoItemBtn1: "Let’s Talk",
  cryptoItemTitle2: "Full-Range Ecosystem Support",
  cryptoItemDesc2_1:
    "Ecosystem Support provides our partners with a complete solution that includes all the necessary functions for a successfully operated exchange, addressing both technical and business aspects.",
  cryptoItemDesc2_2:
    "Our comprehensive ecosystem support solution encompasses technology, liquidity, custody, security, risk management, marketing, human resources, business compliance, and integrations.",
  cryptoItemBtn2: "Live Demo",

  offeringTitle: "Full-Featured Infrastructure",
  offeringTitle1: "Modular Design",
  offeringDesc1:
    "TinyTrader's microservices-driven modular design seamlessly accommodates the integration of novel workflows and features. This empowers businesses to swiftly broaden their horizons and foster innovation through our open-source framework. Every platform operating on TinyTrader leverages this flexible and adaptable modular design.",
  offeringTitle2: "Security in Custody",
  offeringDesc2:
    "Custody security is paramount, ensured by employing safekeeping solutions that have obtained certification from the MPC Alliance, underscoring our commitment to safeguarding assets. Our dedication to employing industry-certified practices reinforces the trust and confidence placed in our custody services.",
  offeringTitle3: "Cutting-Edge Backend Platform",
  offeringDesc3:
    "Our system technology stands out for its innovation, boasting an impressive capacity of up to 70,000 transactions per second (TPS) and an exceptional uptime rate of less than 0.1%. The backbone of our platform lies in its cutting-edge backend infrastructure, ensuring a robust and reliable foundation for seamless trading experiences.",
  offeringTitle4: "Streamlined Crypto Transactions and Payments",
  offeringDesc4:
    "Experience streamlined crypto transactions and payments, simplifying the process for efficient and user-friendly financial interactions. Our system is designed to provide a seamless and straightforward experience, ensuring swift and secure handling of cryptocurrency transactions.",
  offeringTitle5: "Advanced Crypto Wallet Solution",
  offeringDesc5:
    "Our advanced crypto wallet solution offers a sophisticated and user-friendly platform for securely managing and storing digital assets. With cutting-edge features and intuitive design, it provides a seamless experience for users to navigate and control their cryptocurrency holdings.",
  offeringTitle6: "Enhanced Security Measures",
  offeringDesc6:
    "Our enhanced security measures are meticulously implemented to safeguard all assets, providing a robust defense against potential threats. We prioritize the protection of your assets through advanced security protocols, ensuring a secure environment for peace of mind in your financial transactions.",
  HollaExFeaturesTitle: "TinyTrader’s Solution Features",
  HollaExFeaturesTitle1:
    "Assistance in building and operating a sustainable business model",
  HollaExFeaturesTitle2: "Liquidity",
  HollaExFeaturesTitle3: "Listings on CoinMarketCap,  CoinGecko and other",
  HollaExFeaturesTitle4: "Own Tokens Listing",
  HollaExFeaturesTitle5: "Local currencies and multi-language interface ",
  HollaExFeaturesTitle6:
    "Multi-signature wallets for safe storage and transfer of assets",
  HollaExFeaturesTitle7:
    "Multi-payment gateway and crypto, flat and on/off ramps",
  HollaExFeaturesTitle8:
    "Automated identity verification for KYC/AML compliance",
  HollaExFeaturesTitle9: "Instant P2P money transfers via our payment solution",
  HollaExFeaturesTitle10: "Mobile Application (iOS, Android)",
  HollaExFeaturesTitle11:
    "Support with obtained necessary licenses and listings on app stores",
  HollaExFeaturesTitle12:
    "Complete customisation of your platform features and capabilities",
  casesTitle: "Multiple Use Cases",
  casesDesc:
    "TinyTrader, a versatile crypto white label, offers various applications for different businesses, such as exchanges, IT development firms, online currency exchanges, and large fintech enterprises. Serving as your main crypto system, TinyTrader acts as the gateway to the world of finance built on blockchain technology.",
  caseTitle1: "Multi-Asset Exchange",
  caseDesc1:
    "Facilitate the purchase, sale, and conversion of different digital assets, including cryptocurrencies, tokens (utility or security), commodities and other tokenized assets",
  caseTitle2: "Investment Platform",
  caseDesc2:
    "Enable users to safely and strategically invest in digital assets and financial markets globally",
  caseTitle3: "Liquidity Solution",
  caseDesc3:
    "Access a variety of liquid crypto markets through our platform's trading services, facilitated by our network of liquidity providers, ensuring operators have a wide range of digital assets to choose from",
  caseTitle4: "Crypto Deposits & Payments",
  caseDesc4:
    "Enable real-world cryptocurrency payments for your tokens, services, and products, along with peer-to-peer transactions.",
  caseTitle5: "Custody & Clearing Solutions",
  caseDesc5:
    "Ensure smooth and successful deposits and withdrawals for major blockchain assets. Offer swift trading actions and create a platform where users can securely store their digital assets.",
  securityFeaturesTitle: "Platform & Security Features",
  securityFeatures1: "Matching Engine",
  securityFeaturesTitle1:
    "Most innovative trading technology with up to 70k TPS and <0.1% downtime",
  securityFeatures2: "Liquidity",
  securityFeaturesTitle2:
    "Achieved Tight Spreads and Deep Market Depth across cryptocurrencies",
  securityFeatures3: "Custody",
  securityFeaturesTitle3:
    "Secured by safekeeping solutions certified by MPC Alliance",
  securityFeatures4: "Security",
  securityFeaturesTitle4:
    "The highest ISO-proven security standards supported by leading security integrations",
  securityFeatures5: "Risk-management",
  securityFeaturesTitle5:
    "Adjustments by trading pairs, position limits, IPs, withdrawals by individuals",
  securityFeatures6: "Marketing",
  securityFeaturesTitle6:
    "Attract and retain community by built-in marketing tools and networks with KOLs",
  securityFeatures7: "Human Resource",
  securityFeaturesTitle7:
    "Full-cycle support with your business operations personnel needs",
  securityFeatures8: "Business & Compliance",
  securityFeaturesTitle8: "Assistance with business model and licenses",
  securityFeatures9: "Integrations",
  securityFeaturesTitle9:
    "Complete customisation of your platform features and capabilities",

  storiesTitle: "Success Stories: Top-Rated White-Label Crypto Exchange",
  storiesDesc:
    "Our platform has received numerous positive reviews from satisfied users",
  storiesTitle1: "Professional Custody Solution & Crypto Exchange",
  storiesDesc1:
    "TinyTrader's white-label exchange and custody solution have greatly enhanced the client's fintech services, enabling them to grow their customer base and boost revenue. By providing high-quality online crypto exchange services and a uniquely branded crypto app, our solution has proven to be a valuable asset, showcasing impressive results and contributing to overall success in their business.",
  storiesTitle2: "Fiat-to-Crypto Exchange Solution",
  storiesDesc2:
    "TinyTrader delivered a complete platform that seamlessly merges local fiat currencies such as traditional USD and the Dominican peso with cryptocurrencies in a single exchange. As a trailblazing crypto-fiat trading platform in the Dominican Republic, it provides high-quality online crypto exchange services and integrates credit card transactions and Stripe fiat on and off-ramps for user convenience.",
  storiesTitle3: "Tailored Tokens & Exchange Platform",
  storiesDesc3:
    "TinyTrader has proven to be highly effective in simplifying the process of listing various tokens, making it easy for communities to showcase tokens with applications in payment facilitation, loyalty programs, decentralized finance, NFT platforms, GameFi, Metaverse initiatives, and other Web3.0 applications. One notable use is in property investing, where tokenization is seen as the future of the industry.",
  storiesBtn: "Free Demo",

  askedQuestionsTitle: "Frequently Asked Questions",
  askedQuestionsTitle1: "What is a cryptocurrency exchange?",
  askedQuestionsDesc1:
    "A cryptocurrency exchange is an online platform that enables users to buy, sell, and trade various cryptocurrencies, providing a marketplace for digital asset transactions.",
  askedQuestionsTitle2: "How do crypto exchanges work?",
  askedQuestionsDesc2:
    "Crypto exchanges facilitate the trading of cryptocurrencies by connecting buyers and sellers. Users can place market or limit orders, and the exchange matches these orders to execute trades.",
  askedQuestionsTitle3: "Why should I build my own crypto exchange?",
  askedQuestionsDesc3:
    "Building your own crypto exchange can open up a new revenue stream by providing you with customization, control, and branding opportunities tailored to your business, thereby attracting users and generating income through trading fees and other monetization strategies.",
  askedQuestionsTitle4: "What asset type of exchange can you build?",
  askedQuestionsDesc4:
    "We can build crypto, forex, commodities and other exchanges. Please contact us if you have a specific requirement.",
  askedQuestionsTitle5: "Can I list my own token on the exchange?",
  askedQuestionsDesc5:
    "Yes, you can list as many of your own tokens as you would like.",
  askedQuestionsTitle6: "What are your partners?",
  askedQuestionsDesc6:
    "We have a vast partner network of leading companies in exchange, ranking, payment gateways, security, support, OTP, cloud providers, consulting & marketing, and iGaming.",
  askedQuestionsTitle7: "Why is your solution better than your competitors’?",
  askedQuestionsDesc7:
    "TinyTrader provides not only a SaaS platform, but also makes sure that your venture will succeed by offering complete support of the ecosystem from liquidity, risk management and security to marketing, HR, compliance, and others.",

  customerStoriesTitle: "Partners Testimonials",
  customerStoriesTitle1:
    "“We express our sincere gratitude to the TinyTrader team for developing this crypto exchange platform. Their platform simplified our launch process and offered excellent security and scalability for our crypto project.”",
  customerStoriesDesc1: "– james A. ",
  customerStoriesTitle2:
    "“We are thrilled with the outcomes achieved through the platform, and its technical proficiency ensured a seamless and timely launch of our project, proving to be a game-changer for businesses like ours in search of an innovative tech solution.”",
  customerStoriesDesc2: "– Lee R. ",
  customerStoriesTitle3:
    "“The platform's easy-to-use interface and extensive features made launching our venture a breeze. Their consistent support in tailoring the platform to our needs reflects their patience and adaptability.”",
  customerStoriesDesc3: "– Walter M. ",
  customerStoriesTitle4:
    "“We want to extend our heartfelt thanks to the TinyTrader team for creating our crypto exchange platform. Their platform made our launch process smooth and provided exceptional security and scalability for our crypto project.”",
  customerStoriesDesc4: "– Joseph V. ",
  customerStoriesTitle5:
    "“We're thrilled with the platform's success—its technical proficiency ensured a smooth and timely project launch, proving to be a game-changer for businesses seeking innovative tech solutions.”",
  customerStoriesDesc5: "– Olivia M.",
  customerStoriesTitle6:
    "“The project’s launch was made seamless by the platform's user-friendly interface and robust features. Their continuous assistance in tailoring the platform to our requirements demonstrates their patience and flexibility.”",
  customerStoriesDesc6: "– Ethan S.",
};

export default en;
