import home from "./home-zh";
import landing from "./landing-zh";
import news from "./news-zh";
import tech from "./tech-zh";
import contact from "./contact-zh";
import liquidity from "./liquidity-zh";
import about from "./about-zh";
import chatbot from "./chatbot-zh";

const zh = {
  translation: {
    contact_your: "已收到您的申请，稍后客服人员会联系您!",
    email: "邮箱",
    account1: "Telegram",
    account2: "Wechat",
    account3: "Facebook",
    account4: "Linked In",
    account5: "Medium",
    account6: "Whatsup",
    landing_page_email_error: "请输入正确的邮箱",
    landing_page_phone_error: "请输入正确的手机号",
    landing_page_name_warning: "请输入您的姓名",
    landing_page_countrys: {
      china: "中国",
      Bhutan: "不丹",
      "TimorLeste(East Timor)": "东帝汶",
      Taiwan: "中国台湾",
      Macau: " 中国澳门",
      "Hong Kong": "中国香港",
      "Central African Republic": "中非共和国",
      Denmark: "丹麦",
      Ukraine: "乌克兰",
      Uzbekistan: "乌兹别克斯坦",
      Uganda: "乌干达",
      Uruguay: "乌拉圭",
      Chad: "乍得",
      Yemen: "也门",
      Armenia: "亚美尼亚",
      Israel: "以色列",
      Iraq: "伊拉克",
      Iran: "伊朗",
      Belize: "伯利兹",
      "Cape Verde": "佛得角",
      Russia: "俄罗斯",
      Bulgaria: "保加利亚",
      Croatia: "克罗地亚",
      Gambia: "冈比亚",
      Iceland: "冰岛",
      Guinea: "几内亚",
      GuineaBissau: "几内亚比绍",
      Liechtenstein: "列支敦士登",
      CongoRepublic: "刚果（布）",
      CongoDemocraticRepublic: "刚果（金）",
      Libya: "利比亚",
      Liberia: "利比里亚",
      Canada: "加拿大",
      Ghana: "加纳",
      Gabon: "加蓬",
      Hungary: "匈牙利",
      "South Africa": "南非",
      Botswana: "博茨瓦纳",
      Qatar: "卡塔尔",
      Rwanda: "卢旺达",
      Luxembourg: "卢森堡",
      Indonesia: "印尼",
      India: "印度",
      Guatemala: "危地马拉",
      Ecuador: "厄瓜多尔",
      Eritrea: "厄立特里亚",
      Syria: "叙利亚",
      Cuba: "古巴",
      Kyrgyzstan: "吉尔吉斯斯坦",
      Djibouti: "吉布提",
      Kazakhstan: "哈萨克斯坦",
      Colombia: "哥伦比亚",
      "Costa Rica": "哥斯达黎加",
      Cameroon: "喀麦隆",
      Tuvalu: "图瓦卢",
      Turkmenistan: "土库曼斯坦",
      Turkey: "土耳其",
      "Saint Lucia": "圣卢西亚",
      "Saint Kitts and Nevis": "圣基茨和尼维斯",
      "Sao Tome and Principe": "圣多美和普林西比",
      "Saint Vincent and the Grenadines": "圣文森特和格林纳丁斯",
      "San Marino": "圣马力诺",
      Guyana: "圭亚那",
      Tanzania: "坦桑尼亚",
      Egypt: "埃及",
      Ethiopia: "埃塞俄比亚",
      Kiribati: "基里巴斯",
      Tajikistan: "塔吉克斯坦",
      Senegal: "塞内加尔",
      Serbia: "塞尔维亚",
      "Sierra Leone": "塞拉利昂",
      Cyprus: "塞浦路斯",
      Seychelles: "塞舌尔",
      Mexico: "墨西哥",
      Togo: "多哥",
      "Dominican Republic": "多明尼加共和国",
      Dominica: "多米尼加",
      Austria: "奥地利",
      Venezuela: "委内瑞拉",
      Bangladesh: "孟加拉国",
      Angola: "安哥拉",
      Anguilla: "安圭拉",
      "Antigua and Barbuda": "安提瓜和巴布达",
      Andorra: "安道尔",
      Micronesia: "密克罗尼西亚",
      Nicaragua: "尼加拉瓜",
      Nigeria: "尼日利亚",
      Niger: "尼日尔",
      Nepal: "尼泊尔",
      Bahamas: "巴哈马",
      Pakistan: "巴基斯坦",
      Barbados: "巴巴多斯",
      "Papua New Guinea": "巴布亚新几内亚",
      Paraguay: "巴拉圭",
      Panama: "巴拿马",
      Bahrain: "巴林",
      Brazil: "巴西",
      "Burkina Faso": "布基纳法索",
      Burundi: "布隆迪",
      Greece: "希腊",
      Palau: "帕劳",
      "Cayman Islands": "开曼群岛",
      Germany: "德国",
      Italy: "意大利",
      "Solomon Islands": "所罗门群岛",
      Latvia: "拉脱维亚",
      Norway: "挪威",
      "Czech Republic": "捷克共和国",
      Moldova: "摩尔多瓦",
      Morocco: "摩洛哥",
      Monaco: "摩纳哥",
      Brunei: "文莱",
      Fiji: "斐济",
      Swaziland: "斯洛伐克",
      Slovakia: "斯洛伐克",
      Slovenia: "斯洛文尼亚",
      "Sri Lanka": "斯里兰卡",
      Singapore: "新加坡",
      "New Zealand": "新西兰",
      Japan: "日本",
      Chile: "智利",
      KoreaNorth: "朝鲜",
      Cambodia: "柬埔寨",
      Grenada: "格林纳达",
      Georgia: "格鲁吉亚",
      "Vatican City": "梵蒂冈城",
      Belgium: "比利时",
      Mauritania: "毛里塔尼亚",
      Mauritius: "毛里求斯",
      Tonga: "汤加",
      "Saudi Arabia": "沙特阿拉伯",
      France: "法国",
      Poland: "波兰",
      "Bosnia and Herzegovina": "波斯尼亚和黑塞哥维那",
      Thailand: "泰国",
      Zimbabwe: "津巴布韦",
      Honduras: "洪都拉斯",
      Haiti: "海地",
      Australia: "澳大利亚",
      Ireland: "爱尔兰",
      Estonia: "爱沙尼亚",
      Jamaica: "牙买加",
      "Trinidad and Tobago": "特里尼达和多巴哥",
      Bolivia: "玻利维亚",
      Nauru: "瑙鲁",
      Sweden: "瑞典",
      Switzerland: "瑞士",
      Vanuatu: "瓦努阿图",
      Belarus: "白俄罗斯",
      Bermuda: "百慕大",
      Kuwait: "科威特",
      Comoros: "科摩罗",
      "Cote Ivoire": "科特迪瓦",
      Kosovo: "科索沃",
      Peru: "秘鲁",
      Tunisia: "突尼斯",
      Lithuania: "立陶宛",
      Somalia: "索马里",
      Jordan: "约旦",
      Namibia: "纳米比亚",
      Myanmar: "缅甸",
      Romania: "罗马尼亚",
      "United States": "美国",
      USVirginIslands: "美属维尔京群岛",
      Laos: "老挝",
      Kenya: "肯尼亚",
      Finland: "芬兰",
      Sudan: "苏丹",
      Suriname: "苏里南",
      "United Kingdom": "英国",
      "British Virgin Islands": "英属维尔京群岛",
      Netherlands: "荷兰",
      Mozambique: "莫桑比克",
      Lesotho: "莱索托",
      Philippines: "菲律宾",
      "El Salvador": "萨尔瓦多",
      Samoa: "萨摩亚",
      Portugal: "葡萄牙",
      Mongolia: "蒙古",
      Spain: "西班牙",
      Benin: "贝宁",
      Zambia: "赞比亚",
      "Equatorial Guinea": "越南",
      Vietnam: "越南",
      Azerbaijan: "阿塞拜疆",
      Afghanistan: "阿富汗",
      Algeria: "阿尔及利亚",
      Albania: "阿尔巴尼亚",
      Oman: "阿曼",
      Argentina: "阿根廷",
      "United Arab Emirates": "阿联酋",
      "South Korea": "韩国",
      Macedonia: "马其顿",
      Maldives: "马尔代夫",
      Malawi: "马拉维",
      Malaysia: "马来西亚",
      "Marshall Islands": "马绍尔群岛",
      Malta: "马耳他",
      Madagascar: "马达加斯加",
      Mali: "马里",
      Lebanon: "黎巴嫩",
      Montenegro: "黑山",
    },
    //Contact Us
    cotact_us: "联系我们",
    cotact_us_title: "与我们的区块链专家预约咨询",
    name: "姓名",
    email: "邮箱",
    phone_number: "手机号码",
    social_media: "社交媒体",
    preferred_language: "首选语言",
    comments_city_timezone: "留言/城市/时区",
    cotact_us_remarks: "TinyTrader使用提供的信息就我们的相关产品和服务与您联系",
    request_callback: "申请使用",
    search: "搜索",
    "404-1": "5秒后，您将跳转到TinyTrader首页",
    "404-2": "返回首页",
  },
  home,
  landing,
  news,
  tech,
  contact,
  liquidity,
  about,
  chatbot,
};

export default zh;
