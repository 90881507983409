const ko = {
  news: "News",
  newsSubtitle: "뉴스에서 저희를 알아봅니다",
  news20230111Title: "럭키머니 기능 전면출시! ",
  news20230111SubTitle:
    "2023년1월11일,  TinyTrader가 럭키머니 기능을 전면출시합니다.  고객 여러분들의 적극적인 참여 바랍니다.",
  news20230111Content1: "TinyTrader 이용자분들께: ",
  news20230111Content2:
    "2023년1월11일,  TinyTrader가 럭키머니 기능을 전면출시합니다.  고객 여러분들의 적극적인 참여 바랍니다.   (APP으로만 가능) ",
  news20230111Content3: "럭키머니 기능: ",
  news20230111Content4:
    "1. U코인을  럭키머니에 넣어 친구에게 낼 수 있습니다.  (럭키머니는 일반형 럭키머니와 행운형 럭키머니로 나뉨)",
  news20230111Content5:
    "2. 신규회원 럭키머니를  통해 더 많은  신규 사용자들이 플랫폼  계정에  등록하도록 합니다. ",
  news20230111Content6:
    "3. 럭키머니를 공유하여 럭키머니를 받은 자가 신규회원에 가입할 경우,  본 신규회원은 해당 럭키머니를 공유한 초대자의 소개를 받은 것으로 인정합니다. ",
  news20230111Content7:
    "주의:  럭키머니 유효기간은 24시간이며,  기간이 만료되어 활성화하지 못한 럭키머니는 해당 럭키머니를 발송한 이용자의 계정으로 환불됩니다. ",
  news20230111Content8: "TinyTrader",
  news20230111Content9: "2023년 1월 11일",
  news20230401Title: "TinyTrader 선물거래 그리드 로봇 출시! ",
  news20230401SubTitle:
    "계약 그리드 거래는 TinyTrader가 출시한 최신 기능으로 사용자가 암호화폐 시장을 최대한 활용할 수 있도록 합니다. 설정된 매개변수에서 자발적으로 작동할 수 있으며 거래자가 작은 가격 변동, 특히 구간 변동 시장에서 이익을 얻을 수 있도록 합니다.",
  news20230401Content1: "TinyTrader 이용자분들께: ",
  news20230401Content2: "<b>포인트：</b>",
  news20230401Content3:
    "• 그리드 거래는 현재 가격 범위 내에서 미리 설정된 시간 간격으로 선물 계약을 자동으로 매매할 수 있는 전략적 거래 도구입니다.",
  news20230401Content4:
    "• 거래자들이 작은 가격 변동, 특히 구간 변동 시장에서 이익을 얻을 수 있도록 합니다.",
  news20230401Content5:
    "• 사용자 스스로 파라미터를 설정하고, 원클릭하여 주문하면 그리드 거래 전략을 만들 수 있습니다.",
  news20230401Content6:
    "• 상가의 거래량과 거래 수수료 수입을 지속적으로 증가시킵니다. ",
  news20230401Content7:
    "TinyTrader 계약 그리드 거래는 무기한 계약을 기반으로 하며 사용자가 자동으로 주문을 구매하고 판매할 수 있는 도구를 만듭니다. 클릭하면 암호화폐의 일상적인 변동으로부터 이익을 얻을 수 있습니다.",
  news20230401Content8:
    "계약 그리드 거래는 TinyTrader가 출시한 최신 기능으로 사용자가 암호화폐 시장을 최대한 활용할 수 있도록 합니다. 설정된 매개변수에서 자발적으로 작동할 수 있으며 거래자가 작은 가격 변동, 특히 구간 변동 시장에서 이익을 얻을 수 있도록 합니다.",
  news20230401Content9: "TinyTrader 계약 그리드 로봇은 어떤 우세가 있습니까?",
  news20230401Content10:
    "<b>전천후 자동 거래: </b>TinyTrader 계약 그리드 로봇을 사용하여 항상 가격 동향을 추적할 필요 없고 포지션 정보를 입력하고 로봇에게 모든 것을 맡기고 수익을 받기만 하면 됩니다.",
  news20230401Content11:
    "<b>과매/공매/중립 모드 선택: </b>과매 전략을 선택하는 경우 로봇은 과매 거래 개시와 스퀴어를 수행합니다. 공매 전략을 선택하면 로봇은 공매도 거래개시와 스퀴어를 수행합니다. 중립 전략을 선택하면 로봇은 가격이 시장 가격보다 높을 때 공매도 거래개시와 스퀴어를 수행하며 시장 가격보다 낮을 때 과매 거래개시와 스퀴어를 수행합니다.",
  news20230401Content12:
    "<b>레버리지로 수익 확대: </b>레버리지로 거래를 하고 사용자의 포지션 규모를 확대하여 더 높은 수익을 얻는 것입니다.",
  news20230401Content13:
    "<b>초보자도 쉽게 시작할 수 있다: </b>계약 거래 초보자라고 하십니까? 괜찮습니다, TinyTrader 계약 그리드 로봇이 풍부한 경험 없는 사용자는 쉽게 거래할 수 있도록 도와줍니다.",
  news20230401Content14:
    "사용자가 단기 거래를 선호하든 장기 거래를 선호하든, 계약 거래 초보자든 베테랑 전문가든 그리드 로봇은 여러 우세를 제공할 수 있습니다. 빨리 TinyTrader 계약 그리드 로봇을 사용하여 새로운 거래 경험을 시작합시다.",
  news20230401Content15: "TinyTrader",
  news20230401Content16: "2023년 4월 1일",
  news20230505Title:
    "TinyTrader 주도 디지털 통화 옵션 거래 혁신, Saas 제품 창시는 시장 기회 잡기에 조력한다! ",
  news20230505SubTitle:
    "TinyTrader 옵션 거래 SaaS 제품의 혁신적인 거래 방식과 첨단 기술이 투자자들에게 새로운 거래 경험과 수익 기회를 제공할 것이라고. 이제 새로운 거래의 여정을 시작합시다!",
  news20230505Content1:
    "귀사의 플랫폼 투자자는 더 높은 투자 수익률을 갈망합니까? 귀사의 플랫폼 투자자는 전통적인 거래 방식에 싫증이 났고 더 도전적인 투자 경험을 찾고 있습니까? 이제 새로운 거래 제품인 TinyTrader 옵션 거래 SaaS 제품을 추천합니다! ",
  news20230505Content2:
    "옵션 거래는 혁신적인 금융상품으로 투자자들이 미리 결정된 가격과 시간 범위 내에서 자산을 구매하거나 판매할 수 있도록 하합니다. 선물 계약 거래에 비해 옵션 거래는 더 높은 유연성과 큰 투자 수익률을 제공합니다. 저희의 옵션거래 제품은 TinyTrader 으로 정성껏 만들어진 것으로 업계 최초 옵션거래 SaaS 제품이며 디지털 통화 옵션거래 혁신을 선도할 것입니다! ",
  news20230505Content3:
    "저희의 옵션 거래 SaaS 제품은 최신 기술과 혁신적인 솔루션을 사용하여 투자자에게 새로운 디지털 통화 옵션 거래 방식을 제공합니다. 이 제품은 다음과 같은 특징을 가지고 있습니다.",
  news20230505Content4:
    "- <b>신속한 시장 선점</b>: SaaS 또는 통합 SDK 서비스를 제공하고 옵션 시장의 깊이를 공유하며 플랫폼의 옵션 비즈니스 문턱을 낮추고 플랫폼이 디지털 통화 옵션 시장을 빠르게 점유할 수 있도록 지원합니다.",
  news20230505Content5:
    "- <b>유연하고 다양한 거래 전략</b>: 다양한 유형의 옵션 계약을 제공하고 사용자는 자신의 투자 목표와 리스크 선호도에 따라 적절한 계약을 선택하여 거래 기회를 개척하고 수익이 무한합니다.",
  news20230505Content6:
    "- <b>독특한 리스크 관리 시스템</b>: 저희의 리스크 관리 시스템은 사용자의 자금 안전을 보장하기 위해 최첨단 기술과 전략을 채택합니다. 저희 시스템은 사용자 계정의 거래와 포지션을 자동으로 모니터링하고 이상이 발견되면 즉시 해당 조치를 취하여 사용자의 자금 손실을 피합니다.",
  news20230505Content7:
    "- <b>새로운 사용자 거래 경험</b>: 유럽식 옵션을 기반으로 첨단 지능형 알고리즘을 사용하여 전자동화된 거래 경험을 실현하고 거래자를 번거로운 거래 프로세스에서 해방시켜 투자자는 빠른 거래 실행 속도와 원활을 누릴 수 있으며 언제 어디서나 쉽고 즐겁게 거래할 수 있습니다.",
  news20230505Content8:
    "- <b>정확한 가격 발견 메커니즘</b>: 표시 가격은 Black-Scholes 모델에 의해 결정되며 실시간으로 업데이트되어 시장의 추세를 정확하게 반영할 수 있습니다. 가격 책정 과정에서 저희는 시장 가격 및 다른 요소를 충분히 고려하여 사용자의 투자 결정에 대한 참고 자료를 제공하기 위해 공정한 옵션 표시 가격을 제공합니다.",
  news20230505Content9:
    "- <b>전천후 고객 서비스</b>: TinyTrader의 고객 서비스 팀은 업계 전문가들로 구성되어 있으며, 당신에게 양질의 서비스와 지원을 제공할 수 있습니다. 거래 운영이나 리스크 관리나 계정 관리까지 최적의 솔루션을 제공합니다.",
  news20230505Content10:
    "옵션 거래는 도전적이고 혁신적인 거래 방법으로 투자자가 더 높은 투자 수익률과 더 정확한 거래 결정을 달성하도록 합니다. TinyTrader 옵션 거래 SaaS 제품의 혁신적인 거래 방식과 첨단 기술이 투자자들에게 새로운 거래 경험과 수익 기회를 제공할 것이라고 믿습니다. 이제 새로운 거래의 여정을 시작합시다!",
  news20230505Content11: "TinyTrader 이용자분들께: ",
  news20230505Content12: "TinyTrader",
  news20230505Content13: "2023년 5월 5일",
  news20230619Title: "TinyTrader OpenAPI로 혁신과 협업 강화!",
  news20230619SubTitle:
    "OpenAPI의 출시로 사용자들에게 더 큰 유연성과 통합을 제공하여 디지털 자산 거래에서 성공할 수 있도록 도와줍니다.",
  news20230619Content1:
    "TinyTrader의 OpenAPI가 정식으로 론칭되었음을 자랑스럽게 알려드립니다! 이 강력한 도구는 더 많은 기회와 수익을 제공하여 디지털 자산 시장에서 선도적인 위치를 차지할 수 있도록 도와줍니다.",
  news20230619Content2:
    "세계적인 선도적인 SaaS 암호화폐 거래 서비스 제공업체로서 TinyTrader는 기관 트레이더들을 위해 안전하고 효율적이며 혁신적인 디지털 자산 거래 솔루션을 제공하는 데 헌신하고 있습니다. 우리는 지속적으로 사용자 경험을 향상시키며, 시장 요구의 변화에 부응하기 위해 새로운 제품과 기능을 도입하고 있습니다. 이제 OpenAPI의 출시로 사용자들에게 더 큰 유연성과 통합을 제공하여 디지털 자산 거래에서 성공할 수 있도록 도와줍니다.",
  news20230619Content3:
    "TinyTrader의 OpenAPI 제품은 다양한 기능과 데이터를 제공하여 거래에 필요한 요구를 충족시킵니다. 이 제품의 주요 기능은 다음과 같습니다:",
  news20230619Content4:
    "• <b>개방형 거래 기능</b>: OpenAPI를 통해 주문 등 거래 작업을 수행할 수 있습니다. 주문 등록, 주문 취소, 거래 내역 조회 등을 자동화된 거래로 수행하고, 필요에 따라 개인화된 거래 전략을 설정하여 시장을 탐색할 수 있습니다.",
  news20230619Content5:
    "• <b>실시간 시장 데이터</b>: 최신 시장 동향, 거래 페어 가격, 깊이 데이터 등 실시간 정보에 접근할 수 있습니다. 이러한 데이터는 시장 변동에 기반하여 신중한 거래 결정을 내리고 전략을 신속하게 조정하는 데 도움이 됩니다.",
  news20230619Content6:
    "• <b>종합적인 계정 관리</b>: OpenAPI는 계정 잔액 및 거래 내역 조회 등 종합적인 계정 관리 기능을 제공합니다. 디지털 자산과 거래 활동을 보다 쉽게 관리하며, 포트폴리오의 전체적인 상황을 파악할 수 있습니다.",
  news20230619Content7:
    "• <b>보안 조치</b>: TinyTrader 는 안전하고 신뢰할 수 있는 API 인증 및 접근 제어 메커니즘을 통해 사용자 데이터와 자금을 보호합니다. 고급 암호화 기술과 신원 확인 조치를 사용하여 거래 과정을 보호합니다.",
  news20230619Content8:
    "• <b>전방위 서비스 지원</b>: 우리는 자세한 API 문서, 샘플 코드 및 기술 지원을 제공하여 빠르게 시작하고, 마주치는 문제를 해결할 수 있도록 돕습니다.",
  news20230619Content9:
    "• <b>시장 메이킹 서비스</b>: OpenAPI를 통해 종합적인 시장 메이킹 서비스를 제공하여 보다 우수한 수익률과 낮은 지연 시간을 제공합니다. 이를 통해 시장 영향력과 유동성 관리 능력을 극대화하고, 실시간으로 시장을 모니터링하여 유연하게 전략을 조정하여 거래 효율성을 향상시키고, 리스크를 관리하며, 상당한 수익을 창출할 수 있습니다.",
  news20230619Content10:
    "• <b>브로커 서비스</b>: OpenAPI를 통해 최고급 거래량과 스팟 및 파생 시장의 깊이와 유동성을 공유하는 프리미어 브로커 서비스를 제공합니다. 높은 거래 수수료 할인과 유연한 수수료 마크업을 제공합니다.",
  news20230619Content11:
    "TinyTrader의 OpenAPI 제품을 통해 여러분과 개발자들은 우리의 거래 플랫폼을 심도 있게 통합하고 맞춤형 거래 도구, 전략 및 애플리케이션을 개발할 수 있습니다. 여러분과 파트너들께서 이 혁신적인 제품을 적극적으로 탐색하고, 함께 디지털 자산 거래 산업의 발전을 이끌어 나가기를 권장합니다.",
  news20230619Content12:
    "TinyTrader OpenAPI에 대해 자세히 알아보고 디지털 자산 거래의 여정을 시작하세요: <a>TinyTrader OpenAPI</a>",
  news20230619Content13: "최고의 인사를 전합니다,",
  news20230619Content14: "TinyTrader",
  news20230619Content15: "2023년 6월 19일",
  news20230815Title:
    "TinyTrader는 공식적으로 새로운 스팟 거래 및 UI 2.0 버전 업그레이드를 출시하며 사용자에게 더 뛰어난 거래 경험을 제공하고자 합니다.",
  news20230815SubTitle:
    "TinyTrader는 사용자에게 탁월한 거래 경험을 제공하기 위해 새로운 스팟 거래 플랫폼과 UI 2.0 버전 업그레이드를 자랑스럽게 공개합니다. 우리는 지속적인 혁신과 탁월함을 추구하는 과정에서 사용자에게 강력하고 편리하며 안전한 기능을 제공하여 암호화폐 시장에서 더 큰 성공을 돕고자 합니다.",
  news20230815Content1:
    "• <b>새로운 UI 2.0, 환상적인 변화</b>: 새로운 UI 2.0 버전은 상쾌한 인터페이스 디자인을 제공하며 최적화된 레이아웃과 시각 효과를 강조하여 사용자가 더 직관적이고 편안한 거래 경험을 느낄 수 있도록 합니다. 포괄적인 인터페이스 업그레이드는 사용자의 거래 인식을 향상시키고 시장 정보를 더 신속하게 얻어 현명한 결정을 내릴 수 있도록 돕기 위한 것입니다.",
  news20230815Content2:
    "• <b>실시간 시장 정보, 포괄적인 정보</b>: 스팟 거래 플랫폼은 사용자에게 실시간 시장 정보와 포괄적인 통찰력을 제공하여 현명한 거래 결정을 지원합니다. 차트 도구와 기술 지표는 사용자가 시장 동향을 더 정확하게 분석하도록 돕고 거래 결정에 강력한 지원을 제공합니다.",
  news20230815Content3:
    "• <b>무한한 선택, 다양한 거래</b>: 스팟 거래 플랫폼은 사용자에게 더 많은 암호화폐 거래 쌍을 제공합니다. TT는 상인들에게 맞춤형 상장 서비스를 제공하여 다양한 투자 요구를 충족시킵니다. 사용자가 초보자이든 경험 많은 트레이더이든 가장 적합한 거래 쌍을 찾아 시장 기회를 손쉽게 포착할 수 있습니다.",
  news20230815Content4:
    "• <b>안전하고 신뢰할 수 있는 자산 보호</b>: 사용자의 안전은 항상 우리의 최우선 과제입니다. 이번 버전 업그레이드에서는 보다 강력한 보안 보호 시스템을 구축하였으며 최신 암호화 기술과 다중 인증 메커니즘을 도입하여 사용자의 자산과 거래 정보를 최대한 보호하도록 합니다.",
  news20230815Content5:
    "• <b>사용자 경험, 지속적인 개선</b>: TinyTrader는 사용자에게 탁월한 거래 경험을 제공하기 위해 노력하고 있습니다. 우리는 계속해서 사용자의 피드백을 듣고 후속 버전 업그레이드에서 기능을 지속적으로 최적화하고 개선하여 지속적으로 성장하는 사용자 요구를 충족시키고자 합니다.",
  news20230815Content6: "",
  news20230815Content7:
    "이 중요한 버전 업그레이드는 TinyTrader의 기술 혁신과 사용자 경험 측면에서의 새로운 이정표를 나타냅니다. 우리는 이 업그레이드를 통해 사용자들이 더욱 쉽고 효율적으로 암호화폐 시장에 참여할 것임을 굳게 믿고 있습니다.",
  news20230815Content8:
    "마지막으로, 항상 지지해 주셔서 진심으로 감사드립니다. 여러분의 신뢰는 우리의 지속적인 발전을 위한 원동력입니다!",
  news20230815Content9: "",
  news20230815Content10: "TinyTrader 팀",
  news20230815Content11: "2023년 8월 15일",

  homeNewsReadMore: "Read More",
  homeNewsTitle: "In the news",
  homeNewsDesc:
    "We Proudly Unveils Its Latest Advancements In Derivatives  Technologies. As We Continue To Set Industry Standards, We Invite You To Explore The Innovations That Are Redefining Our Platform's Capabilities",

  // 新版英文官网
  ttNewsTitle: "Today News",
  ttNewsDesc:
    "We Proudly Unveils Its Latest Advancements In White-Label Digital Assets Trading Technologies. As We Continue To Set Industry Standards, We Invite You To Explore The Innovations That Are Redefining Our Platform's Capabilities",
  ttNewsView: "View Recent Tiny News",

  newsViewTitle: "View Recent Tiny News",
  newsReadTitle: "Read Also",
  eventViewsTitle: "View Recent Tiny events",
  eventWatchTitle: "Watch Also",
  publicationsTitle: "Publications",
  publicationsDesc:
    "A library of informative presentations and video about our products and industry-related issues. Browse through our extensive range of subject matter and get educated.",

  // events
  eventsTitle: "TinyTrader's VIP After Party at Asia Blockchain Summit 2024: Iconic Web3 Expo In Taiwan",
  eventsDesc: `As an after-party of the Asia Blockchain Summit, this event brings together Web3 founders, investors, crypto exchanges, industry builders and crypto enthusiasts to network and build meaningful connections.`,
  eventsBannerBtn: "Watch Now",
  eventsListTitle: "Here’s What We Announced.",
  eventsItemBtn: "Learn More",

  educationTitle: "Education",
  educationDesc:
    "We Proudly Unveils Its Latest Advancements In White-Label Digital Assets Trading Technologies. As We Continue To Set Industry Standards, We Invite You To Explore The Innovations That Are Redefining Our Platform's Capabilities",
  educationBtn: "View More",
  educationPresentations: "Presentations",
  educationPresentationsDesc:
    "A library of informative presentations and video about our products and industry-related issues. Browse through our extensive range of subject matter and get educated.",
  educationItemsTitle1: "How Crypto Payment Processing Works",
  educationItemsDesc1:
    "In this video, Arthur Azizov, CEO and Founder of the TinyTrader Group, explains the basics of crypto payment processing and how businesses can benefit from using a crypto payment solution like B2BinPay. He dives into the details of the processing infrastructure, current trends in crypto payments, differences between USDT and USDC stablecoins, and more.",
  educationPublications1:
    "We Proudly Unveils Its Latest Advancements In White-Label Digital Assets Trading Technologies. As We Continue To Set Industry Standards, We Invite You To Explore The Innovations That Are Redefining Our Platform's Capabilities",

  marketplaceTitle: "TT Marketplace",
  marketplaceDesc:
    "Ideal venue for businesses seeking marketing collaborations, co-marketing activities, integrations, and revenue-sharing opportunities. With a diverse community of companies, we offer an open and dynamic environment for strategic partnerships. By harnessing the power of synergistic alliances, businesses can expand their reach and access TT’s extensive customer base, which continues to grow exponentially.",
  marketplaceBtn: "Find More",
  marketplaceTitle1: "Welcome to TinyTrader​",
  marketplaceSubtitle: "Empowering Digital Trading Systems Beyond Borders​",
  marketplaceDesc1:
    "TinyTrader is a fintech Software-as-a-Service (SaaS) company specializing in developing and providing technological and liquidity solutions. Our primary focus is to develop and deliver innovative, secure, scalable and robust trading infrastructure, ecosystem applications, and services to businesses and organizations.​",
  marketplaceTitle2: "Mission​",
  marketplaceDesc2:
    "To foster collaboration and create a dynamic ecosystem where businesses can flourish together.​",
  marketplaceTitle3: "Vision",
  marketplaceDesc3:
    "Establish a collaborative and flourishing business community, where companies unite to enhance each other’s operations through cooperation and mutual support.​",
  marketplaceCoreTitle: "Why Join Our Marketplace?​",
  marketplaceCoreDesc:
    "What sets the TT Marketplace apart is its emphasis on cooperation and mutual benefits. By coming together, companies can better achieve more significant milestones and drive innovation to new heights.​",
  marketplaceCoreTitle1: "Affiliate Marketing​",
  marketplaceCoreDesc1:
    "Partner in our affiliate marketing model to promote your product offerings to a broader audience.​",
  marketplaceCoreTitle2: "Co-marketing",
  marketplaceCoreDesc2:
    "Jointly create and promote marketing campaigns,leveraging combined resources for increased brand exposure and mutual growth.",
  marketplaceCoreTitle3: "Revenue Sharing",
  marketplaceCoreDesc3:
    "Collaborate by sharing profits based on contributions, thus fostering mutual success through aligned interests and joint efforts.",
  marketplaceCoreTitle4: "Integrations",
  marketplaceCoreDesc4:
    "Spur innovation by joining the App Partner, to create collaboratively designed product and expand market reach.",
  marketplacePartnerTitle: "Featured Partners",
  marketplacePartnerDesc:
    "TinyTrader has partnered with a broad range of companies across different business segments.",
  marketplacePartnerBtn: "Join Us",

  // 专题列表-详情
  bookADemo: "Book A Demo",
  events20240419Title: "TinyTrader at Token 2049 Dubai: after Party Side Event",
  events20240419SubTitle:
    "We are thrilled to share the exciting news that TinyTrader is set to make its mark at the highly-anticipated Token2049 conference in Dubai, UAE, taking place from April 18th to 19th.",
  events20240419DetailTitle:
    "TinyTrader's VIP After Party at Token2049: Embracing Crypto's Shining Future",
  events20240419SubTitleDetail:
    "As an after-party of the Dubai Crypto Week, this event brings together Web3 founders, investors, tier 1 exchanges, and industry builders to foster collaboration, share insights, and forge meaningful connections.",
  events20240419ContentLabel1: "Event Details",
  events20240419Content1_1:
    "As the sun sets on Dubai Crypto Week, we gathering top visionaries, innovators, and pioneers in the crypto space. It won't just be a party; it'll be a celebration of the future of finance and the incredible journey of the crypto industry.",
  events20240419Content1_2:
    "An evening of unparalleled networking opportunities awaits, set against the iconic skyline of Dubai and the majestic Burj Khalifa. We're bringing together Web3 founders, investors, tier 1 exchanges, and industry builders to share insights and forge connections that are bound to shape the future of the industry.",
  events20240419Content1_3:
    "But it won't be just business talk all night! Our guests will enjoy premium drinks, delectable canapes, and the chance to participate in exciting Lucky Draw Games, with exclusive gifts up for grabs. Get ready for an event that promises to be nothing short of extraordinary!",
  events20240419Content1_4:
    "Join us as we raise our glasses to toast to innovation, collaboration, and the limitless possibilities that lie ahead. Together, let's make this night one to remember, setting the stage for a future where the only constant is change, and the potential for greatness knows no bounds.",
  events20240419ContentLabel2: "About TinyTrader",
  events20240419Content2_1:
    "TinyTrader (TT) is an award-winning white-label trading technology and liquidity solutions provider. With over 50+ Institutional Clients, 100+ Employees, 10+ Countries, and $5B+ Volume, we connect digital-asset trading between fiat and digital currencies for global trading platforms, brokers, developers, and institutions.",
  events20240419Content2_2:
    "At TinyTrader, our unwavering commitment to delivering cutting-edge white-label solutions is at the core of our mission. We empower a diverse spectrum of projects, spanning traditional finance, CFD brokerages, financial technology, and the crypto sector, to elevate their business operations. Our team embodies a unique blend of expertise and transparency, driven by an unwavering commitment to your success. We are well-prepared to share our technological prowess and knowledge to propel your achievements.",
  events20240419ContentLabel3: "About Token2049",
  events20240419Content3:
    "TOKEN2049 is a global conference series, where the crypto ecosystem's decision-makers connect to exchange ideas, network, and shape the industry. TOKEN2049 is the pre-eminent meeting place for entrepreneurs, institutions, industry insiders, investors, builders, and those with a strong interest in the crypto and blockchain industry.",

  events20240219Title:
    "TinyTrader launches a revolutionary proprietary trading solution that signifies our ongoing commitment to innovation in the rapidly evolving world of digital assets.",
  events20240219SubTitle:
    "TinyTrader is excited to announce the launch of its newest product, Prop Trading, a revolutionary proprietary trading solution that signifies our ongoing commitment to innovation in the rapidly evolving world of digital assets.",
  events20240219SubTitleDetail:
    "A challenging program that allows users to earn higher profits by leveraging provided funds",
  events20240219ContentLabel1:
    "Future Generation White-Label Proprietary Trading Solution Release",
  events20240219Content1:
    "TinyTrader is excited to announce the launch of its newest product, Prop Trading, a revolutionary proprietary trading solution that signifies our ongoing commitment to innovation in the rapidly evolving world of digital assets.",
  events20240219ContentLabel2:
    "Tailored for both new and experienced traders, the platform provides an opportunity for users to learn and engage in challenges with minimised financial risks and enhanced potential profits.",
  events20240219Content2: "What is Prop Trading in detail?",
  events20240219ContentLabel3:
    "Prop Trading, short for proprietary trading, is a next-generation solution that empowers traders by providing access to firm capital. Backed by cutting-edge challenges provisioning and performance tracking tools, TinyTrader's Prop Trading is designed to revolutionize the way traders engage with the market.",
  events20240219Content3: "7 Key Features:",
  events20240219ContentLabel4:
    "1. Fully Customizable Platform Based on WordPress:",
  events20240219Content4:
    "Experience complete flexibility with the theme design, manage content through the CMS and enhance your system with a wide range of WordPress tools",
  events20240219ContentLabel5:
    "Guarantee swift speed and seamless responsiveness to provide users with an optimal experience",
  events20240219Content5: "2. Flexible Challenge Setup Back-Office:",
  events20240219ContentLabel6:
    "Customise the challenge setup with a user-friendly back-office interface",
  events20240219Content6:
    "Tailor challenges based on specific criteria, including the number of steps, performance parameters, marketing campaigns, trading periods, and more",
  events20240219ContentLabel7: "3. Multi-Step (Demo & Live) Environment:",
  events20240219Content7:
    "Experience a comprehensive multi-step environment that allows traders to transition seamlessly from demo to live trading",
  events20240219ContentLabel8:
    "Test and refine strategies in a risk-free demo environment before committing to live trading",
  events20240219Content8:
    "Launch the WordPress-based Prop Trading system in the shortest time and the most efficient way.",
  events20240219ContentLabel9: "4. Elevated Marketing Capabilities:",
  events20240219Content9:
    "Bring your platform to the market swiftly, streamline the go-to-market process and gain a competitive edge",
  events20240219ContentLabel10:
    "Utilise the diverse tools within WordPress to extend your online presence, reaching a broader audience and maximising your impact in the digital space",
  events20240219Content10: "5. Risk-Management Tools:",
  events20240219ContentLabel11:
    "Benefit from robust risk-management tools to ensure responsible and secure trading practices",
  events20240219Content11:
    "Set parameters such as minimum trading days, daily and total loss limits, profit targets, and registration fees to align with individual risk tolerance",
  events20240219ContentLabel12: "6. Advanced Challenge Analytics:",
  events20240219Content12:
    "Leverage advanced analytics tools to gain insights into challenge performance",
  events20240219ContentLabel13:
    "Monitor and track trading activity with precision to refine strategies and optimise results",
  events20240219Content13: "7. Full Ecosystem Support:",
  events20240219ContentLabel14:
    "Enjoy a fully supported ecosystem that provides the infrastructure for smooth monitoring and notification needs",
  events20240219Content14:
    "Customise different challenge tiers to cater to a diverse range of users, from those managing small allocated funds to high-net-worth portfolios",
  events20240219ContentLabel15:
    "Want to check out the system? Book a demo with us! ",
  events20240219Content15:
    "Discover more about TinyTrader's SaaS solutions by scheduling a call with our team. Learn how we can help you create new revenue streams and elevate your trading experience. Contact us today to embark on a journey of innovation and success with TinyTrader.",

  events20230589Title:
    "TinyTrader's Participation in Crypto 306: Unlocking Crypto's Potential",
  events20230589SubTitle:
    "We are thrilled to share the exciting news that TinyTrader is set to make its mark at the highly-anticipated Crypto 306 event in Dubai, UAE, taking place from March 8th to 9th.",
  events20230589SubTitleDetail:
    "We are thrilled to share the exciting news that TinyTrader is set to make its mark at the highly-anticipated Crypto 306 event in Dubai, UAE, taking place from March 8th to 9th. Our dedicated team will be on-site, showcasing our market-ready solutions and offering valuable insights to address any questions you may have.",
  events20230589ContentLabel1: "About Crypto 306",
  events20230589Content1:
    "Crypto 306 stands as the pinnacle of annual B2B/B2C networking events, serving as a vital connection point for the global crypto community. This eagerly awaited gathering unites newcomers, investors, and crypto industry stalwarts from across the globe. It serves as a platform for sharing ideas, discussing emerging trends, and presenting groundbreaking projects that promise to shape the future of crypto.",
  events20230589ContentLabel2: "About TinyTrader",
  events20230589Content2:
    "At TinyTrader, our unwavering commitment to delivering cutting-edge white label solutions is at the core of our mission. We empower a diverse spectrum of projects, spanning traditional finance, CFD brokerages, financial technology, and the crypto sector, to elevate their business operations. Our team embodies a unique blend of expertise and transparency, driven by an unwavering commitment to your success. We are well-prepared to share our technological prowess and knowledge to propel your achievements.",
  events20230589ContentLabel3: "Final Thoughts",
  events20230589Content3:
    "We extend a warm invitation to join us at Crypto 306 and connect with our experienced TinyTrader teams. Discover how we can help your company realize its full potential and explore our innovative product offerings. This is an opportunity not to be missed, so seize the moment and register today to be a part of Crypto 306. We eagerly await your presence at this transformative event.",

  events2023071922Title:
    "TinyTrader's Showcased Solutions at Sigma Asia Summit 2023 Sponsored Dinner",
  events2023071922SubTitle:
    "TinyTrader and HBC were proud to be part of AIBC Eurasia 2023, a groundbreaking global blockchain event taking place in Dubai!...",
  events2023071922SubTitleDetail:
    "TinyTrader and HBC were proud to be part of AIBC Eurasia 2023, a groundbreaking global blockchain event taking place in Dubai! The event was taking place on July 20, and it provided the perfect platform for our teams to connect with industry leaders and experts in the blockchain and crypto fields, as well as display our products and latest updates to new and old clients.",
  events2023071922ContentLabel1: "About AIBC Sigma Manila 2023",
  events2023071922Content1:
    "AIBC (Artificial Intelligence Blockchain and Cryptocurrency) is a globally recognized leader in technology events and media production. Their mission is to unite experts, investors, developers, and enthusiasts in AI, blockchain, and related fields. AIBC's international events offer unmatched networking and learning opportunities, connecting attendees with influential industry figures. AIBC SIGMA Manila 2023, held from July 19th to 22nd, underscored their commitment to fostering innovation and collaboration.",
  events2023071922ContentLabel2:
    "Award Granted - Blockchain Data Solution of the Year 2023",
  events2023071922Content2: `At AIBC SIGMA Manila, TinyTrader clinched the "Blockchain Data Solution of the Year 2023" award, affirming their innovation and excellence in the blockchain and cryptocurrency arena. This recognition solidifies their position as a pioneering force, revolutionizing data solutions in the industry.`,
  events2023071922ContentLabel3: "Final Words",
  events2023071922Content3: `AIBC SIGMA Manila 2023 witnessed TinyTrader's remarkable journey, from sponsoring a VIP dinner to being recognized as the "Blockchain Data Solution of the Year 2023." This award not only celebrates their contributions but also underscores the power of innovation, collaboration, and expertise. Such recognition propels both AIBC and TinyTrader forward, opening doors to new possibilities in the ever-evolving blockchain and fintech landscape.`,

  events2023072526Title: "TinyTrader's Impact at WebX 2023",
  events2023072526SubTitle: `On July 25-26, 2023, TinyTrader made a significant impression at the WebX Conference. They played a dual role as sponsors and hosts of the "Pioneer the Future of Web 3" side event, underscoring their commitment to tech advancement.`,
  events2023072526SubTitleDetail: `On July 25-26, 2023, TinyTrader made a significant impression at the WebX Conference. They played a dual role as sponsors and hosts of the "Pioneer the Future of Web 3" side event, underscoring their commitment to tech advancement. WebX, organized by CoinPost, is Asia's largest web3 conference, attracting global industry leaders, startups, investors, and experts. This two-day event at Tokyo International Forum aimed to accelerate global collaboration and position Japan as a Web3 hub in Asia, representing a crucial moment where technology and society intersect.`,
  events2023072526ContentLabel1: "About WebX",
  events2023072526Content1:
    "WebX, hosted by CoinPost, is Asia's premier web3 conference, uniting key industry players, startups, and experts from Japan and beyond. Taking place at the Tokyo International Forum, it serves as a vital platform for discussions on decentralized technologies like blockchain. By bridging the gap between Web3, Web2, and other sectors, WebX accelerates global collaboration and solidifies Japan's position in the Web3 domain.",
  events2023072526ContentLabel2: "Final Words",
  events2023072526Content2: `TinyTrader's sponsorship and the hosting of "Pioneer the Future of Web 3" at WebX Conference 2023 showcased their commitment to innovation. This presence underlines their dedication to empowering projects in traditional finance, CFD brokerages, fintech,and the crypto sector. As the world embraces the web3 era, TinyTrader's role at WebX affirms their influential position in shaping the technology landscape and its integration into society.`,

  events20230725Title: `TinyTrader at WebX and "Pioneer the Future of Web 3"`,
  events20230725SubTitle: `TinyTrader's commitment to innovation takes center stage in two pivotal events. They are proud sponsors of the WebX event and, concurrently, sponsors of a unique side event in Tokyo, "Pioneer the Future of Web 3," organized by TWIC on July 25.`,
  events20230725SubTitleDetail: "",
  events20230725ContentLabel1: "Introduction:",
  events20230725Content1: `TinyTrader's commitment to innovation takes center stage in two pivotal events. They are proud sponsors of the WebX event and, concurrently, sponsors of a unique side event in Tokyo, "Pioneer the Future of Web 3," organized by TWIC on July 25. This gathering brings together multiple Web 3 projects for project sharing and networking, providing a fertile ground for knowledge exchange and collaboration. With a mission centered on delivering cutting-edge white label solutions, TinyTrader's impact extends across traditional finance, CFD brokerages, fintech, and the crypto sector.`,
  events20230725ContentLabel2: `About "Pioneer the Future of Web 3`,
  events20230725Content2: `"Pioneer the Future of Web 3," organized by TWIC, gathers multiple Web 3 projects in Tokyo, offering a platform for sharing and networking, essential in shaping the future of technology.`,
  events20230725ContentLabel3: "Final Thoughts:",
  events20230725Content3: `TinyTrader's dual sponsorship, at both WebX and "Pioneer the Future of Web 3," is a testament to their commitment to innovation and collaboration. These events showcase their cutting-edge white label solutions that empower a diverse range of projects. As they continue to shape the technology and finance landscape, TinyTrader's participation amplifies opportunities and fosters the success of their clients.`,

  events20230903Title: `TinyTrader at "Exploration Unleashed": Navigating Real-World Assets in Web3`,
  events20230903SubTitle: `On September 3, 2023, TinyTrader participated in the "Exploration Unleashed" event in Seoul, organized by Tg Trade.`,
  events20230903SubTitleDetail: "",
  events20230903ContentLabel1: "Introduction:",
  events20230903Content1: `On September 3, 2023, TinyTrader participated in the "Exploration Unleashed" event in Seoul, organized by Tg Trade. This conference focused on the convergence of traditional assets and cutting-edge technologies, with a specific emphasis on tokenization and trading of Real-World Assets (RWAs) in the Web3 space. The event unveiled how blockchain integration redefines ownership and investment concepts, bridging the gap between digital and physical assets. TinyTrader's involvement in the panel discussion, "Unlocking the Potential of Web3 and RWAs — Adoption and Regulatory Considerations," brought together industry experts to explore challenges, opportunities, and regulatory aspects.`,
  events20230903ContentLabel2: `About "Exploration Unleashed`,
  events20230903Content2: `"Exploration Unleashed," organized by Tg Trade, is a conference exploring the intersection of traditional assets and innovative technologies, particularly the tokenization of RWAs in Web3.`,
  events20230903ContentLabel3: "Final Thoughts:",
  events20230903Content3: `TinyTrader's participation at "Exploration Unleashed'' reflects their commitment to innovation and collaboration. By showcasing their cutting-edge white label solutions, they empower a diverse range of projects. This event amplifies opportunities and fosters success, marking an exciting journey at the intersection of real-world assets and Web3. TinyTrader continues to shape the future of technology and finance, leading the way to a more open and transparent future.`,

  events20230907Title:
    "TinyTrader at Korean Blockchain Week After Party - Pioneer the Future of Web 3",
  events20230907SubTitle:
    "On September 7, 2023, Gangnam, Seoul took the spotlight, hosting an anticipated event that promised a unique networking experience.",
  events20230907SubTitleDetail: "",
  events20230907ContentLabel1: "Introduction:",
  events20230907Content1:
    "On September 7, 2023, Gangnam, Seoul took the spotlight, hosting an anticipated event that promised a unique networking experience. It unites visionary leaders, innovators, talent, and investors from the Korean blockchain landscape and beyond. This After Party is the pinnacle of Korean Blockchain Week and marks a pivotal moment in the Web 3 industry. In collaboration with partners like Ticker Capital, Alchemy Pay, Bittrex, Filecoin, and more, including TinyTrader, it's an exclusive opportunity to engage with the minds shaping the future of technology.",
  events20230907ContentLabel2: "About TinyTrader",
  events20230907Content2:
    "TinyTrader's mission revolves around delivering cutting-edge white label solutions, empowering projects in traditional finance, CFD brokerages, fintech, and the crypto sector. Their team combines expertise and transparency, driven by a commitment to their clients' success.",
  events20230907ContentLabel3: "Final Thoughts:",
  events20230907Content3:
    "TinyTrader's presence at the Korean Blockchain Week After Party underscores their commitment to innovation and collaboration. This event provides a platform for showcasing their cutting-edge white label solutions, amplifying opportunities, and fostering the success of their clients. It's an exciting gathering of industry pioneers, marking a significant moment in the journey toward the future of Web 3.",

  events20230912Title:
    "TinyTrader at Token 2049: Japan Meets Singapore (Side event)",
  events20230912SubTitle:
    "Token 2049, a highly anticipated event in the blockchain and cryptocurrency world, is about to get a new wave of energy.",
  events20230912SubTitleDetail: "",
  events20230912ContentLabel1: "Introduction:",
  events20230912Content1: `Token 2049, a highly anticipated event in the blockchain and cryptocurrency world, is about to get a new wave of energy. In partnership with industry leaders Teamz and TWIC, is hosting an exclusive side event, "Japan Meets Singapore," at Token 2049. With thought-provoking discussions, inspiring speeches, and unmatched networking opportunities featuring luminaries from Teamz, TWIC, DigiFinex and more, it's a treasure trove of insights into the ever-evolving world of Web3.`,
  events20230912ContentLabel2: "About Japan meet Singapore",
  events20230912Content2:
    "Teamz, and TWIC are committed to fostering collaboration and knowledge-sharing in the blockchain and cryptocurrency community. ",
  events20230912ContentLabel3: "Final Thoughts:",
  events20230912Content3: `DigiFinex's presence at Token 2049 and their exclusive side event, "Japan Meets Singapore," exemplify their dedication to innovation and collaboration in the dynamic blockchain and cryptocurrency landscape. This event underscores the transformative potential of Web3 and the commitment of these industry leaders to drive the field forward.`,

  events2023091314Title: "TinyTrader and Token 2049: Shaping the Future",
  events2023091314SubTitle:
    "TOKEN2049 is a renowned annual gathering that stands at the forefront of the blockchain and cryptocurrency industry.",
  events2023091314SubTitleDetail: "",
  events2023091314ContentLabel1: "About Token 2049",
  events2023091314Content1:
    "TOKEN2049 is a renowned annual gathering that stands at the forefront of the blockchain and cryptocurrency industry. As a premier event, it draws together a diverse and dynamic community of blockchain experts, investors, entrepreneurs, and enthusiasts from across the globe. With a focus on fostering collaboration and knowledge sharing, TOKEN2049 offers a platform to delve into the latest trends, innovations, and opportunities within the blockchain space. This influential conference features a curated selection of keynote presentations, panel discussions, workshops, and networking sessions, making it a vital nexus for staying informed about the evolving landscape of digital assets, decentralized finance, non-fungible tokens, and other cutting-edge blockchain technologies. For anyone seeking to navigate the ever-changing world of blockchain and cryptocurrencies, TOKEN2049 is a must-attend event, providing valuable insights and connections in this rapidly evolving ecosystem.",
  events2023091314ContentLabel2: "About TinyTrader ",
  events2023091314Content2:
    "At TinyTrader, our unwavering commitment to delivering cutting-edge white label solutions is at the core of our mission. We empower a diverse spectrum of projects,spanning traditional finance, CFD brokerages, financial technology, and the crypto sector, to elevate their business operations. Our team embodies a unique blend of expertise and transparency, driven by an unwavering commitment to your success. We are well-prepared to share our technological prowess and knowledge to propel your achievements.",
  events2023091314ContentLabel3: "Final Thoughts",
  events2023091314Content3:
    "In summary, TinyTrader's participation in TOKEN2049 heralds a transformative moment in blockchain and digital finance. This partnership promises to ignite innovation, collaboration, and insights within the industry. As we anticipate this premier event, it's evident that TinyTrader's expertise will drive the evolution of global financial services, offering attendees a unique opportunity to stay at the forefront of the blockchain landscape.",

  events20230915Title:
    "TT and OKX at Token 2049: Pioneering Liquidity Solutions",
  events20230915SubTitle:
    "Token 2049 Week in Singapore is set to host an exclusive private event where industry leaders and experts will convene to explore the future of liquidity solutions in the cryptocurrency market.",
  events20230915SubTitleDetail: "",
  events20230915ContentLabel1: "Introduction:",
  events20230915Content1:
    "Token 2049 Week in Singapore is set to host an exclusive private event where industry leaders and experts will convene to explore the future of liquidity solutions in the cryptocurrency market. TT and OKX jointly present this event, showcasing their collaborative efforts in delivering flexible spot liquidity solutions, a vital aspect of cryptocurrency trading. The event's agenda includes a keynote address by Viktor Filimonov, Product Manager at TinyTrader, who will shed light on the innovative approaches adopted by TT and OKX to empower liquidity within the trading infrastructure.",
  events20230915ContentLabel2: "About the Speaker:",
  events20230915Content2:
    "Viktor Filimonov, Product Manager at TinyTrader, brings extensive knowledge and experience to the realm of liquidity solutions. He will share insights into how TT and OKX are revolutionizing liquidity, providing seamless and flexible options for clients.",
  events20230915ContentLabel3: "Final Thoughts:",
  events20230915Content3:
    "TT and OKX's collaboration at Token 2049 reflects their commitment to advancing liquidity solutions in the cryptocurrency space. This exclusive event promises insightful discussions, expanded networks, and a glimpse into the future of liquidity solutions. It's an opportunity for attendees to connect with like-minded individuals and gain a deeper understanding of the dynamic cryptocurrency market. TT and OKX's dedication to innovation and collaboration sets the stage for a promising and flexible future in cryptocurrency trading.",

  events20230916Title: "TinyTrader's Presence at Wiki Finance Expo Sydney 2023",
  events20230916SubTitle:
    "Save the date for November 16, 2023, as TinyTrader steps into the spotlight at Wiki Finance Expo Sydney, a part of Wiki Expos illustrious global financial exhibition and fintech conference series.",
  events20230916SubTitleDetail: `CEO and co-founder Zo Liang will take the stage to deliver a keynote speech titled "Proprietary Trading Insights: How White Label Solutions Give You an Edge.`,
  events20230916ContentLabel1: "Keynote Speech by CEO Zo Liang",
  events20230916Content1: `CEO and co-founder Zo Liang will take the stage to deliver a keynote speech titled "Proprietary Trading Insights: How White Label Solutions Give You an Edge." This speech promises to offer valuable insights into the power of white label solutions in gaining a competitive edge in proprietary trading.`,
  events20230916ContentLabel2: "Final Words",
  events20230916Content2:
    "TinyTrader's presence at Wiki Finance Expo Sydney, complete with their booth at C1 and CEO Zo Liang's keynote speech, reaffirms their commitment to innovation and collaboration. This collaboration is set to accelerate opportunities and shape the future of financial technology and blockchain, all under the umbrella of TinyTrader's cutting-edge solutions and the wisdom shared by its co-founder.",

  eventsPhotosTitle: "We Can’t Wait To See You",
};

export default ko;
