const ko = {
  bannerTitle: "Liquidity Hub",
  bannerDesc:
    "The advanced centralized order-book aggregation established to enhance liquidity by smart-routing and connecting different sources of liquidity origination, along with proprietary order routing",
  ttLearnMore: "Find More",

  hubTitle: "What Is TT Liquidity Hub",
  hubDesc1:
    "Our Liquidity Hub enables retail brokers and digital assets trading companies deliver smooth trading experience to their customers",
  hubDesc2:
    "The Liquidity System provides sort of liquidity solutions across spot, futures and options markets.  Our Hub supplies smart-order routing facilitating full-market depth efficiency providing liquidity from different sources with price stability and risk-hedging features",
  hubTitle3: "Advanced",
  hubTitleDesc3: "Solution",
  hubTitle4: "Customer Support",
  hubTitleDesc4: "24/7",
  hubTitle5: "Connectivity",
  hubTitleDesc5: "REST / WS",

  coreTitle: "Core Components",
  coreTitle1: "Internal Liquidity Pool",
  coreTitleDesc1:
    "Internal proprietary matching system operating  with institutionals and retail-brokers  ",
  coreTitle2: "Liquidity Bridges",
  coreTitleDesc2: "Connectivity and Order routing between major exchanges",
  coreTitle3: "External Market-Making",
  coreTitleDesc3: "RestAPI and WebsocketAPI for external liquidity providers",
  coreTitle4: "Smart  Order Routing",
  coreTitleDesc4:
    "Proprietary order routing mechanism to provide the smart order execution",

  architectureTitle: "Liquidity Architecture",
  architectureDesc:
    "TT Hub Ecosystem is an interconnected liquidity community of Liquidity Providers and",
  architectureDesc1:
    "Client Instances, making available an unmatched level of liquidity and user experience for end users.",

  benefitsTitle: "Benefits",
  benefitsDesc: "Why set up Trading System with us?",
  benefitsTitle1: "Quick Setup",
  benefitsDesc1:
    "Established for clients in a short period of time upon specific business needs",
  benefitsTitle2: "Robustness",
  benefitsDesc2:
    "Stability and zero downtime architecture will allow to provide end customer reliable liquidity infrustructure",
  benefitsTitle3: "Flexibility",
  benefitsDesc3:
    "Provided different choices of liquidity solutions setup for the specific needs of the client",
};

export default ko;
