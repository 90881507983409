const zh = {
  news: "新闻",
  newsSubtitle: "资讯详情",
  news20230111Title: "红包功能惊喜上线！",
  news20230111SubTitle:
    "TinyTrader将于2023年1月11日上线全新的红包功能，欢迎各位新老用户参与体验。",
  news20230111Content1: "尊敬的各位TinyTrader用户：",
  news20230111Content2:
    "TinyTrader将于2023年1月11日上线全新的红包功能，欢迎各位新老用户参与体验（目前只支持APP端）。",
  news20230111Content3: "通过红包功能，您可以：",
  news20230111Content4:
    "1.将真U存入红包并且发送给您的好友（红包支持普通红包和拼手气红包）",
  news20230111Content5: "2.通过新人红包的方式，吸引新用户注册平台账户",
  news20230111Content6: "3.通过红包新注册的用户会自动成为您所邀请的用户",
  news20230111Content7:
    "注：红包的有效期是24小时，过期未被激活完成的红包将按原路退回发送者的账户中。",
  news20230111Content8: "TinyTrader",
  news20230111Content9: "2023年1月11日",
  news20230401Title: "TinyTrader 合约网格机器人重磅上线！",
  news20230401SubTitle:
    "合约网格交易是TinyTrader推出的一大最新功能，可帮助用户充分利用加密货币市场。它可在设定参数下自主工作，它帮助交易者从小幅价格波动中获利,尤其是在区间震荡市场。",
  news20230401Content1: "尊敬的各位TinyTrader用户：",
  news20230401Content2: "<b>要点：</b>",
  news20230401Content3:
    "• 网格交易是一种策略交易工具，可以在当前价格范围内按预设的时间间隔自动买卖期货合约。",
  news20230401Content4:
    "• 它帮助交易者从小幅价格波动中获利，尤其是在区间震荡市场。",
  news20230401Content5:
    "• 用户自行设置参数，只需一键下单，即可创建网格交易策略。",
  news20230401Content6: "• 持续提升商户交易量和交易手续费收入。",
  news20230401Content7:
    "TinyTrader合约网格交易基于永续合约，创建了易于操作的工具，允许用户自动买入和卖出订单。只需点击一下，即可从加密货币的日常波动中获利。",
  news20230401Content8:
    "合约网格交易是TinyTrader推出的一大最新功能，可帮助用户充分利用加密货币市场。它可在设定参数下自主工作，它帮助交易者从小幅价格波动中获利,尤其是在区间震荡市场。",
  news20230401Content9: "TinyTrader合约网格机器人有哪些优势？",
  news20230401Content10:
    "<b>全天候自动交易：</b>使用TinyTrader合约网格机器人，用户无需时刻跟踪价格走势。只需输入仓位信息，然后将一切交给机器人，坐享收益即可。",
  news20230401Content11:
    "<b>多/空/中性模式任选：</b>如果选择做多策略，机器人仅会进行多头开仓和平仓。如果选择做空策略，机器人仅会进行空头开仓和平仓。如果选择中性策略，机器人会在价格高于市价时进行空头开仓和平仓，并在价格低于市价时进行多头开仓和平仓。",
  news20230401Content12:
    "<b>利用杠杆放大收益：</b>利用杠杆进行交易，放大用户的仓位规模，赚取更高收益。",
  news20230401Content13:
    "<b>新手亦可轻松上手：</b>合约交易新手？没关系，TinyTrader合约网格机器人可助用户轻松交易，无需丰富经验。",
  news20230401Content14:
    "无论用户倾向于短线交易还是长线交易，无论是合约交易新手还是资深行家，网格机器人都能提供多重优势。快来使用TinyTrader合约网格机器人，开启全新交易体验。",
  news20230401Content15: "TinyTrader",
  news20230401Content16: "2023年4月1日",
  news20230505Title:
    "TinyTrader领军数字货币期权交易革新，SaaS产品首创，助您夺取市场先机！",
  news20230505SubTitle:
    "TinyTrader期权交易SaaS产品的革新性的交易方式和先进的技术将为投资者带来全新的交易体验和收益机会。现在我们邀请您加入我们，开启全新的交易之旅！",
  news20230505Content1:
    "您平台的投资者是否渴望更高的投资回报率？您平台的投资者是否厌倦了传统的交易方式并寻求更具挑战性的投资体验？现在，我们向您推荐一款全新的交易产品——TinyTrader期权交易SaaS产品！",
  news20230505Content2:
    "期权交易是一种创新性的金融工具，它允许投资者在事先确定的价格和时间范围内买入或卖出一种资产。相较于期货合约交易，期权交易提供更高的灵活性和更大的投资回报率。而我们的期权交易产品则是由TinyTrader专业金融团队精心打造，作为业内首创的期权交易SaaS产品，将引领数字货币期权交易革新！",
  news20230505Content3:
    "我们的期权交易SaaS产品采用最新的技术和创新性的解决方案，为投资者提供了一种全新的数字货币期权交易方式。这款产品拥有以下特点：",
  news20230505Content4:
    "- <b>快速夺取市场先机</b>：提供SaaS或集成SDK服务，共享期权市场深度，降低平台开展期权业务门槛，帮助平台快速抢占数字货币期权市场。",
  news20230505Content5:
    "- <b>灵活多样的交易策略</b>：提供多种不同类型的期权合约，用户可以根据自己的投资目标和风险偏好选择合适的合约，从而开拓交易机会，以小搏大，收益无限。",
  news20230505Content6:
    "- <b>独特的风控体系</b>：我们的风险控制体系，采用最先进的技术和策略来确保用户的资金安全。我们的系统会自动监控用户账户的交易和仓位，当发现异常情况时，会立即采取相应的措施，避免用户的资金受到损失。",
  news20230505Content7:
    "- <b>全新的用户交易体验</b>：基于欧式期权，采用先进的智能算法来实现全自动化的交易体验，将交易者从繁琐的交易过程中解放出来，投资者可以享受到极速的交易执行速度和无与伦比的交易流畅性，无论何时何地，都可以轻松愉快地进行交易。",
  news20230505Content8:
    "- <b>精准的价格发现机制</b>：标记价格由Black-Scholes模型决定，并且实时更新，能够准确反映市场的预期。在定价过程中，我们充分考虑市场报价等因素，提供一个公允价格作为期权标记价格，为用户的投资决策提供参考。",
  news20230505Content9:
    "- <b>全天侯的客户服务</b>：TinyTrader的客户服务团队由行业专业人员组成，能够为您提供优质的服务和支持。无论是交易操作、风险控制还是账户管理，我们都会为您提供最优解决方案。",
  news20230505Content10:
    "期权交易是一种富有挑战性和创新性的交易方式，可以帮助投资者实现更高的投资回报率和更精准的交易决策。 我们相信，TinyTrader期权交易SaaS产品的革新性的交易方式和先进的技术将为投资者带来全新的交易体验和收益机会。现在我们邀请您加入我们，开启全新的交易之旅！",
  news20230505Content11: "尊敬的各位TinyTrader用户：",
  news20230505Content12: "TinyTrader",
  news20230505Content13: "2023年5月5日",
  news20230619Title: "深化创新与协作：TinyTrader OpenAPI正式上线！",
  news20230619SubTitle:
    "随着OpenAPI的发布，我们将为用户带来更大的灵活性和整合性，助力用户在数字资产交易中取得成功。",
  news20230619Content1:
    "我们很高兴地宣布，TinyTrader的OpenAPI已正式上线！这一强大的工具将为您带来更多机会和收益，助您在数字资产市场中取得领先地位。",
  news20230619Content2:
    "作为全球领先的SaaS数字货币交易服务商，TinyTrader致力于为机构交易者提供安全、高效和创新的数字资产交易解决方案。我们不断努力提升用户体验，推出新产品和功能，以满足不断变化的市场需求。而现在，随着OpenAPI的发布，我们将为用户带来更大的灵活性和整合性，助力用户在数字资产交易中取得成功。",
  news20230619Content3:
    "TinyTrader的OpenAPI产品拥有丰富的功能和数据，满足您的交易需求。以下是该产品的主要特点：",
  news20230619Content4:
    "• <b>交易能力开放</b>: 通过OpenAPI，您可以执行各种交易操作，包括下单、撤销订单、查询交易历史等。借助API接口，您能够实现自动化交易和个性化交易策略，根据自己的需求驾驭市场。",
  news20230619Content5:
    "• <b>实时市场数据</b>: 获取最新的市场行情、交易对价格、深度数据等实时信息。这些数据将助您做出明智的交易决策，并根据市场变化及时调整策略。",
  news20230619Content6:
    "• <b>全面的账户管理</b>: OpenAPI产品提供完善的账户管理功能，包括查询账户余额和交易历史等。更轻松地管理您的数字资产和交易活动，掌握全局。",
  news20230619Content7:
    "• <b>安全保障</b>: TinyTrader提供安全可靠的API认证和访问控制机制，确保用户数据和资金的安全性。我们采用先进的加密技术和身份验证措施，为您的交易保驾护航。",
  news20230619Content8:
    "• <b>360度服务支持</b>: 我们提供详尽的API文档、示例代码和技术支持，帮助您快速上手并解决遇到的问题。",
  news20230619Content9:
    "• <b>做市商服务</b>: 通过 OpenAPI提供全面的做市商服务，更优费率，更低延迟，帮助您充分发挥市场影响力和流动性管理能力，实时监测市场并灵活调整策略，以提升交易效率、管理风险并获取更可观的收益。",
  news20230619Content10:
    "• <b>经纪商服务</b>: 通过OpenAPI提供一流的市场深度，与一线交易所共享现货市场和衍生品市场的深度和流动性，提供高额的交易佣金返利和灵活的费率加点等方案。",
  news20230619Content11:
    "借助TinyTrader的OpenAPI产品，您和开发者可以深入整合我们的交易平台，开发定制化的交易工具、策略和应用程序。我们鼓励您与合作伙伴积极探索这一创新产品，共同推动数字资产交易行业的发展。",
  news20230619Content12:
    "点击此处了解更多关于TinyTrader OpenAPI的信息，并开启您的数字资产交易之旅：<a>TinyTrader OpenAPI</a>",
  news20230619Content13: "",
  news20230619Content14: "TinyTrader",
  news20230619Content15: "2023年6月19日",
  news20230815Title:
    "TinyTrader正式推出全新的现货交易和UI 2.0版本迭代，致力于为用户带来更加卓越的交易体验！",
  news20230815SubTitle:
    "TinyTrader正式推出全新的现货交易和UI 2.0版本迭代，致力于为用户带来更加卓越的交易体验。在不断追求创新与卓越的过程中，我们将为用户提供更强大、更便捷、更安全的功能，助力用户在数字货币市场取得更大的成功。",
  news20230815Content1:
    "• <b>全新UI 2.0，焕然一新</b>: 崭新的UI 2.0版本带来焕然一新的界面设计，强调优化布局和视觉效果，让用户在交易过程中感受更直观、更舒适的交易体验。界面的全面升级旨在提高用户的交易感知，助用户更迅速地获取市场信息，做出明智决策。",
  news20230815Content2:
    "• <b>实时行情，全面信息</b>: 现货交易市场为用户提供实时行情和全面的市场信息，助力用户做出明智的交易决策。我们的图表工具和技术指标将协助用户更精准地分析市场趋势，为用户的交易决策提供有力支持。",
  news20230815Content3:
    "• <b>无限选择，多样交易</b>: 现货交易市场为用户带来更多的数字货币交易对，TT向商户提供定制化上币服务，满足用户的多样投资需求。无论用户是新手还是经验丰富的交易者，都能找到最适合用户的交易对，轻松捕捉市场机会。",
  news20230815Content4:
    "• <b>安全可靠，资产保障</b>: 用户的安全始终是我们的首要任务。在本次版本迭代中，我们进一步加强了安全防护体系，采用最新的加密技术和多重验证机制，以确保用户的资产和交易信息得到最大程度的保护。",
  news20230815Content5:
    "• <b>用户体验，持续优化</b>: TinyTrader一直致力于为用户提供卓越的交易体验。我们将持续倾听用户的反馈，不断在后续版本迭代中优化和改进功能，以满足不断增长的用户需求。",
  news20230815Content6: "",
  news20230815Content7:
    "这一次重要的版本迭代标志着TinyTrader在技术创新和用户体验方面的新里程碑。我们深信，通过这次迭代，用户将更轻松、高效地参与数字货币市场。",
  news20230815Content8:
    "最后，衷心感谢您一直以来的支持。您的信任是我们不断进步的动力！",
  news20230815Content9: "",
  news20230815Content10: "TinyTrader团队",
  news20230815Content11: "2023年8月15日",

  homeNewsReadMore: "Read More",
  homeNewsTitle: "In the news",
  homeNewsDesc:
    "We Proudly Unveils Its Latest Advancements In Derivatives  Technologies. As We Continue To Set Industry Standards, We Invite You To Explore The Innovations That Are Redefining Our Platform's Capabilities",

  // 新版英文官网
  ttNewsTitle: "Today News",
  ttNewsDesc:
    "We Proudly Unveils Its Latest Advancements In White-Label Digital Assets Trading Technologies. As We Continue To Set Industry Standards, We Invite You To Explore The Innovations That Are Redefining Our Platform's Capabilities",
  ttNewsView: "View Recent Tiny News",

  newsViewTitle: "View Recent Tiny News",
  newsReadTitle: "Read Also",
  eventViewsTitle: "View Recent Tiny events",
  eventWatchTitle: "Watch Also",
  publicationsTitle: "Publications",
  publicationsDesc:
    "A library of informative presentations and video about our products and industry-related issues. Browse through our extensive range of subject matter and get educated.",

  // events
  eventsTitle: "TinyTrader's VIP After Party at Asia Blockchain Summit 2024: Iconic Web3 Expo In Taiwan",
  eventsDesc: `As an after-party of the Asia Blockchain Summit, this event brings together Web3 founders, investors, crypto exchanges, industry builders and crypto enthusiasts to network and build meaningful connections.`,
  eventsBannerBtn: "Watch Now",
  eventsListTitle: "Here’s What We Announced.",
  eventsItemBtn: "Learn More",

  educationTitle: "Education",
  educationDesc:
    "We Proudly Unveils Its Latest Advancements In White-Label Digital Assets Trading Technologies. As We Continue To Set Industry Standards, We Invite You To Explore The Innovations That Are Redefining Our Platform's Capabilities",
  educationBtn: "View More",
  educationPresentations: "Presentations",
  educationPresentationsDesc:
    "A library of informative presentations and video about our products and industry-related issues. Browse through our extensive range of subject matter and get educated.",
  educationItemsTitle1: "How Crypto Payment Processing Works",
  educationItemsDesc1:
    "In this video, Arthur Azizov, CEO and Founder of the TinyTrader Group, explains the basics of crypto payment processing and how businesses can benefit from using a crypto payment solution like B2BinPay. He dives into the details of the processing infrastructure, current trends in crypto payments, differences between USDT and USDC stablecoins, and more.",
  educationPublications1:
    "We Proudly Unveils Its Latest Advancements In White-Label Digital Assets Trading Technologies. As We Continue To Set Industry Standards, We Invite You To Explore The Innovations That Are Redefining Our Platform's Capabilities",

  marketplaceTitle: "TT Marketplace",
  marketplaceDesc:
    "Ideal venue for businesses seeking marketing collaborations, co-marketing activities, integrations, and revenue-sharing opportunities. With a diverse community of companies, we offer an open and dynamic environment for strategic partnerships. By harnessing the power of synergistic alliances, businesses can expand their reach and access TT’s extensive customer base, which continues to grow exponentially.",
  marketplaceBtn: "Find More",
  marketplaceTitle1: "Welcome to TinyTrader​",
  marketplaceSubtitle: "Empowering Digital Trading Systems Beyond Borders​",
  marketplaceDesc1:
    "TinyTrader is a fintech Software-as-a-Service (SaaS) company specializing in developing and providing technological and liquidity solutions. Our primary focus is to develop and deliver innovative, secure, scalable and robust trading infrastructure, ecosystem applications, and services to businesses and organizations.​",
  marketplaceTitle2: "Mission​",
  marketplaceDesc2:
    "To foster collaboration and create a dynamic ecosystem where businesses can flourish together.​",
  marketplaceTitle3: "Vision",
  marketplaceDesc3:
    "Establish a collaborative and flourishing business community, where companies unite to enhance each other’s operations through cooperation and mutual support.​",
  marketplaceCoreTitle: "Why Join Our Marketplace?​",
  marketplaceCoreDesc:
    "What sets the TT Marketplace apart is its emphasis on cooperation and mutual benefits. By coming together, companies can better achieve more significant milestones and drive innovation to new heights.​",
  marketplaceCoreTitle1: "Affiliate Marketing​",
  marketplaceCoreDesc1:
    "Partner in our affiliate marketing model to promote your product offerings to a broader audience.​",
  marketplaceCoreTitle2: "Co-marketing",
  marketplaceCoreDesc2:
    "Jointly create and promote marketing campaigns,leveraging combined resources for increased brand exposure and mutual growth.",
  marketplaceCoreTitle3: "Revenue Sharing",
  marketplaceCoreDesc3:
    "Collaborate by sharing profits based on contributions, thus fostering mutual success through aligned interests and joint efforts.",
  marketplaceCoreTitle4: "Integrations",
  marketplaceCoreDesc4:
    "Spur innovation by joining the App Partner, to create collaboratively designed product and expand market reach.",
  marketplacePartnerTitle: "Featured Partners",
  marketplacePartnerDesc:
    "TinyTrader has partnered with a broad range of companies across different business segments.",
  marketplacePartnerBtn: "Join Us",

  // 专题列表-详情
  bookADemo: "Book A Demo",
  events20240419Title: "TinyTrader at Token 2049 Dubai: after Party Side Event",
  events20240419SubTitle:
    "We are thrilled to share the exciting news that TinyTrader is set to make its mark at the highly-anticipated Token2049 conference in Dubai, UAE, taking place from April 18th to 19th.",
  events20240419DetailTitle:
    "TinyTrader's VIP After Party at Token2049: Embracing Crypto's Shining Future",
  events20240419SubTitleDetail:
    "As an after-party of the Dubai Crypto Week, this event brings together Web3 founders, investors, tier 1 exchanges, and industry builders to foster collaboration, share insights, and forge meaningful connections.",
  events20240419ContentLabel1: "Event Details",
  events20240419Content1_1:
    "As the sun sets on Dubai Crypto Week, we gathering top visionaries, innovators, and pioneers in the crypto space. It won't just be a party; it'll be a celebration of the future of finance and the incredible journey of the crypto industry.",
  events20240419Content1_2:
    "An evening of unparalleled networking opportunities awaits, set against the iconic skyline of Dubai and the majestic Burj Khalifa. We're bringing together Web3 founders, investors, tier 1 exchanges, and industry builders to share insights and forge connections that are bound to shape the future of the industry.",
  events20240419Content1_3:
    "But it won't be just business talk all night! Our guests will enjoy premium drinks, delectable canapes, and the chance to participate in exciting Lucky Draw Games, with exclusive gifts up for grabs. Get ready for an event that promises to be nothing short of extraordinary!",
  events20240419Content1_4:
    "Join us as we raise our glasses to toast to innovation, collaboration, and the limitless possibilities that lie ahead. Together, let's make this night one to remember, setting the stage for a future where the only constant is change, and the potential for greatness knows no bounds.",
  events20240419ContentLabel2: "About TinyTrader",
  events20240419Content2_1:
    "TinyTrader (TT) is an award-winning white-label trading technology and liquidity solutions provider. With over 50+ Institutional Clients, 100+ Employees, 10+ Countries, and $5B+ Volume, we connect digital-asset trading between fiat and digital currencies for global trading platforms, brokers, developers, and institutions.",
  events20240419Content2_2:
    "At TinyTrader, our unwavering commitment to delivering cutting-edge white-label solutions is at the core of our mission. We empower a diverse spectrum of projects, spanning traditional finance, CFD brokerages, financial technology, and the crypto sector, to elevate their business operations. Our team embodies a unique blend of expertise and transparency, driven by an unwavering commitment to your success. We are well-prepared to share our technological prowess and knowledge to propel your achievements.",
  events20240419ContentLabel3: "About Token2049",
  events20240419Content3:
    "TOKEN2049 is a global conference series, where the crypto ecosystem's decision-makers connect to exchange ideas, network, and shape the industry. TOKEN2049 is the pre-eminent meeting place for entrepreneurs, institutions, industry insiders, investors, builders, and those with a strong interest in the crypto and blockchain industry.",

  events20240219Title:
    "TinyTrader launches a revolutionary proprietary trading solution that signifies our ongoing commitment to innovation in the rapidly evolving world of digital assets.",
  events20240219SubTitle:
    "TinyTrader is excited to announce the launch of its newest product, Prop Trading, a revolutionary proprietary trading solution that signifies our ongoing commitment to innovation in the rapidly evolving world of digital assets.",
  events20240219SubTitleDetail:
    "A challenging program that allows users to earn higher profits by leveraging provided funds",
  events20240219ContentLabel1:
    "Future Generation White-Label Proprietary Trading Solution Release",
  events20240219Content1:
    "TinyTrader is excited to announce the launch of its newest product, Prop Trading, a revolutionary proprietary trading solution that signifies our ongoing commitment to innovation in the rapidly evolving world of digital assets.",
  events20240219ContentLabel2:
    "Tailored for both new and experienced traders, the platform provides an opportunity for users to learn and engage in challenges with minimised financial risks and enhanced potential profits.",
  events20240219Content2: "What is Prop Trading in detail?",
  events20240219ContentLabel3:
    "Prop Trading, short for proprietary trading, is a next-generation solution that empowers traders by providing access to firm capital. Backed by cutting-edge challenges provisioning and performance tracking tools, TinyTrader's Prop Trading is designed to revolutionize the way traders engage with the market.",
  events20240219Content3: "7 Key Features:",
  events20240219ContentLabel4:
    "1. Fully Customizable Platform Based on WordPress:",
  events20240219Content4:
    "Experience complete flexibility with the theme design, manage content through the CMS and enhance your system with a wide range of WordPress tools",
  events20240219ContentLabel5:
    "Guarantee swift speed and seamless responsiveness to provide users with an optimal experience",
  events20240219Content5: "2. Flexible Challenge Setup Back-Office:",
  events20240219ContentLabel6:
    "Customise the challenge setup with a user-friendly back-office interface",
  events20240219Content6:
    "Tailor challenges based on specific criteria, including the number of steps, performance parameters, marketing campaigns, trading periods, and more",
  events20240219ContentLabel7: "3. Multi-Step (Demo & Live) Environment:",
  events20240219Content7:
    "Experience a comprehensive multi-step environment that allows traders to transition seamlessly from demo to live trading",
  events20240219ContentLabel8:
    "Test and refine strategies in a risk-free demo environment before committing to live trading",
  events20240219Content8:
    "Launch the WordPress-based Prop Trading system in the shortest time and the most efficient way.",
  events20240219ContentLabel9: "4. Elevated Marketing Capabilities:",
  events20240219Content9:
    "Bring your platform to the market swiftly, streamline the go-to-market process and gain a competitive edge",
  events20240219ContentLabel10:
    "Utilise the diverse tools within WordPress to extend your online presence, reaching a broader audience and maximising your impact in the digital space",
  events20240219Content10: "5. Risk-Management Tools:",
  events20240219ContentLabel11:
    "Benefit from robust risk-management tools to ensure responsible and secure trading practices",
  events20240219Content11:
    "Set parameters such as minimum trading days, daily and total loss limits, profit targets, and registration fees to align with individual risk tolerance",
  events20240219ContentLabel12: "6. Advanced Challenge Analytics:",
  events20240219Content12:
    "Leverage advanced analytics tools to gain insights into challenge performance",
  events20240219ContentLabel13:
    "Monitor and track trading activity with precision to refine strategies and optimise results",
  events20240219Content13: "7. Full Ecosystem Support:",
  events20240219ContentLabel14:
    "Enjoy a fully supported ecosystem that provides the infrastructure for smooth monitoring and notification needs",
  events20240219Content14:
    "Customise different challenge tiers to cater to a diverse range of users, from those managing small allocated funds to high-net-worth portfolios",
  events20240219ContentLabel15:
    "Want to check out the system? Book a demo with us! ",
  events20240219Content15:
    "Discover more about TinyTrader's SaaS solutions by scheduling a call with our team. Learn how we can help you create new revenue streams and elevate your trading experience. Contact us today to embark on a journey of innovation and success with TinyTrader.",

  events20230589Title:
    "TinyTrader's Participation in Crypto 306: Unlocking Crypto's Potential",
  events20230589SubTitle:
    "We are thrilled to share the exciting news that TinyTrader is set to make its mark at the highly-anticipated Crypto 306 event in Dubai, UAE, taking place from March 8th to 9th.",
  events20230589SubTitleDetail:
    "We are thrilled to share the exciting news that TinyTrader is set to make its mark at the highly-anticipated Crypto 306 event in Dubai, UAE, taking place from March 8th to 9th. Our dedicated team will be on-site, showcasing our market-ready solutions and offering valuable insights to address any questions you may have.",
  events20230589ContentLabel1: "About Crypto 306",
  events20230589Content1:
    "Crypto 306 stands as the pinnacle of annual B2B/B2C networking events, serving as a vital connection point for the global crypto community. This eagerly awaited gathering unites newcomers, investors, and crypto industry stalwarts from across the globe. It serves as a platform for sharing ideas, discussing emerging trends, and presenting groundbreaking projects that promise to shape the future of crypto.",
  events20230589ContentLabel2: "About TinyTrader",
  events20230589Content2:
    "At TinyTrader, our unwavering commitment to delivering cutting-edge white label solutions is at the core of our mission. We empower a diverse spectrum of projects, spanning traditional finance, CFD brokerages, financial technology, and the crypto sector, to elevate their business operations. Our team embodies a unique blend of expertise and transparency, driven by an unwavering commitment to your success. We are well-prepared to share our technological prowess and knowledge to propel your achievements.",
  events20230589ContentLabel3: "Final Thoughts",
  events20230589Content3:
    "We extend a warm invitation to join us at Crypto 306 and connect with our experienced TinyTrader teams. Discover how we can help your company realize its full potential and explore our innovative product offerings. This is an opportunity not to be missed, so seize the moment and register today to be a part of Crypto 306. We eagerly await your presence at this transformative event.",

  events2023071922Title:
    "TinyTrader's Showcased Solutions at Sigma Asia Summit 2023 Sponsored Dinner",
  events2023071922SubTitle:
    "TinyTrader and HBC were proud to be part of AIBC Eurasia 2023, a groundbreaking global blockchain event taking place in Dubai!...",
  events2023071922SubTitleDetail:
    "TinyTrader and HBC were proud to be part of AIBC Eurasia 2023, a groundbreaking global blockchain event taking place in Dubai! The event was taking place on July 20, and it provided the perfect platform for our teams to connect with industry leaders and experts in the blockchain and crypto fields, as well as display our products and latest updates to new and old clients.",
  events2023071922ContentLabel1: "About AIBC Sigma Manila 2023",
  events2023071922Content1:
    "AIBC (Artificial Intelligence Blockchain and Cryptocurrency) is a globally recognized leader in technology events and media production. Their mission is to unite experts, investors, developers, and enthusiasts in AI, blockchain, and related fields. AIBC's international events offer unmatched networking and learning opportunities, connecting attendees with influential industry figures. AIBC SIGMA Manila 2023, held from July 19th to 22nd, underscored their commitment to fostering innovation and collaboration.",
  events2023071922ContentLabel2:
    "Award Granted - Blockchain Data Solution of the Year 2023",
  events2023071922Content2: `At AIBC SIGMA Manila, TinyTrader clinched the "Blockchain Data Solution of the Year 2023" award, affirming their innovation and excellence in the blockchain and cryptocurrency arena. This recognition solidifies their position as a pioneering force, revolutionizing data solutions in the industry.`,
  events2023071922ContentLabel3: "Final Words",
  events2023071922Content3: `AIBC SIGMA Manila 2023 witnessed TinyTrader's remarkable journey, from sponsoring a VIP dinner to being recognized as the "Blockchain Data Solution of the Year 2023." This award not only celebrates their contributions but also underscores the power of innovation, collaboration, and expertise. Such recognition propels both AIBC and TinyTrader forward, opening doors to new possibilities in the ever-evolving blockchain and fintech landscape.`,

  events2023072526Title: "TinyTrader's Impact at WebX 2023",
  events2023072526SubTitle: `On July 25-26, 2023, TinyTrader made a significant impression at the WebX Conference. They played a dual role as sponsors and hosts of the "Pioneer the Future of Web 3" side event, underscoring their commitment to tech advancement.`,
  events2023072526SubTitleDetail: `On July 25-26, 2023, TinyTrader made a significant impression at the WebX Conference. They played a dual role as sponsors and hosts of the "Pioneer the Future of Web 3" side event, underscoring their commitment to tech advancement. WebX, organized by CoinPost, is Asia's largest web3 conference, attracting global industry leaders, startups, investors, and experts. This two-day event at Tokyo International Forum aimed to accelerate global collaboration and position Japan as a Web3 hub in Asia, representing a crucial moment where technology and society intersect.`,
  events2023072526ContentLabel1: "About WebX",
  events2023072526Content1:
    "WebX, hosted by CoinPost, is Asia's premier web3 conference, uniting key industry players, startups, and experts from Japan and beyond. Taking place at the Tokyo International Forum, it serves as a vital platform for discussions on decentralized technologies like blockchain. By bridging the gap between Web3, Web2, and other sectors, WebX accelerates global collaboration and solidifies Japan's position in the Web3 domain.",
  events2023072526ContentLabel2: "Final Words",
  events2023072526Content2: `TinyTrader's sponsorship and the hosting of "Pioneer the Future of Web 3" at WebX Conference 2023 showcased their commitment to innovation. This presence underlines their dedication to empowering projects in traditional finance, CFD brokerages, fintech,and the crypto sector. As the world embraces the web3 era, TinyTrader's role at WebX affirms their influential position in shaping the technology landscape and its integration into society.`,

  events20230725Title: `TinyTrader at WebX and "Pioneer the Future of Web 3"`,
  events20230725SubTitle: `TinyTrader's commitment to innovation takes center stage in two pivotal events. They are proud sponsors of the WebX event and, concurrently, sponsors of a unique side event in Tokyo, "Pioneer the Future of Web 3," organized by TWIC on July 25.`,
  events20230725SubTitleDetail: "",
  events20230725ContentLabel1: "Introduction:",
  events20230725Content1: `TinyTrader's commitment to innovation takes center stage in two pivotal events. They are proud sponsors of the WebX event and, concurrently, sponsors of a unique side event in Tokyo, "Pioneer the Future of Web 3," organized by TWIC on July 25. This gathering brings together multiple Web 3 projects for project sharing and networking, providing a fertile ground for knowledge exchange and collaboration. With a mission centered on delivering cutting-edge white label solutions, TinyTrader's impact extends across traditional finance, CFD brokerages, fintech, and the crypto sector.`,
  events20230725ContentLabel2: `About "Pioneer the Future of Web 3`,
  events20230725Content2: `"Pioneer the Future of Web 3," organized by TWIC, gathers multiple Web 3 projects in Tokyo, offering a platform for sharing and networking, essential in shaping the future of technology.`,
  events20230725ContentLabel3: "Final Thoughts:",
  events20230725Content3: `TinyTrader's dual sponsorship, at both WebX and "Pioneer the Future of Web 3," is a testament to their commitment to innovation and collaboration. These events showcase their cutting-edge white label solutions that empower a diverse range of projects. As they continue to shape the technology and finance landscape, TinyTrader's participation amplifies opportunities and fosters the success of their clients.`,

  events20230903Title: `TinyTrader at "Exploration Unleashed": Navigating Real-World Assets in Web3`,
  events20230903SubTitle: `On September 3, 2023, TinyTrader participated in the "Exploration Unleashed" event in Seoul, organized by Tg Trade.`,
  events20230903SubTitleDetail: "",
  events20230903ContentLabel1: "Introduction:",
  events20230903Content1: `On September 3, 2023, TinyTrader participated in the "Exploration Unleashed" event in Seoul, organized by Tg Trade. This conference focused on the convergence of traditional assets and cutting-edge technologies, with a specific emphasis on tokenization and trading of Real-World Assets (RWAs) in the Web3 space. The event unveiled how blockchain integration redefines ownership and investment concepts, bridging the gap between digital and physical assets. TinyTrader's involvement in the panel discussion, "Unlocking the Potential of Web3 and RWAs — Adoption and Regulatory Considerations," brought together industry experts to explore challenges, opportunities, and regulatory aspects.`,
  events20230903ContentLabel2: `About "Exploration Unleashed`,
  events20230903Content2: `"Exploration Unleashed," organized by Tg Trade, is a conference exploring the intersection of traditional assets and innovative technologies, particularly the tokenization of RWAs in Web3.`,
  events20230903ContentLabel3: "Final Thoughts:",
  events20230903Content3: `TinyTrader's participation at "Exploration Unleashed'' reflects their commitment to innovation and collaboration. By showcasing their cutting-edge white label solutions, they empower a diverse range of projects. This event amplifies opportunities and fosters success, marking an exciting journey at the intersection of real-world assets and Web3. TinyTrader continues to shape the future of technology and finance, leading the way to a more open and transparent future.`,

  events20230907Title:
    "TinyTrader at Korean Blockchain Week After Party - Pioneer the Future of Web 3",
  events20230907SubTitle:
    "On September 7, 2023, Gangnam, Seoul took the spotlight, hosting an anticipated event that promised a unique networking experience.",
  events20230907SubTitleDetail: "",
  events20230907ContentLabel1: "Introduction:",
  events20230907Content1:
    "On September 7, 2023, Gangnam, Seoul took the spotlight, hosting an anticipated event that promised a unique networking experience. It unites visionary leaders, innovators, talent, and investors from the Korean blockchain landscape and beyond. This After Party is the pinnacle of Korean Blockchain Week and marks a pivotal moment in the Web 3 industry. In collaboration with partners like Ticker Capital, Alchemy Pay, Bittrex, Filecoin, and more, including TinyTrader, it's an exclusive opportunity to engage with the minds shaping the future of technology.",
  events20230907ContentLabel2: "About TinyTrader",
  events20230907Content2:
    "TinyTrader's mission revolves around delivering cutting-edge white label solutions, empowering projects in traditional finance, CFD brokerages, fintech, and the crypto sector. Their team combines expertise and transparency, driven by a commitment to their clients' success.",
  events20230907ContentLabel3: "Final Thoughts:",
  events20230907Content3:
    "TinyTrader's presence at the Korean Blockchain Week After Party underscores their commitment to innovation and collaboration. This event provides a platform for showcasing their cutting-edge white label solutions, amplifying opportunities, and fostering the success of their clients. It's an exciting gathering of industry pioneers, marking a significant moment in the journey toward the future of Web 3.",

  events20230912Title:
    "TinyTrader at Token 2049: Japan Meets Singapore (Side event)",
  events20230912SubTitle:
    "Token 2049, a highly anticipated event in the blockchain and cryptocurrency world, is about to get a new wave of energy.",
  events20230912SubTitleDetail: "",
  events20230912ContentLabel1: "Introduction:",
  events20230912Content1: `Token 2049, a highly anticipated event in the blockchain and cryptocurrency world, is about to get a new wave of energy. In partnership with industry leaders Teamz and TWIC, is hosting an exclusive side event, "Japan Meets Singapore," at Token 2049. With thought-provoking discussions, inspiring speeches, and unmatched networking opportunities featuring luminaries from Teamz, TWIC, DigiFinex and more, it's a treasure trove of insights into the ever-evolving world of Web3.`,
  events20230912ContentLabel2: "About Japan meet Singapore",
  events20230912Content2:
    "Teamz, and TWIC are committed to fostering collaboration and knowledge-sharing in the blockchain and cryptocurrency community. ",
  events20230912ContentLabel3: "Final Thoughts:",
  events20230912Content3: `DigiFinex's presence at Token 2049 and their exclusive side event, "Japan Meets Singapore," exemplify their dedication to innovation and collaboration in the dynamic blockchain and cryptocurrency landscape. This event underscores the transformative potential of Web3 and the commitment of these industry leaders to drive the field forward.`,

  events2023091314Title: "TinyTrader and Token 2049: Shaping the Future",
  events2023091314SubTitle:
    "TOKEN2049 is a renowned annual gathering that stands at the forefront of the blockchain and cryptocurrency industry.",
  events2023091314SubTitleDetail: "",
  events2023091314ContentLabel1: "About Token 2049",
  events2023091314Content1:
    "TOKEN2049 is a renowned annual gathering that stands at the forefront of the blockchain and cryptocurrency industry. As a premier event, it draws together a diverse and dynamic community of blockchain experts, investors, entrepreneurs, and enthusiasts from across the globe. With a focus on fostering collaboration and knowledge sharing, TOKEN2049 offers a platform to delve into the latest trends, innovations, and opportunities within the blockchain space. This influential conference features a curated selection of keynote presentations, panel discussions, workshops, and networking sessions, making it a vital nexus for staying informed about the evolving landscape of digital assets, decentralized finance, non-fungible tokens, and other cutting-edge blockchain technologies. For anyone seeking to navigate the ever-changing world of blockchain and cryptocurrencies, TOKEN2049 is a must-attend event, providing valuable insights and connections in this rapidly evolving ecosystem.",
  events2023091314ContentLabel2: "About TinyTrader ",
  events2023091314Content2:
    "At TinyTrader, our unwavering commitment to delivering cutting-edge white label solutions is at the core of our mission. We empower a diverse spectrum of projects,spanning traditional finance, CFD brokerages, financial technology, and the crypto sector, to elevate their business operations. Our team embodies a unique blend of expertise and transparency, driven by an unwavering commitment to your success. We are well-prepared to share our technological prowess and knowledge to propel your achievements.",
  events2023091314ContentLabel3: "Final Thoughts",
  events2023091314Content3:
    "In summary, TinyTrader's participation in TOKEN2049 heralds a transformative moment in blockchain and digital finance. This partnership promises to ignite innovation, collaboration, and insights within the industry. As we anticipate this premier event, it's evident that TinyTrader's expertise will drive the evolution of global financial services, offering attendees a unique opportunity to stay at the forefront of the blockchain landscape.",

  events20230915Title:
    "TT and OKX at Token 2049: Pioneering Liquidity Solutions",
  events20230915SubTitle:
    "Token 2049 Week in Singapore is set to host an exclusive private event where industry leaders and experts will convene to explore the future of liquidity solutions in the cryptocurrency market.",
  events20230915SubTitleDetail: "",
  events20230915ContentLabel1: "Introduction:",
  events20230915Content1:
    "Token 2049 Week in Singapore is set to host an exclusive private event where industry leaders and experts will convene to explore the future of liquidity solutions in the cryptocurrency market. TT and OKX jointly present this event, showcasing their collaborative efforts in delivering flexible spot liquidity solutions, a vital aspect of cryptocurrency trading. The event's agenda includes a keynote address by Viktor Filimonov, Product Manager at TinyTrader, who will shed light on the innovative approaches adopted by TT and OKX to empower liquidity within the trading infrastructure.",
  events20230915ContentLabel2: "About the Speaker:",
  events20230915Content2:
    "Viktor Filimonov, Product Manager at TinyTrader, brings extensive knowledge and experience to the realm of liquidity solutions. He will share insights into how TT and OKX are revolutionizing liquidity, providing seamless and flexible options for clients.",
  events20230915ContentLabel3: "Final Thoughts:",
  events20230915Content3:
    "TT and OKX's collaboration at Token 2049 reflects their commitment to advancing liquidity solutions in the cryptocurrency space. This exclusive event promises insightful discussions, expanded networks, and a glimpse into the future of liquidity solutions. It's an opportunity for attendees to connect with like-minded individuals and gain a deeper understanding of the dynamic cryptocurrency market. TT and OKX's dedication to innovation and collaboration sets the stage for a promising and flexible future in cryptocurrency trading.",

  events20230916Title: "TinyTrader's Presence at Wiki Finance Expo Sydney 2023",
  events20230916SubTitle:
    "Save the date for November 16, 2023, as TinyTrader steps into the spotlight at Wiki Finance Expo Sydney, a part of Wiki Expos illustrious global financial exhibition and fintech conference series.",
  events20230916SubTitleDetail: `CEO and co-founder Zo Liang will take the stage to deliver a keynote speech titled "Proprietary Trading Insights: How White Label Solutions Give You an Edge.`,
  events20230916ContentLabel1: "Keynote Speech by CEO Zo Liang",
  events20230916Content1: `CEO and co-founder Zo Liang will take the stage to deliver a keynote speech titled "Proprietary Trading Insights: How White Label Solutions Give You an Edge." This speech promises to offer valuable insights into the power of white label solutions in gaining a competitive edge in proprietary trading.`,
  events20230916ContentLabel2: "Final Words",
  events20230916Content2:
    "TinyTrader's presence at Wiki Finance Expo Sydney, complete with their booth at C1 and CEO Zo Liang's keynote speech, reaffirms their commitment to innovation and collaboration. This collaboration is set to accelerate opportunities and shape the future of financial technology and blockchain, all under the umbrella of TinyTrader's cutting-edge solutions and the wisdom shared by its co-founder.",

  eventsPhotosTitle: "We Can’t Wait To See You",
};

export default zh;
