const zh = {
  account1: "电报",
  account2: "微信",
  account3: "脸书",
  account4: "领英",
  account5: "Medium",
  privacy_policy: "隐私条款",
  services: "交易平台服务",
  servicesSubTitle: "产品与服务",
  servicesT1: "合约交易系统",
  servicesT1ST1Title: "高性能撮合引擎",
  servicesT1ST1Desc: "强大性能的、高可靠性、高可拓展的撮合系统",
  servicesT1ST2Title: "多种交易模式",
  servicesT1ST2Desc:
    "永续合约、差价合约、无限合约，并支持USDT/张自由切换和计算单位，支持多空双向持仓及高倍杠杆",
  servicesT1ST3Title: "专业交易",
  servicesT1ST3Desc: "提供K线技术分析指标和画线工具及多种委托策略，及跟单交易",
  servicesT1ST4Title: "支持功能",
  servicesT1ST4Desc: "配套财务系统、风控体系及运营管理后台",
  servicesT2: "期权交易系统",
  servicesT2ST1Title: "资金利用更灵活",
  servicesT2ST1Desc:
    "期权允许用户使用少量资金来大规模地投资，因此可以使用户利用有限的资金来实现最大程度的资金利用率",
  servicesT2ST2Title: "一流的流动性",
  servicesT2ST2Desc:
    "Tinytrader提供一流的流动性和市场深度，这为我们的用户提供了公平透明的交易体验，滑点最小",
  servicesT2ST3Title: "风险有限，上升空间无限",
  servicesT2ST3Desc:
    "用户作为期权持有人，可以在正确时赚取无限利润，在错误时限制损失",
  servicesT2ST4Title: "拓展交易机会",
  servicesT2ST4Desc: "各类期权可以灵活组合，持续创造收益，无惧市场波动",
  servicesT3: "现货交易系统",
  servicesT3ST1Title: "优质深度支持",
  servicesT3ST1Desc: "更完善的撮合交易，保障热门币对良好流动性",
  servicesT3ST2Title: "安全的钱包技术",
  servicesT3ST2Desc:
    "稳定安全的钱包提供底层技术支持 便捷的充提币及丰富的运营活动值",
  servicesT3ST3Title: "支持多主链币种",
  servicesT3ST3Desc:
    "支持多主链的充提币，提供使用上的便捷，同时降低数字资产的使用门槛",
  servicesT4: "场外交易系统",
  servicesT4ST1Title: "高效快捷",
  servicesT4ST1Desc: "高效快捷地完成法币与数字货币的交易撮合",
  servicesT4ST2Title: "多渠道方式",
  servicesT4ST2Desc: "平台出入金渠道，方便用户操作，有效扩大平台受众",
  servicesT4ST3Title: "安全完善的风控",
  servicesT4ST3Desc: "安全完善的风控机制，保障用户出入金安全",
  servicesT5: "NFT交易系统",
  servicesT5ST1Title: "流动性",
  servicesT5ST1Desc: "通过NFT的交易增加平台内的流动性",
  servicesT5ST2Title: "多元化交易",
  servicesT5ST2Desc:
    "支持定价买卖或竞拍等多种环境进行NFT交易，并且平台支持多币种来进行交易",
  servicesT5ST3Title: "后台管理系统",
  servicesT5ST3Desc:
    "客户可通过后台，系统性的对平台进行管理，并且可迅速掌握平台运维中的相关数据",
  servicesT6: "赠金交易系统",
  servicesT6ST1Title: "赠金试玩",
  servicesT6ST1Desc:
    "用户获得虚拟赠金，即可试玩赠金合约，牛熊对战等多种交易系统",
  servicesT6ST2Title: "无限空投",
  servicesT6ST2Desc: "用户可获得平台赠金，并且在赠金失效前使用赠金",
  servicesT6ST3Title: "盈利真U",
  servicesT6ST3Desc:
    "用户通过赠金合约或者牛熊获得的盈利，达到一定额度后可划转为真U",
  servicesT7: "合约网格机器人",
  servicesT7ST1Title: "全天候自动交易",
  servicesT7ST1Desc:
    "使用合约网格机器人，用户无需时刻跟踪价格走势。只需输入仓位信息，然后将一切交给机器人，坐享收益即可。",
  servicesT7ST2Title: "多/空/中性策略任选",
  servicesT7ST2Desc:
    "如果选择做多策略，机器人仅会进行多头开仓和平仓。如果选择做空策略，机器人仅会进行空头开仓和平仓。如果选择中性策略，机器人会在价格高于市价时进行空头开仓和平仓，并在价格低于市价时进行多头开仓和平仓。",
  servicesT7ST3Title: "利用杠杆放大收益",
  servicesT7ST3Desc: "利用杠杆进行交易，放大用户的仓位规模，赚取更高收益。",
  solutions: "新金融衍生品解决方案",
  solutionsSubTitle:
    "十余年来，Tinytrader服务来数十个交易所，提供了最先进的新金融衍生品解决方案，构建了可支持上百种成熟主链代币的服务框架。",
  solutionsT1: "交易所解决方案",
  solutionsT1ST1Title: "灵活性",
  solutionsT1ST1Desc:
    "可以自由选择搭配功能模块、app排版样式以及部署方式，搭建更具特色和针对性的交易所系统",
  solutionsT1ST2Title: "活跃性",
  solutionsT1ST2Desc:
    "多种营销工具组合使用，不仅可以提高用户获取的效率，也能够大大地提高现有用户的留存率和活跃度",
  solutionsT1ST3Title: "丰富性",
  solutionsT1ST3Desc:
    "多个交易模块并行，丰富的交易模式，给用户提供丰富的交易场景",
  solutionsT2: "金融衍生品解决方案",
  solutionsT2ST1Title: "多种金融产品",
  solutionsT2ST1Desc:
    "涵盖了永续合约、差价合约、各类期权以及跟单产品等帮助客户布局衍生领域，抢占市场流量",
  solutionsT2ST2Title: "扎实的技术基础",
  solutionsT2ST2Desc:
    "使用云节点服务，离线签名系统，冷热钱包等多种技术手段，确保金融功能的流程和高效",
  solutionsT2ST3Title: "顶级的风控措施",
  solutionsT2ST3Desc:
    "使用最顶级的安全措施，全面的风险控制系统确保客户金融资产的安全性",
  solutionsT3: "SaaS产品解决方案",
  solutionsT3ST1Title: "一体化服务",
  solutionsT3ST1Desc:
    "涉及APP、WEB、管理后台、代理商后台、活动后台等全面的管理、运营于一体的SAAS系统",
  solutionsT3ST2Title: "完善的交易体系",
  solutionsT3ST2Desc:
    "集衍生品交易系统、支付系统、NFT交易系统、赠金系统、跟单系统为一体的平台体系",
  solutionsT3ST3Title: "超高的流动性",
  solutionsT3ST3Desc: "领先行业的流动性支持，支持超过1000个交易对",
  solutionsT4: "用户增长解决方案",
  solutionsT4ST1Title: "赠金模式",
  solutionsT4ST1Desc:
    "赠金模式是Tinytrader创新开发的体验金玩法。用户通过各种方式获得赠金，在专属交易区盈利后可以进行划转操作",
  solutionsT4ST2Title: "跟单社区",
  solutionsT4ST2Desc:
    "跟单功能可以有效提升社区互动性，也为用户的策略选择提供了更多的参考。启动跟单功能后，跟单交易员的所有开仓平仓行为都将同步执行",
  solutionsT4ST3Title: "任务中心",
  solutionsT4ST3Desc:
    "Tinytrader的可定制用户激励中心，可根据运营目标设置不同的任务内容，支持多种筛选条件的添加，可更好的配合运营和市场目标，提供产品支持",
  solutionsT4ST4Title: "经纪人系统",
  solutionsT4ST4Desc:
    "创新使用经纪人系统，提供社区式的用户管理系统，实现强大的平台用户裂变功效，产生规模效益",
  solutionsT5: "资产托管解决方案",
  solutionsT5ST1Title: "安全稳定",
  solutionsT5ST1Desc:
    "多个部门协同管理平台资产，利用MPC的最高级别的加密技术，实现分布式管理，消除潜在风险",
  solutionsT5ST2Title: "高效便捷",
  solutionsT5ST2Desc:
    "接入超过上百个主流链节点，同时满足资产托管、跨交易所资产配置在内的一系列便捷交易",
  solutionsT5ST3Title: "风控保证",
  solutionsT5ST3Desc:
    "提供扎实的风控服务，选择靠谱的合规提供商，建立综合全面的风控合规体系，满足全球多个地区的合规准则",
  solutionsT6: "Web 3.0 解决方案",
  solutionsT6ST1Title: "web 3.0 技术",
  solutionsT6ST1Desc:
    "综合使用多种区块链顶级技术，是行业中最稳定和安全的web3.0 服务商之一",
  solutionsT6ST2Title: "web 3.0 产品",
  solutionsT6ST2Desc:
    "完善的NFT平台，成熟的云服务，打造业内标杆式的web3.0 产品",
  solutionsT6ST3Title: "Web 3.0 成员",
  solutionsT6ST3Desc:
    "由业内的专家组成的数十人的顾问团队和技术团队，为关键行业方向提供智力支持",
  solutionsT7: "安全解决方案",
  solutionsT7ST1Title: "网站安全审计",
  solutionsT7ST1Desc: "Tinytrader专业团队，为您审计网站代码和环境、确定风险",
  solutionsT7ST2Title: "数字钱包安全审计",
  solutionsT7ST2Desc: "为您提供审计报告建议，助您修复数字钱包漏洞",
  solutionsT7ST3Title: "智能合约安全审计",
  solutionsT7ST3Desc: "为您验证智能合约，确保安全上链",
  solutionsT8: "运营顾问服务",
  solutionsT8ST1Title: "上线运营支持",
  solutionsT8ST1Desc: "为您提供上线支持并制定策略，协助快速开展运营工作",
  solutionsT8ST2Title: "长期运营支持",
  solutionsT8ST2Desc:
    "为您提供长期运营方案，帮助实现全面有效运营，达成既定目标",
  solutionsT8ST3Title: "定制方案支持",
  solutionsT8ST3Desc:
    "为您就某个重大事件进行传播提供定制方案，以协助达成单项目既定目标",
  support: "服务支持",
  supportSubTitle:
    "Tinytrader为所有的用户提供管家式的服务支持，所有的平台需求可以在第一时间得到反馈和追踪，力求达成商户满意",
  supportT: "我们的服务",
  supportT1: "流动性支持",
  supportT1Desc: "有效解决前期用户不足时交易流动性匮乏的痛点",
  supportT2: "1对1技术顾问",
  supportT2Desc: "专业1对1指导，随时解决您对于技术开发的疑问",
  supportT3: "业务运营服务",
  supportT3Desc: "针对指定业务，提供专业化、定制化的增值运营服务",
  supportT4: "产品运营培训",
  supportT4Desc: "就行业生态及产品运营知识进行系统培训，提高团队认知",
  supportT5: "周边服务支持",
  supportT5Desc: "根据品牌性质和市场需求，提供周边产品创意和思路方案",
  supportT6: "系统定期维护升级",
  supportT6Desc: "配合产品迭代和研发功能上线进行配套维护及相关服务",
  supportT7: "API接口",
  supportT7Desc:
    "负责对外各类型收录，交易深度对接等过程中需要接口对接或代码优化部分",
  supportPowerful: "服务优势",
  supportPowerfulT1Title: "多年技术沉淀，经验丰富",
  supportPowerfulT1Desc:
    '对于高频交易的金融衍生品来说，系统的稳定行十分重要。Tinytrader团队依靠丰富经验可保证交易产品在极端行情和瞬时流量快速增长时依然稳定运行，不会出现"宕机"的情况。',
  supportPowerfulT2Title: "快速完成搭建，抢占市场",
  supportPowerfulT2Desc:
    "市场瞬息万变，错过一波机遇损失远大于开发成本。Tinytrader保证为用户快速搭建产品，在性能稳定的前提下第一时间上线，不错过每一波机遇。",
  supportPowerfulT3Title: "极高完全性，保障用户资产",
  supportPowerfulT3Desc:
    "通过冷热钱包分离、多重离线签名等方式深度储存资产，保障用户及平台资产安全。通过强大的DDoS清洗能力，完美防御各种类型DDoS流量，100%清洗SYN Flood、UDP Flood、ICMP Flood等攻击，充足的防护资源让网站永不宕机。",
  supportPowerfulT4Title: "扩展用户，多维度运营支持",
  supportPowerfulT4Desc:
    "Tinytrader提供了用户赠金、任务中心、社区互动等多个运营支持功能，为用户在后续的用户增长和市场拓展提供丰富的产品功能支持。",
  supportOpening: "开站流程",
  supportOpeningT1: "了解您的需求",
  supportOpeningT2: "1v1定制专属方案",
  supportOpeningT3: "签署合约",
  supportOpeningT4: "资料收集",
  supportOpeningT5: "线上部署",
  supportOpeningT6: "系统培训",
  supportOpeningT7: "客户验收",
  supportOpeningT8: "线上运营管理",
  supportCompliance: "合规服务",
  supportComplianceT: "Tinytrader-合规金融牌照支持",
  supportComplianceD1:
    "Tinytrader 总部位于新加坡，是一家专业从事区块链新金融衍生品SAAS技术研发及区块链金融产品全栈式服务平台。",
  supportComplianceD2:
    "Tinytrader 拥有一支300人以上的顶级技术团队，经三年技术沉淀，服务了上万名客户。团队成员全部来自国际知名互联网公司，拥有多年的金融产品及区块链应用开发经验。",
  supportComplianceD3:
    "Tinytrader 致力为区块链从业者提供全方位的区块链产品及技术服务支持，具体服务包含搭建交易平台，云服务，全新金融衍生品开发，区块链应用开发等。",
  case: "成功案例",
  caseSubTitle: "我们为许多的交易所提供技术支持，成为许多品牌信赖的合作伙伴",
  caseT1: "我们的客户",
  caseT2: "成功项目",
  caseT2ST1Title: "Hyper",
  caseT2ST1Desc:
    "Hyper W是一家在塞舌尔共和国正式注册的加密货币金融衍生品交易所。该平台致力于提供具有证券交易所级别安全性的技术架构，并为数百万用户提供多样化的金融衍生品交易。Hyper W始终坚持“创新、高效和安全”，为全球用户提供优质、创新的产品和服务，成为世界一流的加密货币衍生品服务提供商。HyperW由一组高级金融工程专家发起。该团队在金融衍生品行业拥有多年的经验。核心成员来自清华大学、北京大学、复旦大学等中国重点大学，汇集了高盛、百度、甲骨文、腾讯、阿里巴巴等精英，试图打造新一代去中心化衍生品交易平台。",
  caseT2ST2Title: "Ulink",
  caseT2ST2Desc:
    "Ulink是全球领先且合规的专业区块链衍生品交易平台，总部位于新加坡。优联始终秉承“安全、自由、用户”的公司理念，为欧洲、美洲、东南亚、香港、新加坡等地区120多万用户提供交易服务。该公司在美国和加拿大拥有双重MSB许可证，全球化进程正在加快。目前，它已经在世界各地推出了独立业务。未来，它将不遗余力地基于“安全、自由、用户”来实施。Ulink的目标是创建一个完整的全球数字资产金融生态系统，实现全球区块链生态系统建设。",
  caseT2ST3Title: "BWFX",
  caseT2ST3Desc:
    "BWFX交易所(BWFX.site) 是全球领先合规化的专业区块链衍生品交易平台，总部位于新加坡，秉承「安全、自由、用户走心」的交易理念，为欧洲，美洲，东南亚，中国香港，新加坡等地区的120多万用户展开交易服务。BWFX拥有来自世界百强企业的区块链技术专家团队，融合全球最顶尖的安全技术，致力于为用户提供安全和便捷的交易服务。",
  caseT2ST4Title: "Biking",
  caseT2ST4Desc:
    "全球领先的区块链数字资产合约交易平台Biking继续向全球提供数字货币交易、区块链教育、区块链项目孵化、区块链资产发行平台和区块链研究机构，以及区块链公益和慈善服务。目前，自行车用户覆盖全球30多个国家和地区。凭借每秒140万个订单的核心内存匹配技术，Biking从新加坡首都Intabing获得了2000万美元（折合人民币1.3亿元）的战略投资。同时，它还在美国和加拿大获得MSB许可证，是一家合规的海外合同交易所。",
  caseT2ST5Title: "Sunbit",
  caseT2ST5Desc:
    "Sunbit是全球安全领先的数字资产衍生品交易平台。它拥有合规运营数字货币交易平台和金融衍生品的许可证。Sunbit由区块链领域的专业国际团队开发和运营，在交易系统、风险控制、结算和安全管理方面拥有丰富的经验。Sunbit致力于以合规、专业、友好、用户至上、服务至上的方式为全球用户提供安全、稳定、便捷的数字资产交易服务。",
  caseT2ST6Title: "HPX",
  caseT2ST6Desc:
    "HPX是一个专注于区块链用户体验的区块链数字资产交易平台。HPX交易所软件为用户提供方便的加密/法定货币交易和安全舒适的交易环境。用户可以前往HPX交易所应用程序实现多币种交易、数字资产趋势查询和在线挖矿赚钱。有在线专业顾问帮助用户解决问题和疑虑，也有有用的专业课程帮助用户了解更多信息。",
  caseT3: "关于我们",
  more: "更多",
  why: "为什么选择我们",
  why1Title: "保护用户资产的顶级安全性",
  why1Desc:
    "通过冷热钱包隔离、离线多重签名等方式进行资产存储，以确保用户和平台资产的安全。凭借强大的DDoS清理功能，它可以完美防御各种攻击，如SYN Flood、UDP Flood、ICMP Flood等，使网站免于关闭。",
  why2Title: "创新且全面合规的解决方案",
  why2Desc:
    "TinyTrader的创新和全方位合规解决方案包括数字资产交换解决方案、NFT交易平台解决方案、钱包解决方案、流动性解决方案和资产托管解决方案。",
  why3Title: "国家级战略性互联网技术中心",
  why3Desc:
    "对于高频交易金融衍生品，系统的稳定性非常重要。TinyTrader团队依靠专业技术和丰富经验，确保在极端市场条件和流量激增下不会出现“停机”。",
  why4Title: "多年的行业经验",
  why4Desc:
    "我们为企业提供易于集成、可扩展和安全的区块链解决方案，以我们强大的技术专长和丰富的经验为后盾。",
  why5Title: "专业策略助力",
  why5Desc:
    "我们为客户甄选亚太地区的专业运营第三方顾问团队，给予客户可信任、有效的运营策略，帮助解决客户在运营侧的能力不足问题，助力客户快速达成既定目标。",
  why6Title: "海量资源支撑",
  why6Desc:
    "我们整合行业资源并秉承开放的服务理念，给予客户产品运营过程中所需的资源支持，包括渠道资源、商务资源、媒体资源、做市商资源、社区资源、资本资源等。",
  about: "关于我们",
  about1:
    "Tinytrader 总部位于新加坡，是一家专业从事区块链新金融衍生品SAAS技术研发及区块链金融产品全栈式服务平台。",
  about2: "团队成员",
  about3: "客户",
  about4: "国家",
  about5: "日交易量",
  about6: "50亿美元",
  ourPartners: "合作伙伴",
  banner: "<div>打造属于你自己的</div> <b>交易所</b>",
  bannerContent1: "七日快速搭建",
  bannerContent2: "多元资产流动性",
  bannerContent3: "全天候支持",
  home: "首页",
  products: "产品",
  news: "新闻",
  apply: "申请使用",
  applyDesc: "获取更多您想要了解的交易和信息",
  name: "姓名",
  nameError: "请输入您的姓名",
  email: "邮箱",
  emailError: "请输入正确的邮箱",
  socialMedia: "社交账号",
  socialMediaError: "请输入社交账号",
  message: "信息",
  submit: "提交",
  seeMore: "查看更多",
  slogan: "用我们的服务 帮你跨过 去未来的 那一小步",
  coreProducts: "核心产品",
  coreProductsDesc:
    "我们的核心产品涵盖了市场上数字资产交易的核心功能，如现货、合约、期权、场外交易、NFT、策略交易、资产增值、支付等，支持App、Desktop和PC端。",
  headerMenuT1: "产品",
  headerMenuT1ST1: "数字资产交易",
  headerMenuT1ST1D1:
    "涵盖市场上数字资产交易的核心模块，如现货、期货、期权、OTC、NFT、策略交易、理财、支付等",
  headerMenuT1ST1D2: "提供领先的风险控制策略，以确保交易所的安全",
  headerMenuT1ST1D3:
    "提供四种部署解决方案，以满足您的需求：SaaS、私有化（托管）、SDK、私有化",
  headerMenuT1ST1D4: "通过三种不同的界面提供服务：App、Desktop和PC",
  headerMenuT1ST1D5: "支持三大交易所的主链及相应的代币",
  headerMenuT1ST1D6: "提供行业领先的流动性服务",
  headerMenuT1ST1D7: "撮合引擎：性能强大，可靠性高",
  headerMenuT1ST2: "运营工具",
  headerMenuT1ST2D1: "提供各种运营工具，以支持商户获得流量和提升用户活跃度",
  headerMenuT1ST2D2: "交易策略：支持跟单交易和网格交易策略",
  headerMenuT1ST2D3: "存入代币产生利息，提高用户粘性和交易所管理的资产规模",
  headerMenuT1ST2D4:
    "用户拉新、留存、促活、转化，如红包、空投、邀请返佣、赠金交易",
  headerMenuT1ST3: "NFT交易系统",
  headerMenuT1ST3D1: "通过NFT交易增加平台上的流动性",
  headerMenuT1ST3D2: "集成管理工具，提升数据洞察力",
  headerMenuT1ST3D3:
    "支持NFT交易，盲盒交易和多种场景下的多货币交易，如固定价格交易和拍卖",
  headerMenuT1ST3D4: "存储在IPFS上，分散式存储确保了数据的稳定性和可靠性",
  headerMenuT1ST4: "API",
  headerMenuT1ST4D1: "根据您的业务需求集成API",
  headerMenuT1ST4D2:
    "我们提供各种API，以帮助开发人员将数字资产交易的核心功能集成到您自己的产品或服务中",
  headerMenuT1ST4D3:
    "作为SaaS服务（如期权、期货、营销活动等）。都提供了相同的API，您不需要学习不同的API",
  headerMenuT1ST4D4:
    "针对特殊的API服务（如下单、平仓、获取行情数据等）为Web、Android 和iOS提供SDK支持",
  headerMenuT1ST5: "信用卡",
  headerMenuT1ST5D1: "存款数字货币，享受全球支付",
  headerMenuT1ST5D2: "信用卡由TinyTrader发行。它提供虚拟卡和实体卡",
  headerMenuT1ST5D3: "帮助商户拓展全球客户",
  headerMenuT1ST5D4: "支持Visa, MasterCard和银联卡",
  headerMenuT1ST5D5: "资金应由合规的托管服务提供商托管，确保资金的安全",
  headerMenuT1ST5D6: "支持定制封面设计，多风格个性化展示",
  headerMenuT1ST5D7: "由全球超过176个国家的5000万商家支持",
  headerMenuT2: "解决方案",
  headerMenuT2ST1: "钱包",
  headerMenuT2ST1D1: "极致安全，从流程到交易数据全方位保护资产安全",
  headerMenuT2ST1D2: "一个钱包轻松地管理50+公链资产",
  headerMenuT2ST1D3: "由专业金融机构提供的投资选择",
  headerMenuT2ST1D4: "智能路由自动计算最优价格、滑点、gas，让交易变的简单",
  headerMenuT2ST2: "流动性",
  headerMenuT2ST2D1: "获得主流交易所的深度流动资金池",
  headerMenuT2ST2D2: "支持更低的买卖价差和更好的买卖价格",
  headerMenuT2ST2D3: "包括现货流动性和衍生品流动性",
  headerMenuT2ST2D4: "为您的商业模式量身定制流动性解决方案",
  headerMenuT2ST3: "资产托管",
  headerMenuT2ST3D1:
    "提供定制的解决方案，以满足您企业的数字资产、规模和财务运营能力",
  headerMenuT2ST3D2: "根据您的偏好，提供实时、每日或固定结算的指定企业钱包地址",
  headerMenuT2ST3D3: "拥有个性化和对用户友好的仪表盘",
  headerMenuT2ST3D4: "运营产研成本最小化",
  headerMenuT2ST3D5: "支持50+主链和160+主流货币",
  headerMenuT2ST4: "Web3基础架构",
  headerMenuT2ST5: "分布式存储",
  headerMenuT2ST6: "运营顾问服务",
  headerMenuT2ST6D1:
    "为客户甄选亚太地区的专业运营第三方顾问团队，成员来自Huobi、Binance、OKX等，具备丰富项目运营经验",
  headerMenuT2ST6D2: "协助客户搭建专业运营架构，实现快速上线运营",
  headerMenuT2ST6D3: "根据客户特性量身定制运营、营销、公关、社区、活动等策略",
  headerMenuT2ST6D4: "依据客户需求协助渠道、媒体、社区、商务等资源对接及整合",
  headerMenuT2ST6D5:
    "支持客户全链路运营咨询服务，安排专业团队进行项目运营及市场推广等环节的一对一咨询服务",
  headerMenuT2ST6D6:
    "提供活动策划、事件营销、用户增长、媒体传播、社区建设等定制运营咨询服务",
  headerMenuT3: "关于",
  headerMenuT3ST1: "服务支持",
  headerMenuT3ST2: "成功案例",
  PhoneNumber: "电话号码",
  EMailAddress: "电子邮件地址",
  LearnMore: "更多",
  navigation: "导航",
  contact_your: "已收到您的申请，稍后客服人员会联系您!",
  newSolutions: "数字资产交易解决方案",
  newSolutionsSubTitle:
    "TinyTrader提供了一站式SaaS解决方案，为加密交易所的启动和发展提供所有工具。 ",
  newSolutionsT1: "核心交易平台",
  newSolutionsT1ST1Title: "期货交易系统",
  newSolutionsT1ST2Title: "期权交易系统",
  newSolutionsT1ST3Title: "现货交易系统",
  newSolutionsT1ST4Title: "场外交易系统",
  newSolutionsT1ST5Title: "NFT交易系统",
  newSolutionsT1ST6Title: "赠金交易系统",
  newSolutionsT1ST7Title: "期货网格机器人",
  newSolutionsT2: "交易核心功能",
  newSolutionsT2ST1Title: "每秒处理能力为70,000笔交易",
  newSolutionsT2ST2Title: "最大交易执行时间：30毫秒",
  newSolutionsT2ST3Title:
    "支持的订单类型（市价订单、限价订单、触发订单、全部成交或取消订单、立即成交或取消订单）",
  newSolutionsT2ST4Title: "逐仓保证金模式和全仓保证金模式",
  newSolutionsT3: "交易界面",
  newSolutionsT3ST1Title: "永续合约期货、期权和差价合约（CFD）交易模式",
  newSolutionsT3ST2Title: "直观、信息丰富的交易视图",
  newSolutionsT3ST3Title: "委托历史记录、余额和账户摘要",
  newSolutionsT3ST4Title: "期货损益计算器",
  newSolutionsT3ST5Title: "多平台跨系统开发",
  newSolutionsT4: "后台",
  newSolutionsT4ST1Title: "代理合伙人管理",
  newSolutionsT4ST2Title: "自动KYC审核和人工KYC审核",
  newSolutionsT4ST3Title: "跟单交易管理",
  newSolutionsT4ST4Title: "多语言支持",
  newSolutionsT4ST5Title: "交易佣金设置",
  newSolutionsT4ST6Title: "交易历史分析",
  newSolutionsT4ST7Title: "文档存储",
  newSolutionsT5: "运营工具",
  newSolutionsT5ST1: "期货网格机器人",
  newSolutionsT5ST2: "理财",
  newSolutionsT5ST2Title:
    "Tinytrader理财是我们的资产管理平台，帮助用户加密资产稳健增值。 我们提供极具竞争力的年化收益率，保证高额收益，同时为资金提供一流的安全保障。",
  newSolutionsT5ST2T1: "交易门槛极低",
  newSolutionsT5ST2D1: "随存随取零手续费，存入即赚取阶梯稳定收益",
  newSolutionsT5ST2T2: "一流安全保障",
  newSolutionsT5ST2D2: "冷热钱包隔离，多轮安全审计",
  newSolutionsT5ST2T3: "高收益产品组合",
  newSolutionsT5ST2D3:
    "丰富的投资周期从短到长，灵活的投资额度满足用户多种赚币需求，30+数字资产闲置资金赚收益",
  newSolutionsT5ST3: "跟单交易",
  newSolutionsT5ST3Title1: "交易达人",
  newSolutionsT5ST3Desc11: "KOL分享交易策略，吸引更多跟单者穿越牛熊",
  newSolutionsT5ST3Desc12: "建立跟单群，共享盈利",
  newSolutionsT5ST3Title2: "跟单用户",
  newSolutionsT5ST3Desc2: "一键跟单，轻松赚钱",
  newSolutionsT5ST4: "模拟交易",
  newSolutionsT5ST4T1: "上手迅速",
  newSolutionsT5ST4D1:
    "提供了大量的交易知识和教程，可以帮助新用户更好地理解交易市场，学习交易技巧",
  newSolutionsT5ST4T2: "风险规避",
  newSolutionsT5ST4D2:
    "让老用户实践自己的交易策略，通过不断试错，找到适合自己的交易方式",
  newSolutionsT5ST4T3: "免费赠金",
  newSolutionsT5ST4D3: "用户免费领取赠金，收益无限",
  newSolutionsT5ST4T4: "盈利提现",
  newSolutionsT5ST4D4:
    "用户可以在不用担心损失的情况下学习交易技能，盈利的金额超出限额后可以进行提现",
  newSolutionsT5ST5: "市场营销组件",
  newSolutionsT5ST5Text1:
    "任务奖励可以培养用户的使用习惯，通过完成任务来提高用户的活跃度和留存率",
  newSolutionsT5ST5Text2:
    "用户邀请新用户注册交易，可以获得交易所返佣和平台奖励",
  newSolutionsT5ST5Text3: "发送红包功能帮助交易所推广并引流",
  newSolutionsT5ST5Text4: "交易大赛活动燃爆全场",
  newSolutionsT7: "系统架构",
  newSolutionsT8: "部署方案",
  newSolutionsT8ST1Title: "SaaS（公共云）",
  newSolutionsT8ST1Desc1: "拥有市场",
  newSolutionsT8ST1Desc2: "拥有运营能力",
  newSolutionsT8ST1Desc3: "无需技术团队",
  newSolutionsT8ST2Title: "私有化（托管）",
  newSolutionsT8ST2Desc1: "拥有市场",
  newSolutionsT8ST2Desc2: "拥有运营能力",
  newSolutionsT8ST2Desc3: "需要技术团队",
  newSolutionsT8ST2Desc4: "低成本地独立部署，无需源代码",
  newSolutionsT8ST3Title: "SDK软件开发工具包",
  newSolutionsT8ST3Desc1: "拥有市场",
  newSolutionsT8ST3Desc2: "拥有运营能力",
  newSolutionsT8ST3Desc3: "需要技术团队",
  newSolutionsT8ST3Desc4: "将数字资产交易集成到自己的产品和服务",
  newSolutionsT8ST4Title: "私有化",
  newSolutionsT8ST4Desc1: "可以完全掌控交易系统",
  newSolutionsT8ST4Desc2: "根据运营理念最大限度地定制系统",
  newSolutionsT8ST4Desc3: "服务某些/特殊国家",
  newSolutionsT8ST4Desc4: "拥有风险安全技术支持",

  // 新版首页
  headerApply: "Book A Demo",
  tabTech: "Tech",
  tabLiquidity: "Liquidity",
  liquidityMenuTitle1: "TT Hub",
  tabContact: "Contact Us",
  tinyTrader: "Tinytrader",
  techMenuTitle1: "Digital Asset Exchange",
  techMenuDesc1: "Full range trading platform solutions",
  techMenuTitle2: "TT Crypto Card",
  techMenuDesc2: "Digital banking card solution",
  techMenuTitle3: "Open API",
  techMenuDesc3: "Integration to existing platform solution",
  techMenuTitle4: "Proprietary Trading",
  techMenuDesc4: "Trading with firm capital solution",
  techMenuChildrenTitle1: "TT0: Spot",
  techMenuChildrenDesc1: "Spot Trading System",
  techMenuChildrenTitle2: "TT1: Futures",
  techMenuChildrenDesc2: "Futures Trading System",
  techMenuChildrenTitle3: "TT2: Options",
  techMenuChildrenDesc3: "Options Trading System",
  tabAbout: "About",
  aboutMenuTitle: "About Us",
  aboutMenuDesc:
    "We're revolutionizing 21st-century trading platforms by eradicating barriers, enhancing user experience, and pioneering seamless trading and liquidity solutions.",
  aboutMenuChildrenTitle1: "Company Overview",
  aboutMenuChildrenTitle2: "Team",
  aboutMenuChildrenTitle3: "Careers",
  tabResources: "Resources",
  resourcesMenuDesc:
    "Designed a comprehensive resources center for today's institutional businesses, offering timely news updates, insightful education, and handpicked marketplace partners. Elevate your trading infrastructure with us.",
  resourcesChildrenTitle1: "TT Marketplace",
  resourcesChildrenTitle2: "News",
  resourcesChildrenTitle3: "Education",
  resourcesChildrenTitle4: "Events",

  bannerTitle1: "Advanced White-Label",
  bannerTitle2: "Trading Technology & Liquidity",
  bannerTitle3: "Solutions Provider",
  bannerTitle4: "Tiny Steps to Trade in the Future",
  ttLearnMore: "Learn More",
  bannerNumber1: "50+",
  bannerNumberDesc1: "Institutional Clients",
  bannerNumber2: "100+",
  bannerNumberDesc2: "Employees",
  bannerNumber3: "10+",
  bannerNumberDesc3: "Countries",
  bannerNumber4: "$5B+",
  bannerNumberDesc4: "Volume",

  footerCopyright1: "TinyTrader. All Rights Reserved.",
  footerCopyright2:
    "Trademarks In Singapore (Official Registration Numbers To Be Confirmed). TinyTrader Represents A Formalized Joint Venture",
  footerCopyright3:
    "Established Through Contractual Alliances Among Distinct Legal Entities And Esteemed Individuals Within Singapore",
  footerSlogan:
    "TinyTrader (TT) Is an advanced white-label trading technology and liquidity solutions provider that connects digital-asset trading between fiat and digital currencies for global trading platforms, brokers, developers, and institutions.",
  footerProducts: "Products",
  footerProducts1: "Digital Asset",
  footerProducts2: "TT Spot",
  footerProducts3: "TT Futures ",
  footerProducts4: "TT Options",
  footerProducts8: "Prop Trading",
  footerProducts5: "Crypto Card",
  footerProducts6: "Open API ",
  footerProducts7: "TT Marketplace",
  footerLearn: "Learn",
  footerLearn1: "News",
  footerLearn2: "Event",
  footerLearn3: "About",
  footerLearn4: "Carrers",
  footerLegal: "Legal",
  footerLegal1: "Terms of Services",
  footerLegal2: "Privacy Policy",
  footerSupport: "Support",
  footerSupport1: "Contact Us",
  footerSupport2: "FAQ",
  footerSupport3: "Prop Trading App Download",
  emailTitle: "Stay Connected!",
  emailDesc: "Get Our Newsletter",
  emailBtn: "Enter your Email",

  whoTitle: "Who We Are",
  whoDesc:
    "We are committed to empowering every client, from traditional finance to emerging fintech, with our cutting-edge trading technologies and liquidity solutions",

  clientTitle: "Open to Every Type of a Client",
  clientTitle1:
    "We are committed to empowering every client, from traditional finance to emerging fintech, with our cutting-edge trading technologies and liquidity solutions",
  clientTitle2: "From Traditional Finance To Emerging Fintech,",
  clientTitle3: "With Our Cutting-Edge Cerivatives And Liquidity Solutions",
  clientListTitle1: "TradFi Companies",
  clientListDesc1:
    "We provides entities such as FOREX, CFD firms and brokerages with seamless integration to the digital asset market, establishing an additional revenue stream, whilst circumventing the necessity of building a proprietary platform from the ground up.",
  clientListTitle2: "Financial Technologies ",
  clientListDesc2:
    "Our offering for wallets, custodians, and digital trading venues provide opportunity to deliver a secure, scalable, and customizable platform that amplifies your service offerings and equips you with a competitive edge trading technologies landscape.",
  clientListTitle3: "Crypto Projects",
  clientListDesc3:
    "We provides a robust, intuitive trading infrastructure, ideal for tokens and crypto project owners. This invariably enhances the visibility of your project and ensures optimal market market.",

  solutionsTitle: "Our Solutions",
  solutionsTitle1:
    "We provide a complete solutions suite covering infrastructure development and ecosystem support for forward-looking businesses exploring new revenue streams",
  solutionsTitle2: "Ecosystem Support For Forward-Looking Businesses Exploring",

  solutionsMenuTitle1: "White Label Crypto Exchange",
  solutionsMenuList1Item1:
    "Execution in matching engine with high speed, efficiency and utmost reliability",
  solutionsMenuList1Item2:
    "Provided with the full-range digital asset trading platform functionality, including spot trading, derivatives (futures and options), prop trading, P2P, including ecosystem services",
  solutionsMenuList1Item3:
    "Integrated risk management solutions to guarantee exchange security",
  solutionsMenuList1Item4:
    "Delivered deployment solutions: SaaS (Public Cloud), SDK and API integrations",
  solutionsMenuList1Item5:
    "Availability across platforms: Desktop, PC, and mobile applications",
  solutionsMenuList1Item6:
    "Provided aggregated liquidity services on the white label crypto exchange platform",

  solutionsMenuTitle2: "Operational Tools",
  solutionsMenuList2Item1:
    "Cover all of the cryptocurrency core functions in the market, such as spot, derivatives, OTC, NFTs, strategy trading, asset value-added, payments, etc",
  solutionsMenuList2Item2:
    "Integrate leading risk control strategies to ensure the security of exchanges",
  solutionsMenuList2Item3:
    "Provide four deployment solutions to meet your needs: SaaS (Public Cloud), Privatization (Hosting), SDK, Privatization",
  solutionsMenuList2Item4:
    "Offer these across three different interfaces: desktop, PC, and mobile apps",
  solutionsMenuList2Item5:
    "Support main chains and corresponding tokens of top three exchanges",
  solutionsMenuList2Item6: "Offer industry leading liquidity services",
  solutionsMenuList2Item7:
    "Matching Engine: powerful performance, high reliability",

  solutionsMenuTitle3: "API",
  solutionsMenuList3Item1:
    "Comprehensive API support enhancing exchange infrastructure integration",
  solutionsMenuList3Item2:
    "Seamless WebSocket and RestAPI integration for real-time data exchange and operations",
  solutionsMenuList3Item3:
    "Program API provision, enabling software development grounded in our systems",
  solutionsMenuList3Item4:
    "Open architecture, promoting adaptability and customization for diverse applications",
  solutionsMenuList3Item5:
    "Empower developers with detailed documentation and support, ensuring efficient system utilization",
  solutionsMenuList3Item6:
    "Prioritize security and efficiency, ensuring rapid data transfers with utmost integrity",
  solutionsMenuList3Item7:
    "Foster innovation, supporting third-party extensions and collaborative integrations",

  solutionsMenuTitle4: "Crypto Card",
  solutionsMenuList4Item1:
    "Secured digital banking integration service powered by white-label integration",
  solutionsMenuList4Item2:
    "Secure issuance of digital assets payment cards, ensuring user trust and reliability",
  solutionsMenuList4Item3:
    "Seamless integration with the exchange, facilitating instantaneous digital assets transactions",
  solutionsMenuList4Item4:
    "Equip users with the ability to transact both online and offline worldwide",
  solutionsMenuList4Item5:
    "Maintain the highest standards of security, ensuring asset protection and transactional integrity",
  solutionsMenuList4Item6:
    "Simplify user experiences through offering of instant virtual card provisioning, eliminating lengthy forms",
  solutionsMenuList4Item7:
    "Flexibility in fund management: Provide seamless options for users to convert back to USDT",

  solutionsMenuTitle5: "Liquidity",
  solutionsMenuList5Item1:
    "Internal organic liquidity pool for consistent market depth",
  solutionsMenuList5Item2:
    "Seamless liquidity bridging with top-tier crypto exchanges",
  solutionsMenuList5Item3: "Advanced Market Making (MM) algorithms",
  solutionsMenuList5Item4:
    "RestAPI and WebSocket API across markets external MM connectivity",

  liquidityTitle: "Liquidity Solutions",
  liquidityDesc1:
    "We offer a variety of liquidity solutions, including Remarketer automated market",
  liquidityDesc2:
    "making software and solutions that provide spot liquidity, derivatives liquidity ",
  liquidityDesc3: "and leveraged ETF liquidity.",
  liquidityBtn1: "Internal Liquidity Pool",
  liquidityBtn2: "Liquidity Bridges",
  liquidityBtn3: "External API Connectivity",

  marketplaceTitle: "TT Marketplace",
  marketplaceDesc1:
    "Ideal venue for businesses seeking marketing collaborations, co-marketing activities, integrations, and revenue-sharing opportunities. With a diverse community of companies, we offer an open and dynamic environment for strategic partnerships. By harnessing the power of synergistic alliances, businesses can expand their reach and access TT’s extensive customer base, which continues to grow exponentially.",
  marketplaceDesc2:
    "By Harnessing The Power Of Synergistic Alliances, Businesses Can Expand Their Reach And Access TT’s Extensive Customer Base, Which Continues To Grow Exponentially.",

  awardsTitle: "Awards & Licensing",
  awardsItem1Title: "ISO 27001:2022",
  awardsItem1Desc: "Information security, cybersecurity and privacy protection",
  awardsItem2Title: "DMCC DLT License",
  awardsItem2Desc:
    "Authority for blockchain and cryptocurrency businesses in Dubai, UAE",
  awardsItem3Title: "RAK DAO Digital-Assets Oasis License",
  awardsItem3Desc: "Digital asset businesses in Ras Al Khaimah, UAE",
  awardsItem4Title: "Best Blockchain Data Solution 2023",
  awardsItem4Desc:
    "Awarded as best Blockchain Solution Provider of the year by Sigma",
  awardsItem5Title: "Best Liquidity Provider 2023",
  awardsItem5Desc:
    "Awarded as the best Liquidity Provider of the year by CryptoExpo",
  awardsItem6Title: "Best White-Label Solution Provider 2023",
  awardsItem6Desc: "Awarded as White-Label Solution Provider by WikiExpo",

  partnersTitle: "Our Partners",
  partnersDesc: "Don’t take our word for it. Trust our customers",
  partnersItem1Title:
    "CoinW is globally recognized as a leading exchange platform, characterized by its advanced trading capabilities. It offers trading in different markets,",
  partnersItem1Desc:
    "a comprehensive affiliate  system, and some of the industry's highest rebate bonus structures.",
  partnersItem2Title:
    "OKX is a leading cryptocurrency exchange renowned for its",
  partnersItem2Desc:
    "advanced technology, offering users a platform to trade, invest, and manage their digital assets securely and efficiently.",
  partnersItem3Title:
    "Alchemy Pay is globally acknowledged as a forefront payment gateway, characterized by its cutting-edge transaction solutions. It provides seamless crypto-to-fiat conversions, intricate payment protocol integrations,",
  partnersItem3Desc: "",
  partnersItem4Title:
    "Jumio is universally esteemed as a pinnacle in digital identity verification, characterized by its state-of-the-art authentication mechanisms. It offers advanced biometric scanning, intricate document verification processes,",
  partnersItem4Desc: "",
  partnersItem5Title:
    "AWS (Amazon Web Services) is universally recognized as a paramount entity in cloud computing, epitomized by its pioneering infrastructure services.",
  partnersItem5Desc: "",
  partnersItem6Title:
    "CoinMarketCap is the world's most-referenced price-tracking website for cryptoassets in the rapidly growing cryptocurrency space.",
  partnersItem6Desc: "",

  noFoundTitle: "Oops it seems you follow backlink",
  noFoundBtn: "Go To Home",

  comingTitle: "We’re Coming Soon..",
  comingDesc:
    "Lorem ipsum dolor sit amet consectetur. Vel sit facilisi mauris vitae aliquam velit molestie imperdiet eget. In sociis gravida posuere arcu egestas. ",
  comingItem1: "360",
  comingItem1Unit: "DAYS",
  comingItem2: "24",
  comingItem2Unit: "HOURS",
  comingItem3: "60",
  comingItem3Unit: "MUNUTES",
  comingItem4: "60",
  comingItem4Unit: "SECONDS",
  cookieTitle:
    "We use cookies to personalize and improve your experience on our website. By clicking “Accept” you consent to our",
  cookiePolicy: "Cookies Policy.",
  cookieDecline: "Decline",
  cookieAccept: "Accept",
  hyp_tiny_saas_download_Platforms:
    "Any time, anywhere. Trading made easy over multiple platforms",
  hyp_tiny_saas_download_Available:
    "Available on iOS, Android, Windows, and Mac. Offering you full-fledged functions, on the go",
  hyp_tiny_saas_download_Mobile: "Mobile",
  hyp_tiny_saas_download_Desktop: "Desktop",
  hyp_tiny_saas_download_DownloadText_Android: "Android Download",
  hyp_tiny_saas_download_DownloadText_iOS: "iOS Download",
};

export default zh;
