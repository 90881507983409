const zh = {
  chatbot_welcome: "👋 欢迎来到TT Chatbot,您的个人AI助手，需要关于平台<a href='#' style='text-decoration: none;color:#FAB600' >常见问题</a>？我在这里帮助您！ 我将根据TT 平台的数据为您提供市场见解。'",
  chatbot_bytheway: "顺便说一句，您可以为您的网站创建一个像我一样的聊天机器人！ ",
  chatbot_expired: "会话已过期，系统将不会考虑历史聊天中的信息",
  chatbot_analyzing: "正在分析您的提问",
  chatbot_system: "对不起，我无法识别{XXX}是指什么。请提供更多信息或者更正您的输入",
  chatbot_identify: "系统出错，请重新提问",
  chatbot_changeLanguage: "更改语言",
  chatbot_language: "语言",
  chatbot_chinese: "中文",
  chatbot_endChat: "结束对话",
  chatbot_cancel: "取消",
  chatbot_looking: "是否在寻找",
  chatbot_customized: "可以定制什么服务?",
  chatbot_supported: "支持哪些加密货币?",
  chatbot_create: "您可以为您的网站创建一个像我一样的聊天机器人！",
  chatbot_start: "开始对话",
  chatbot_AIAssistant: "欢迎来到TT Chatbot,您的个人AI助手，我在这里帮助您！",
  chatbot_currentLanguage: "继续当前语言",
  chatbot_solved: "解决",
  chatbot_unsolved: "未解决",
  chatbot_enterQuestion: "请您输入问题",
  chatbot_feedback: "感谢你的反馈",
};

export default zh;
